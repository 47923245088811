
<SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
         :data="Component"
         :vmodel="vmodel"
         />
 <div v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE,Component) && canDoOperation(ObjectGroupConst.VIEW,Component)" :key="componentKey" :id="getPropertyValue(HtmlTypeConst.ID,Component)" v-tooltip="getPropertyValue(HtmlTypeConst.TOOLTIP,Component)"
     :style="getPropertyValue(HtmlTypeConst.STYLE,Component)" :class="getCompClass(Component)" :name="getPropertyValue(HtmlTypeConst.NAME,Component)" >
     <slot name="content">
        
        <FormBuilder v-if="formBuildComp>0"  :container="container" :componentId="formBuildComp" :applicationId="Component.applicationId"
        :applicationVersion="Component.applicationVersion" :attrsInput="attrsInput" 
        /> 

     </slot>
      {{ getPropertyValue(HtmlTypeConst.TEXT,Component) }} {{ vmodel }}
 </div>