import { ClassicPreset as Classic, GetSchemes, NodeEditor } from 'rete';

import { LogNode } from "../nodes/examples/LogNode";
import { StartNode } from "../nodes/startNode";
import { TextNode } from "../nodes/textNode";
import { ControlFlowEngine, DataflowEngine } from 'rete-engine';
import { IapCatalogType } from '../../../../../catalog/domain/iapCatalogType';
import CatalogCommandConst from '../../../../../catalog/domain/const/CatalogCommandConst';
import { IapWorkFlowActivity } from '../../../../domain/service/iapWorkFlowActivity';
import { Container } from 'inversify';
import { IapWorkFlowActivityConnection } from '../../../../domain/service/iapWorkFlowActivityConnection';
import { IapWorkFlow } from '@ilinium/shared/src/entidades/builder/workflow/domain/service/iapWorkFlow';
import HelperWorkFlow from '../../../functions/helperWorkFlow';
import { DataSourceNode } from '../nodes/dataSourceNode';
import { ValidateComponentNode } from '../nodes/validateComponentNode'
import { Area2D, AreaPlugin } from 'rete-area-plugin';
import { VueArea2D } from 'rete-vue-plugin';
import { ContextMenuExtra } from 'rete-context-menu-plugin';
import { MinimapExtra } from 'rete-minimap-plugin';
import { RerouteExtra } from 'rete-connection-reroute-plugin';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import { NavigationNode } from '../nodes/navigationNode';
import { DelayNode } from '../nodes/delayNode';
import { Router } from 'vue-router';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';
import { StoreProcedureNode } from '../nodes/storeProcedureNode';
import { MessageNode } from '../nodes/messageNode';
import { MessageHideNode } from '../nodes/messageHideNode'
import { SendMailNode } from '../nodes/sendMailNode';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import { DisplayComponentNode } from '../nodes/displayComponentNode';
import { EncryptComponentNode } from '../nodes/encryptComponentNode';
import {GeneratorKeyComponentNode} from '../nodes/generatorKeyComponentNode';
import { ChangeComponentNode } from '../nodes/changeComponentNode';
import StoreProcedureNodeConst from '../constants/StoreProcedureNodeConst';
import DisplayComponentNodeConst from '../constants/DisplayComponentNodeConst';
import EncryptComponentNodeConst from '../constants/EncryptComponentNodeConst';
import GeneratorKeysComponentNodeConst from '../constants/GeneratorKeysComponentNodeConst';
import MessageNodeConst from '../constants/MessageNodeConst';
import ConditionNodeConst from '../constants/ConditionNodeConst';
import { ConditionNode } from '../nodes/conditionNode';
import TextNodeConst from '../constants/TextNodeConst';
import ChangeComponentNodeConst from '../constants/ChangeComponentNodeConst';
import ValidateComponentNodeConst from '../constants/ValidateComponentNodeConst';
import DataSourceNodeConst from '../constants/DataSourceNodeConst';
import { EndNode } from '../nodes/endNode';
import NavigationNodeConst from '../constants/NavigationNodeConst';
import { UbmNode } from '../nodes/ubmNode';
import UbmNodeConst from '../constants/UbmNodeConst';
import SendMailNodeConst from '../constants/SendMailNodeConst';
import { IapWorkFlowActivityControl } from '../../../../domain/service/iapWorkFlowActivityControl';
import SwitchNodeConst from '../constants/SwitchNodeConst';
import { SwitchNode } from '../nodes/switchNode';
import CacheNodeConst from '../constants/CacheNodeConst';
import { CacheNode } from '../nodes/cacheNode';


export class Connection<
  A extends NodeProps,
  B extends NodeProps
> extends Classic.Connection<A, B> {
  isLoop?: boolean;
}

export type AreaExtra =
  | Area2D<Schemes>
  | VueArea2D<Schemes>
  | ContextMenuExtra
  | MinimapExtra
  | RerouteExtra;

export type NodeProps = EndNode | StartNode | LogNode | TextNode | DataSourceNode | NavigationNode | DelayNode | StoreProcedureNode | DisplayComponentNode | ChangeComponentNode | MessageNode | MessageHideNode |  SendMailNode | ConditionNode | ValidateComponentNode | UbmNode | EncryptComponentNode | GeneratorKeyComponentNode
| SwitchNode | CacheNode ;
export type ConnProps = Connection<StartNode, LogNode> 
| Connection<TextNode, LogNode> 
| Connection<StartNode, EndNode>

| Connection<StartNode, DataSourceNode>
| Connection<DataSourceNode, DataSourceNode>
| Connection<DataSourceNode, EndNode>

| Connection<StartNode, DelayNode>
| Connection<DelayNode, DelayNode>
| Connection<DelayNode, EndNode>
| Connection<StartNode, NavigationNode>
| Connection<NavigationNode, NavigationNode>
| Connection<NavigationNode, EndNode>

| Connection<StartNode, StoreProcedureNode>
| Connection<StoreProcedureNode, StoreProcedureNode>
| Connection<StoreProcedureNode, EndNode>

| Connection<StartNode, DisplayComponentNode>
| Connection<DisplayComponentNode, DisplayComponentNode>
| Connection<DisplayComponentNode, EndNode>

| Connection<StartNode, ChangeComponentNode>
| Connection<ChangeComponentNode, ChangeComponentNode>
| Connection<ChangeComponentNode, EndNode>

| Connection<StartNode, MessageNode>
| Connection<MessageNode, MessageNode>
| Connection<MessageNode, EndNode>

| Connection<StartNode, MessageHideNode>
| Connection<MessageHideNode, MessageHideNode>
| Connection<MessageHideNode, EndNode>


| Connection<StartNode, ConditionNode>
| Connection<ConditionNode, ConditionNode>
| Connection<ConditionNode, EndNode>
| Connection<StoreProcedureNode, ConditionNode>

| Connection<StartNode, ValidateComponentNode>
| Connection<ValidateComponentNode, ValidateComponentNode>
| Connection<ValidateComponentNode, EndNode>


| Connection<StartNode, UbmNode>
| Connection<UbmNode, UbmNode>
| Connection<UbmNode, EndNode>

| Connection<StartNode, SendMailNode>
| Connection<SendMailNode, SendMailNode>
| Connection<SendMailNode, EndNode>


| Connection<StartNode, EncryptComponentNode>
| Connection<EncryptComponentNode, EncryptComponentNode>
| Connection<EncryptComponentNode, EndNode>

| Connection<StartNode, GeneratorKeyComponentNode>
| Connection<GeneratorKeyComponentNode, GeneratorKeyComponentNode>
| Connection<GeneratorKeyComponentNode, EndNode>

| Connection<StartNode, SwitchNode>
| Connection<SwitchNode, SwitchNode>
| Connection<SwitchNode, EndNode>

| Connection<StartNode, CacheNode>
| Connection<CacheNode, CacheNode>
| Connection<CacheNode, EndNode>




;


export type Schemes = GetSchemes<NodeProps, ConnProps>;



export default function HelperRete(
  router:Router,
  container: HTMLElement | undefined, wf: IapWorkFlow, catalogTypes: IapCatalogType[], srvContainer: Container | undefined, store: any
  , applicationId:number, applicationVersion:number,rootParentId:number,formKey:String,currentElementKey:string, componentId: number, componentData: IapComponent[], updateNode: any = undefined,
  getNodeInternalData: any = undefined,showExpressionControl: any = undefined,callBackEndFunction: any = undefined
) {

  const { addWorkFlowActivity, updateWorkFlowActivityVariable,updateWorkFlowActivityControl, addWorkFlowActivityControl,
    deleteWorkFlowActivityControl, addWorkFlowActivityConnection, deleteWorkFlowActivity, removeWorkFlowActivityConnection } = HelperWorkFlow(wf, srvContainer, store);

  let area: any = null;
  if (container) {
    area = new AreaPlugin<Schemes, AreaExtra>(container);
  }



  const socket = new Classic.Socket('socket');
  const currentComponent = componentData.find(x => x.id == componentId)
  const dataSources = componentData.filter(x => x.iapComponentDataSources).flatMap(x => x.iapComponentDataSources)




  const dataflow = new DataflowEngine<Schemes>(({ inputs, outputs }) => {
    return {
      inputs: () => Object.keys(inputs).filter((name) => name !== "ejecutar"),
      outputs: () => Object.keys(outputs).filter((name) => name !== "ejecutar")
    };
  });

  const engine = new ControlFlowEngine<Schemes>(() => {
    return {
      inputs: () => ["ejecutar","dataInput","message","objectOutput"],
      outputs: () => ["ejecutar","ok","cancel","true","false","error","dataOutput","option1","option2","option3","option4","option5","default"]
    };
  });



/*
  const getCommandsList = (tipo: string, catalogTypes: IapCatalogType[]) => {

    if (catalogTypes && catalogTypes.length > 0) {
      const cats = catalogTypes.flatMap(x => x.iapCatalogs)
      if (cats) {
        return cats.filter(x => x.typeId == tipo && x.active).map(x => x.description, () => new StartNode(socket))
      }
    }

    return [];

  }
  */

  const getTypeFromNode = (data: any): string => {
    if (data instanceof StartNode) {
      return CatalogCommandConst.CMD_START;
    }

    if (data instanceof EndNode) {
      return CatalogCommandConst.CMD_END;
    }


    if (data instanceof LogNode) {
      return CatalogCommandConst.CMD_MSG;
    }


    if (data instanceof DataSourceNode) {
      return CatalogCommandConst.CMD_SELECTDATASOURCE;
    }


    if (data instanceof DelayNode) {
      return CatalogCommandConst.DELAY;
    }

    if (data instanceof NavigationNode) {
      return CatalogCommandConst.CMD_REDIRECT;
    }
    if (data instanceof StoreProcedureNode){
      return CatalogCommandConst.EXEC_PROC;
    }

    if (data instanceof DisplayComponentNode){
      return CatalogCommandConst.EXEC_SHOWFORM;
    }

    if (data instanceof ChangeComponentNode){
      return CatalogCommandConst.CMD_CHANGECOMP;
    }

    
    if (data instanceof MessageNode){
      return CatalogCommandConst.CMD_MSG;
    }

    if (data instanceof MessageHideNode){
      return CatalogCommandConst.CMD_MSG_HIDE;
    }

    

    if (data instanceof ConditionNode){
      return CatalogCommandConst.CMD_CONDICION;
    }

    if (data instanceof SwitchNode){
      return CatalogCommandConst.CMD_SWITCH;
    }

    if (data instanceof CacheNode){
      return CatalogCommandConst.CMD_CACHE;
    }
    
    
   
    if (data instanceof ValidateComponentNode){
      return CatalogCommandConst.CMD_VALIDATECOMP;
    }


    if (data instanceof UbmNode){
      return CatalogCommandConst.CMD_UBM
    }

    if (data instanceof SendMailNode){
      return CatalogCommandConst.CMD_SENDMAIL
    }

    if (data instanceof EncryptComponentNode){
      return CatalogCommandConst.CMD_ENCRYPT
    }

    if (data instanceof GeneratorKeyComponentNode){
      return CatalogCommandConst.CMD_KEYGEN
    }

    

    return CatalogCommandConst.CMD_TEXT;

  }


  const addWorkFlowActivityFromContext = async (context: any) => {    
    const data = {
      type: getTypeFromNode(context.data),
      left: 100,
      top: 100,
      color: '#FFFFFF',
      name: context.data.id,
      label: context.data.label ?? '',
      isInvalid: false,
      canDelete: true,
      inputNames: Object.keys(context.data.inputs).map(k =>
      ({
        key: k,
        value: context.data.controls[k]?.value?.toString() ?? ''
      })
      )
      ,
    
      outputNames: Object.keys(context.data.outputs).map(k =>
      ({
        key: k,
        value: context.data.controls[k]?.value?.toString() ?? ''
      })
      ),
      controls: Object.keys(context.data.controls).map(k =>
        ({
          key: k,
          value: context.data.controls[k]?.value?.toString() ?? ''
        })
        ).concat(
          Object.keys(context.data.controls?.value ?? []).filter(x=> x && x?.startsWith('variable')).map(k =>
            ({
              key: k,
              value: JSON.stringify(HelperCommon.isNullOrWhitespace(context.data.controls.value[k]) ? '' :context.data.controls.value[k])
            }) 
        ) ?? [] 
        ),
    }


    await addWorkFlowActivity(data);
  }

  const updateWorkFlowActivityVariableFromContext = (id:string, variableName:string, variableValue:any, isInput:boolean) => {
    const sourceact = wf?.iapWorkFlowActivities.find(x => x.name == id);

    if (sourceact) {

      const variable = sourceact.iapWorkFlowActivityVariables.find(x=> x.name == variableName && x.isInput == isInput)
      if (variable && variable.defaultValue !== variableValue?.toString()){
        variable.defaultValue = variableValue?.toString() ?? ''
        variable.fum=new Date();
        variable.uum =  store.getters.getCurrentUser.id,
        updateWorkFlowActivityVariable([variable])
      }
    }
  }

  
  const updateWorkFlowActivityControlFromContext = async (id:string, variableName:string, variableValue:any) => {    
    const sourceact = wf?.iapWorkFlowActivities.find(x => x.name == id);
    
    if (sourceact) {
      const variableControl = sourceact.iapWorkFlowActivityControls.find(x=> x.name == variableName )
      if (variableControl && variableControl.value !== variableValue?.toString()){
        variableControl.value = variableValue?.toString() ?? ''
        await updateWorkFlowActivityControl(variableControl)
      }
    }
  }

  const addWorkFlowActivityControlFromContext = async (id:string, variableName:string, variableValue:any) => {
    const sourceact = wf?.iapWorkFlowActivities.find(x => x.name == id);
    
    if (sourceact) {        
        const newItem : IapWorkFlowActivityControl =({
          id: 0,
          workFlowActivityId: sourceact.id,
          name: variableName,
          value: variableValue,
          localizable: false,
          group: HelperUtils.newGuid(),
          
      })

      await addWorkFlowActivityControl(newItem)
      
    }
  }


  const deleteWorkFlowActivityControlFromContext = async (id:string, variableName:string) => {
    const sourceact = wf?.iapWorkFlowActivities.find(x => x.name == id);
    
    if (sourceact) {
      const variableControl = sourceact.iapWorkFlowActivityControls.find(x=> x.name == variableName )
      if (variableControl)
      {
        await deleteWorkFlowActivityControl(variableControl)
      }
      
    }
  }


  const getWorkFlowActivityControlFromContext = (id:string, variableName:string) => {
    const sourceact = wf?.iapWorkFlowActivities.find(x => x.name == id);
    
    if (sourceact) {
      const variableControl = sourceact.iapWorkFlowActivityControls.find(x=> x.name == variableName )
      return variableControl;
    }
  }

  const getWorkFlowActivityVariableFromContext = (id:string, variableName:string, isInput:boolean) => {
    const sourceact = wf?.iapWorkFlowActivities.find(x => x.name == id);

    if (sourceact) {

      const variable = sourceact.iapWorkFlowActivityVariables.find(x=> x.name == variableName && x.isInput == isInput)
      return variable;
    }
  }


  const addWorkFlowActivityConnectionFromContext = (context: any) => {
    const sourceact = wf?.iapWorkFlowActivities.find(x => x.name == context.data.source);
    const targetact = wf?.iapWorkFlowActivities.find(x => x.name == context.data.target);
    if (sourceact && targetact) {
      const auxcn: IapWorkFlowActivityConnection = ({
        id: 0,
        externalId: context.data.id,
        workFlowActivityVariableIdInput: sourceact.iapWorkFlowActivityVariables.find(v => v.name == context.data.sourceOutput)?.id ?? -1,
        workFlowActivityVariableIdOutput: targetact.iapWorkFlowActivityVariables.find(v => v.name == context.data.targetInput)?.id ?? -1,
        fcr: new Date(),
        ucr: store.getters.getCurrentUser.id,
        uum: store.getters.getCurrentUser.id,
        fum: new Date(),
        group:HelperUtils.newGuid(),
      })

      addWorkFlowActivityConnection(sourceact.id, targetact.id, [auxcn])
    }
  }


  const removeWorkFlowActivityConnectionFromContext = (iapconn: IapWorkFlowActivityConnection, context: any) => {
    const dsourceact = wf?.iapWorkFlowActivities.find(x => x.name == context.data.source);
    const dtargetact = wf?.iapWorkFlowActivities.find(x => x.name == context.data.target);
    if (iapconn && dsourceact && dtargetact) {
      removeWorkFlowActivityConnection(dsourceact.id, dtargetact.id, iapconn)
    }
  }

  function addDataToEditor(editor: NodeEditor<Schemes>, item: IapWorkFlowActivity, dataFlow: DataflowEngine<Schemes>) {
    let newnode: Classic.Node = {} as any;
    switch (item.typeWorkFlowId) {
      case CatalogCommandConst.CMD_START:
        newnode = new StartNode(socket);
        newnode.id = item.name;
        //editor.addNode(newnode as any)
        break;

      case CatalogCommandConst.CMD_END:
          newnode = new EndNode(socket,callBackEndFunction,dataflow);
          newnode.id = item.name;
          //editor.addNode(newnode as any)
          break;

      case CatalogCommandConst.CMD_MSG:
          const selectedMsg = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == MessageNodeConst.VAR_MSG_IN)?.value ?? '');
          const selectedPMsgType =parseInt(JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == MessageNodeConst.VAR_MSGTYPE_IN)?.value ?? '1')); //parseInt(item.iapWorkFlowActivityControls.find(x => x.name == 'value')?.value ?? '-1');          
          const selectedModalType =JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == MessageNodeConst.VAR_MSGMODAL_IN)?.value ?? 'false'); //parseInt(item.iapWorkFlowActivityControls.find(x => x.name == 'value')?.value ?? '-1');          
          const selectedCounterType =JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == MessageNodeConst.VAR_MSGCOUNTER_IN)?.value ?? 'false'); //parseInt(item.iapWorkFlowActivityControls.find(x => x.name == 'value')?.value ?? '-1');          
          newnode = new MessageNode(area, socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, selectedMsg,selectedPMsgType,selectedModalType,selectedCounterType, updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,item, dataflow,componentData);
          newnode.id = item.name;

        break;

        case CatalogCommandConst.CMD_MSG_HIDE:          
          newnode = new MessageHideNode(socket);
          newnode.id = item.name;
  
          break;

        case CatalogCommandConst.CMD_TEXT:
          const selectedText = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == TextNodeConst.VAR_TEXT_IN)?.value ?? '');
          newnode = new TextNode(area, socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, selectedText,updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,item,componentData);
          newnode.id = item.name;

        break;

      case CatalogCommandConst.CMD_SELECTDATASOURCE:
        const variableInceptionInput = parseInt(item.iapWorkFlowActivityControls.find(x => x.name == DataSourceNodeConst.VAR_INCEPTION_IN)?.value ?? '-1');
        const variableComponentIdInput = parseInt(item.iapWorkFlowActivityControls.find(x => x.name == DataSourceNodeConst.VAR_COMPONENTID_IN)?.value ?? '-1');
        const variableCfgsInput =JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == DataSourceNodeConst.VAR_CFG_IN)?.value ?? '[]') as any;
        const variableFieldsInput =JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == DataSourceNodeConst.VAR_FIELD_IN)?.value ?? '[]') as any;
        const variableConnectionInput = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == DataSourceNodeConst.VAR_CONN_IN)?.value ?? '""');
        const variableDsIdInput = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == DataSourceNodeConst.VAR_DS_IN)?.value ?? '""');
        const variableOperationInput = parseInt(item.iapWorkFlowActivityControls.find(x => x.name == DataSourceNodeConst.VAR_DS_OPERATION)?.value ?? '-1');
        const variableComponentDataSourceIdInput = parseInt(item.iapWorkFlowActivityControls.find(x => x.name == DataSourceNodeConst.VAR_COMPONENTDATASOURCEID_IN)?.value ?? '-1');
        const variableComponentDataSourceListIdInput = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == DataSourceNodeConst.VAR_COMPONENTDATASOURCELISTID_IN)?.value ?? '[]') as any;




        newnode = new DataSourceNode(area,socket,dataflow,updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,item,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion
          ,variableInceptionInput,variableOperationInput,variableComponentIdInput,variableComponentDataSourceIdInput,variableComponentDataSourceListIdInput,variableConnectionInput,variableDsIdInput,variableFieldsInput as any,variableCfgsInput as any,dataSources,componentData);

        newnode.id = item.name;

        break;

      case CatalogCommandConst.CMD_REDIRECT:

        const selectedMenu = parseInt(item.iapWorkFlowActivityControls.find(x => x.name == NavigationNodeConst.VAR_MENUID)?.value ?? '-1');
        const parameterInput = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == NavigationNodeConst.VAR_PAR)?.value ?? '[]') as any;

        const variableRedirectTypeInput = parseInt(item.iapWorkFlowActivityControls.find(x => x.name == NavigationNodeConst.VAR_TIPO)?.value ?? '0');
        const variableParamsRedirectUrlInput = item.iapWorkFlowActivityControls.find(x => x.name == NavigationNodeConst.VAR_PARURL)?.value ?? '';
        const variableParamsRedirectUrlTargetInput = item.iapWorkFlowActivityControls.find(x => x.name == NavigationNodeConst.VAR_PARURLTARGET)?.value ?? '_blank';
        const variableParamsRedirectUrlParamsInput = item.iapWorkFlowActivityControls.find(x => x.name == NavigationNodeConst.VAR_PARURLPARAMS)?.value ?? '';
        let variablecompsInput = null;
        try{
          variablecompsInput = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == NavigationNodeConst.VAR_COMPS)?.value ?? 'null');
        }catch{
          variablecompsInput = null;
        }
        

        

        //area,socket,formKey,currentElementKey,rootParentId,componentId,-1,'',updateNode,getNodeInternalData,showExpressionControl,router,store,componentData,undefined,dataflow
        newnode = new NavigationNode(area,socket,formKey,currentElementKey,rootParentId,componentId,
          applicationId,applicationVersion,
          variableRedirectTypeInput,variableParamsRedirectUrlInput,variableParamsRedirectUrlTargetInput,
          variableParamsRedirectUrlParamsInput,
          selectedMenu,parameterInput,variablecompsInput,updateNode,getNodeInternalData,showExpressionControl,router,store,componentData,item,dataflow,srvContainer);
        newnode.id = item.name;

        break;

        case CatalogCommandConst.DELAY:
          const selectedValueDelay = parseInt(item.iapWorkFlowActivityControls.find(x => x.name == 'value')?.value ?? '-1');
          newnode = new DelayNode(socket, selectedValueDelay, updateNode);
          newnode.id = item.name;
  
          break;

          case CatalogCommandConst.EXEC_PROC:
          const selectedConn = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == StoreProcedureNodeConst.VAR_CONN_IN)?.value ?? '""');
          const selectedProc = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == StoreProcedureNodeConst.VAR_PROC_IN)?.value ?? '""'); //parseInt(item.iapWorkFlowActivityControls.find(x => x.name == 'value')?.value ?? '-1');
          const selectedParamInputs =JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == StoreProcedureNodeConst.VAR_PARAM_IN)?.value ?? '[]') as any;
          newnode = new StoreProcedureNode(area, socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, selectedConn,selectedProc,selectedParamInputs, updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,item,dataflow,componentData);
          newnode.id = item.name;

          break;

          case CatalogCommandConst.EXEC_SHOWFORM:
            const selectedComp = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == DisplayComponentNodeConst.VAR_COMP_IN)?.value ?? '""');
            const selectedCompModal = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == DisplayComponentNodeConst.VAR_COMPMODAL_IN)?.value ?? '""'); //parseInt(item.iapWorkFlowActivityControls.find(x => x.name == 'value')?.value ?? '-1');
            const selectedCompCurrModal = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == DisplayComponentNodeConst.VAR_COMPCURR_IN)?.value ?? '""'); //parseInt(item.iapWorkFlowActivityControls.find(x => x.name == 'value')?.value ?? '-1');
            const selectedCompParamInputs =JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == DisplayComponentNodeConst.VAR_PARAM_IN)?.value ?? '[]') as any;
            const selectedCompHeader = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == DisplayComponentNodeConst.VAR_HEADER_IN)?.value ?? '""'); //parseInt(item.iapWorkFlowActivityControls.find(x => x.name == 'value')?.value ?? '-1');
            newnode = new DisplayComponentNode(area, socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, selectedComp,selectedCompHeader,selectedCompModal,selectedCompParamInputs,selectedCompCurrModal, updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,item,dataflow,componentData);
            newnode.id = item.name;
  
          

            break;

            case CatalogCommandConst.CMD_ENCRYPT:
              const selectedData = item.iapWorkFlowActivityControls.find(x => x.name == EncryptComponentNodeConst.VAR_DATA)?.value ?? '';
              const UrlEncode = HelperUtils.booleanify(item.iapWorkFlowActivityControls.find(x => x.name == EncryptComponentNodeConst.VAR_ENCODEURL)?.value ?? 'false');
              newnode = new EncryptComponentNode(area, socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, 
                selectedData,UrlEncode, updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,item,dataflow,componentData);
              newnode.id = item.name;

              break;

              case CatalogCommandConst.CMD_KEYGEN:
                const selectedOptionType = Number(item.iapWorkFlowActivityControls.find(x => x.name == GeneratorKeysComponentNodeConst.VAR_TYPE)?.value ?? '1');
                const selectedOptions = item.iapWorkFlowActivityControls.find(x => x.name == GeneratorKeysComponentNodeConst.VAR_OPTIONS)?.value ?? null;
                const selectedKeyHash = HelperUtils.booleanify(item.iapWorkFlowActivityControls.find(x => x.name == GeneratorKeysComponentNodeConst.VAR_HASH)?.value ?? 'false');                
                
                newnode = new GeneratorKeyComponentNode(area, socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, 
                  selectedOptionType,selectedOptions,selectedKeyHash, updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,item,dataflow,componentData);
                newnode.id = item.name;
  
                break;

                

            case CatalogCommandConst.CMD_CHANGECOMP:
              const selectedCompId = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == ChangeComponentNodeConst.VAR_COMP_IN)?.value ?? '""');
              const selectedCompAttrsInputs =JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == ChangeComponentNodeConst.VAR_ATTR_IN)?.value ?? '[]') as any;              
              const selectedCompEventsInputs =JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == ChangeComponentNodeConst.VAR_EVENT_IN)?.value ?? '[]') as any;              
              newnode = new ChangeComponentNode(area, socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, selectedCompId,selectedCompAttrsInputs,selectedCompEventsInputs, updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,item,dataflow,componentData);
              newnode.id = item.name;
    
            
  
              break;

              case CatalogCommandConst.CMD_VALIDATECOMP:
                const selectedValCompId = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == ValidateComponentNodeConst.VAR_COMP_IN)?.value ?? '""');
                
                newnode = new ValidateComponentNode(area, socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, selectedValCompId, updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,item,store,dataflow,componentData);
                newnode.id = item.name;
      
              
    
                break;
  

              
  
            case CatalogCommandConst.CMD_CONDICION:
              const selectedCondition = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == ConditionNodeConst.VAR_COND_IN)?.value ?? '"');
              newnode = new ConditionNode(area, socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, selectedCondition,updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,item,dataflow,componentData);
              newnode.id = item.name;
    
            break;
            
            case CatalogCommandConst.CMD_SWITCH:
              const selectedCondition0 = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == SwitchNodeConst.VAR_COND_IN0)?.value ?? '"');
              const selectedCondition1 = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == SwitchNodeConst.VAR_COND_IN1)?.value ?? '"');
              const selectedCondition2 = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == SwitchNodeConst.VAR_COND_IN2)?.value ?? '"');
              const selectedCondition3 = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == SwitchNodeConst.VAR_COND_IN3)?.value ?? '"');
              const selectedCondition4 = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == SwitchNodeConst.VAR_COND_IN4)?.value ?? '"');

              newnode = new SwitchNode(area, socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, selectedCondition0, selectedCondition1, selectedCondition2, selectedCondition3, selectedCondition4,updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,item,dataflow,componentData);
              newnode.id = item.name;
    
            break;

            

            case CatalogCommandConst.CMD_UBM:
              const selectedPhone = item.iapWorkFlowActivityControls.find(x => x.name == UbmNodeConst.VAR_PHONE_IN)?.value ?? '';              
              newnode = new UbmNode(area, socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, selectedPhone, updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,item, dataflow,componentData);
              newnode.id = item.name;
    
            break;
          
            case CatalogCommandConst.CMD_SENDMAIL:
              const selectedTo = item.iapWorkFlowActivityControls.find(x => x.name == SendMailNodeConst.VAR_TO_IN)?.value ?? '';
              const selectedCc = item.iapWorkFlowActivityControls.find(x => x.name == SendMailNodeConst.VAR_CC_IN)?.value ?? '';
              const selectedSubject = item.iapWorkFlowActivityControls.find(x => x.name == SendMailNodeConst.VAR_SUBJECT_IN)?.value ?? '';
              const selectedBody = item.iapWorkFlowActivityControls.find(x => x.name == SendMailNodeConst.VAR_BODY_IN)?.value ?? '';
              newnode = new SendMailNode(area, socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, selectedTo, selectedCc, selectedSubject, selectedBody, updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,store,item, dataflow,componentData);
              newnode.id = item.name;
    
            break;

            case CatalogCommandConst.CMD_CACHE:
              const selectedOption = JSON.parse(item.iapWorkFlowActivityControls.find(x => x.name == CacheNodeConst.VAR_OPTION)?.value ?? '""');
              
              newnode = new CacheNode(area, socket,formKey,currentElementKey,rootParentId,componentId,applicationId,applicationVersion, selectedOption, updateNode,getNodeInternalData,showExpressionControl,router,srvContainer,item,store,dataflow,componentData);
              newnode.id = item.name;
    
            
  
              break;


    }

   



    return newnode;
  }

  return {
    wf,
    dataflow,
    engine,
    socket,
    deleteWorkFlowActivity,
    addWorkFlowActivityFromContext,
    addWorkFlowActivityConnectionFromContext,
    removeWorkFlowActivityConnectionFromContext,
    addDataToEditor,
    area,
    currentComponent,
    dataSources,
    updateNode,
    getNodeInternalData,
    showExpressionControl,
    updateWorkFlowActivityVariableFromContext,
    updateWorkFlowActivityControlFromContext,
    addWorkFlowActivityControlFromContext,
    deleteWorkFlowActivityControlFromContext,
    getWorkFlowActivityControlFromContext,
    getWorkFlowActivityVariableFromContext

  };
}





//////////////////////////////////////////////////////////////








