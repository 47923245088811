<template>
    <SummaryDebug v-if="getPropertyBooleanValue(BaseControlTypeConst.DEBUG, Component)" :data="Component"
        :vmodel="newMail" />
    <div :key="componentKey"
        v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)">

        <div v-if="getPropertyValue(EmailTypeConst.CAPTION)"
            class="flex align-items-center px-4 md:px-0 border-top-1 surface-border md:border-none pt-4 md:pt-0">
            <!--<Button type="button" icon="pi pi-chevron-left" class="surface-border text-900 w-3rem h-3rem mr-3" outlined severity="secondary" @click="goBack()"></Button>-->
            <span class="block text-900 font-bold text-xl" v-html="getPropertyValue(EmailTypeConst.CAPTION)"></span>
        </div>
        <div
            class="surface-section grid mt-4 grid-nogutter formgrid p-4 gap-3 md:surface-border md:border-1 border-round">
            <div class="col-12 field">
                <a v-if="hasLayoutTo" href="#" @click="opViewContacts = true" class=" font-semibold"
                    :class="{ 'p-invalid': v$.to.$error && submitted, 'customrequired': true }"> Para </a>
                <label v-else for="to" :class="{ 'p-invalid': v$.to.$error && submitted, 'customrequired': true }"
                    class="text-900 font-semibold">Para </label>
                <InputText id="to" v-model="newMail.to" type="text" :class="{ 'p-invalid': v$.to.$error && submitted }"
                    aria-describedby="text-error" style="width:100%" />
                <span v-if="submitted">
                    <span v-for="error in v$.to.$errors" :key="error.$uid">
                        <small class="p-error">{{ error.$message }}</small>
                    </span>
                </span>
            </div>
            <div class="col-12 field">
                <label for="cc" class="text-900 font-semibold">CC </label>
                <InputText id="cc" type="text" v-model="newMail.cc" style="width:100%" />

            </div>
            <div class="col-12 field">
                <label for="Subject" class="text-900 font-semibold"
                    :class="{ 'p-invalid': v$.subject.$error && submitted, 'customrequired': true }">Asunto </label>
                <InputText id="subject" type="text" v-model="newMail.subject" placeholder="Asunto"
                    :class="{ 'p-invalid': v$.subject.$error && submitted }" style="width:100%" />
                <span v-if="submitted">
                    <span v-for="error in v$.subject.$errors" :key="error.$uid">
                        <small class="p-error">{{ error.$message }}</small>
                    </span>
                </span>
            </div>
            <div class="col-12 field">
                <Panel header="Adjuntos" toggleable :collapsed=true>
                    <FileUpload ref="fileupload"
                        accept=".csv,.xlsx,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.pdf,.zip,.rar,.gz,.tar,.tgz,.7z,.bmp,.jpg,.jpeg,.tiff,.png,.ico,.gif,.msg"
                        :maxFileSize="1000000" label="UploadFile" chooseLabel="Adjuntar archivo a importar"
                        class="mr-2 inline-block p-button-secondary" cancelLabel="Eliminar los archivos"
                        :showUploadButton="false" @select="onSelectedFiles" @remove="onRemoveFiles"
                        @clear="onClearFiles">
                        <template #empty>
                            <p>Arrastre y suelte aquí el fichero que desea adjuntar.</p>
                        </template>
                    </FileUpload>

                    <Panel
                        v-if="getPropertyValue(EmailTypeConst.IDOBJ) && getPropertyValue(EmailTypeConst.OBJID) && getPropertyValue(EmailTypeConst.OBJID)"
                        header="Otros documentos" toggleable :collapsed=true class="mt-1">
                        <CrudDocument :rootParentId="Component.rootParentId" :container="container" :Component="Component"
                            :caption="'Otros Documentos'" :idObject="getPropertyValue(EmailTypeConst.IDOBJ)"
                            :objectId="getPropertyNumberValue(EmailTypeConst.OBJID)" :canAdd="false" :canEdit="false"
                            :canDelete="false" :onlyListDocument="true" :showAddinPopUp="false"
                            :multipleSelection="true" :slotProps="slotProps"
                            :entityMainIntegracionId="getPropertyNumberValue(EmailTypeConst.ENT_MAIN_INT_ID)"
                            v-model:multipleSelectionData="multipleSelectionData"
                            :source="getPropertyValue(EmailTypeConst.DOCSOURCE)">

                        </CrudDocument>
                    </Panel>

                </Panel>
            </div>

            <div class="col-12 field">
                <Toolbar>
                    <template #start>
                        <a v-if="hasLayoutTemplateBody && checked" href="#" @click="opViewTemplates = true" class=" font-semibold"
                        :class="{ 'p-invalid': v$.body.$error && submitted, 'customrequired': true }"> Mensaje </a>
                        <label v-else-if="checked" for="Subject" class="text-900 font-semibold"
                            :class="{ 'p-invalid': v$.body.$error && submitted, 'customrequired': true }">Mensaje</label>
                    </template>

                 

                    <template #end>
                        <ToggleButton v-model="checked" onLabel="Vista Previa" offLabel="Editor" onIcon="pi pi-envelope" style="height: 36px;"
                        offIcon="pi pi-file-edit" class="mr-1" aria-label="Do you confirm" />

                        <Button v-if="hasMicrosoftSession" type="button" class="h-3rem w-full sm:w-auto" icon="pi pi-microsoft" label="Office365" style="margin-right:3px;"
                            @click="openOutlookCompose(paramEmail.id)"></Button>
                            <!--@click="openMailTo(newMail.to,newMail.cc,newMail.subject,newMail.body)"></Button>-->

                        <Button v-if="hasMicrosoftSession && !paramEmail.id" type="button" class="h-3rem w-full sm:w-auto" icon="pi pi-save" label="Borrador" style="margin-right:3px;"
                            @click="createDraft()"></Button>

                        <Button type="button" class="h-3rem w-full sm:w-auto" icon="pi pi-send" label="Enviar"
                            @click="sendMail()"></Button>
                    </template>
                </Toolbar>

                <Editor v-if="checked" v-model="newMail.body" editorStyle="width:100%; height: 250px"
                    :class="{ 'p-invalid': v$.body.$error && submitted }" />
                    <div v-else v-html="newMail.body" style="width:100%; height: 250px" /> 
                <span v-if="submitted">
                    <span v-for="error in v$.body.$errors" :key="error.$uid">
                        <small class="p-error">{{ error.$message }}</small>
                    </span>
                </span>
              
            </div>


        </div>





    </div>
    <Dialog v-if="hasLayoutTo" v-model:visible="opViewContacts" modal header=" "
        styleClass="mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6"
        contentStyleClass="border-round-bottom border-top-1 surface-border p-0" :closable="true" :maximizable="true">
        <slot name="to"></slot>

    </Dialog>
    <Dialog v-if="hasLayoutTemplateBody" v-model:visible="opViewTemplates" modal header=" "
        styleClass="mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6"
        contentStyleClass="border-round-bottom border-top-1 surface-border p-0" :closable="true" :maximizable="true">

        <slot name="body"></slot>

    </Dialog>
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, shallowRef, onBeforeUnmount, watch, watchEffect } from 'vue';
import { MessageService } from '../../../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../../../common/infrastructure/servicios/MessageService';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';


import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import Editor from 'primevue/editor';
import EmailTypeConst from '../../../../domain/Constants/EmailTypeConst';
import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
import CrudDocument from '../../../../../../builderDocument/document/infrastructure/component/CrudDocument.vue'
import { StorageDocument } from '../../../../../../builderDocument/document/domain/storageDocument';
import { FileUploadRemoveEvent } from 'primevue/fileupload';
import { uploadFile } from '../../../../../../builderMaster/auth/domain/uploadFile';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import { Actions } from '../../../../../../../common/infrastructure/almacen/enums/StoreEnums';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import { getAccessToken, getAccessTokenIfExist, initializeMsalInstance, msalInstance } from '@ilinium/shared/src/entidades/builderMaster/auth/infrastructure/component/authConfig';
import HelperUtils from '../../../../../../../common/infrastructure/funciones/HelperUtils';

export default defineComponent({
    name: 'dynamic_crud_tabla',
    components: {
        SummaryDebug,
        Editor,
        CrudDocument
    },
    emits: ['update-email-id', 'pageChange', 'navigate'],
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
       
        slotProps: {
            type: Object,
            default: () => ({})
        },
        formKey: {
            type: String,
            default: ''
        },
        paramEmail: {
            type: Object,
            default: () => ({})
        },
        
        objectId: {
            type: Number,
            default: -1
        },
        idObject: {
            type: Number,
            default: -1
        },
    },
    setup(props, { emit }) {
        //
        const store = useStore();
        const { vmodel, canDoOperation, getProperty, getPropertyValue, setPropertyValueByName, 
            getPropertyBooleanValue, getPropertyNumberValue, getLayoutComponent, loaded, processEventComponent
            ,componentKey,baseOnBeforeUnmount
        } = ComponentCommonRender(ref(props.Component).value ,props.slotProps, props.container,store);
        const uploadFiles = ref<uploadFile[]>([])
        const reglas = {
            to: {
                required: helpers.withMessage("El email es obligatorio", required),
            },
            subject: {
                required: helpers.withMessage("El asunto es obligatorio", required),
            },
            body: {
                required: helpers.withMessage("El mensaje es obligatorio", required),
            },
        }

        const fileupload = ref();
        const submitted = ref(false);
        const hasMicrosoftSession = ref(false);
        const checked = ref(true);
        const hasLayoutTo = shallowRef(getLayoutComponent(props.Component, ControlTypeConst.CTLAYOUT_MAIL_TO) !== undefined || getLayoutComponent(props.Component, ControlTypeConst.CTLAYOUT_DASHBOARDMAIL_TO) !== undefined);
        const hasLayoutTemplateBody = shallowRef(getLayoutComponent(props.Component, ControlTypeConst.CTLAYOUT_MAIL_BODY) !== undefined || getLayoutComponent(props.Component, ControlTypeConst.CTLAYOUT_DASHBOARDMAIL_BODY) !== undefined);
        const opViewContacts = ref(false);
        const opViewTemplates = ref(false);
        let isLoaded = false;
        const multipleSelectionData = ref<StorageDocument[]>([]);

        const navigate = (item) => {
            const targetSection = item.routerLink || 'compose';
            emit('navigate', targetSection); // Emitimos el evento
        };

        const newMail = ref({
            to: props.paramEmail?.toRecipients?.map(r => r.emailAddress.address).join(';') ?? (getPropertyValue(EmailTypeConst.TO) ?? ''),
            cc: props.paramEmail?.ccRecipients?.map(r => r.emailAddress.address).join(';') ?? (getPropertyValue(EmailTypeConst.CC) ?? ''),
            subject: props.paramEmail?.subject ?? (getPropertyValue(EmailTypeConst.SUBJECT) ?? ''),
            body: props.paramEmail?.body?.content ?? (getPropertyValue(EmailTypeConst.MSG) ?? '')
        });

        const v$ = useVuelidate(reglas, newMail as any);

        const isValidData = (): boolean => {
            submitted.value = true;
            v$.value.$reset();
            v$.value.$touch();
            if (v$.value.$invalid) {
                HelperCommon.setFocusOnFirstError();
                return false;
            }

            return true;
        }

        const clearData = () => {
            uploadFiles.value = [];
            multipleSelectionData.value = [];
            onClearFiles();
            if (fileupload.value) {
                fileupload.value.clear();
            }

            newMail.value.to = '';
            newMail.value.cc = '';
            newMail.value.subject = '';
            newMail.value.body = '';
            submitted.value = false;
        }

        const sendMailMicrosoft = async () => {
            HelperLoading.showLoading();

            const accessToken = await getAccessToken();
            if (accessToken) {
                const url = `https://graph.microsoft.com/v1.0/me/sendMail`;
                const objIdValue = props.objectId ?? getPropertyNumberValue(EmailTypeConst.OBJECTID);
                const idObjectValue = props.idObject ?? getPropertyNumberValue(EmailTypeConst.IDOBJECT);

                // Estructura del mensaje
                const mailData = {
                    message: {
                        subject: newMail.value.subject,
                        body: {
                            contentType: "HTML", // Puede ser "Text" o "HTML"
                            content: newMail.value.body
                        },
                        toRecipients: newMail.value.to.split(';').map((email: string) => ({
                            emailAddress: { address: email }
                        })),
                        ccRecipients: newMail.value.cc.trim() !== '' ? newMail.value.cc.split(';').map((email: string) => ({
                            emailAddress: { address: email }
                        })) : [],
                        /*bccRecipients: mailObject.bccRecipients?.map((email: string) => ({
                            emailAddress: { address: email }
                        })) || [],*/
                        attachments: uploadFiles.value?.map((attachment: any) => ({
                            '@odata.type': '#microsoft.graph.fileAttachment',
                            name: attachment.name,
                            contentBytes: attachment.contentBytes,
                        })) || [],
                    } as any,
                    saveToSentItems: true // Si quieres guardar el correo en "Elementos enviados"
                } as any;

                const extendedProperties = [] as any;
                if (objIdValue) {
                    extendedProperties.push({
                        id: "String {11223344-5566-7788-99AA-BBCCDDEEFF00} Name objectId", 
                        value: objIdValue.toString(),
                    });
                }

                if (idObjectValue) {
                    extendedProperties.push({
                        id: "String {AABBCCDD-EEFF-0011-2233-445566778899} Name idObject", 
                        value: idObjectValue.toString(),
                    });
                }

                // Solo asigna si hay propiedades extendidas
                if (extendedProperties.length > 0) {
                    mailData.singleValueExtendedProperties = extendedProperties;
                }

                try {
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(mailData)
                    });

                    HelperLoading.hideLoading();

                    if (!response.ok) {
                        //throw new Error(`Error al enviar correo: ${response.statusText}`);
                        MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al enviar el correo.');
                        return false;    
                    }

                    MessageService.showMessage(MessageType.Correcto, '', 'Correo enviado correctamente.');
                    navigate({ routerLink: 'sent' });
                    return true;

                } catch (error) {
                    HelperLoading.hideLoading();
                    MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al enviar el correo.')     
                    return false;
                }

            } else {
                HelperLoading.hideLoading();
                console.error("No se pudo obtener el token de acceso.");
                return false;
            }
        };

        const sendMailBuilder = async () => {
            //isLoading.value = true;
            //HelperLoading.showLoading();

            if (multipleSelectionData.value.length > 0) {
                const otherDocs = multipleSelectionData.value.map(x => ({
                    documentId: x.id,
                    sourceDocument: getPropertyValue(EmailTypeConst.DOCSOURCE),
                    fileName: x.fileName,
                    size: 0,
                    mimeType: '',
                    content: ''
                }) as uploadFile)
                uploadFiles.value = uploadFiles.value.concat(otherDocs)
            }
            HelperLoading.showLoading();
            store
                .dispatch(Actions.SENDMAIL, [store.getters.getCurrentUser.id, newMail.value.to, newMail.value.cc, newMail.value.subject, newMail.value.body, uploadFiles.value, props.container])
                .then((response) => {
                    if (response) {
                        clearData();
                        setPropertyValueByName(EmailTypeConst.DATAVALUE, response.toString());
                        MessageService.showMessage(MessageType.Correcto, '', 'Correo enviado correctamente.');
                        processEventComponent(EmailTypeConst.CMD_MAIL_SENDED);
                    }                      
                })
                .finally(() => {
                    //isLoading.value = false;
                    HelperLoading.hideLoading();

                }).catch(e => {
                    MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al enviar el correo.')                        
                    throw new Error(e);
                });
        };

        const sendMail = async () => {
            if (isValidData()) {
                const accessToken = await getAccessTokenIfExist();
                if (accessToken) {
                    await sendMailMicrosoft();
                }
                else {
                    await sendMailBuilder();
                }
            }
        };

        const createDraft = async (): Promise<string | undefined> => {
            /*if ( //NO SE MANTIENE LA CONDICIÓN PARA CREAR BORRADORES PARA ENVIAR CON OFFICE365 con cabeceras con un borrador previo sin nada
                newMail.value.subject.trim().length > 0 || 
                newMail.value.body.trim().length > 0 || 
                newMail.value.to.trim().length > 0 || 
                newMail.value.cc.trim().length > 0
            ) {*/
                try {
                    const accessToken = await getAccessToken();
                    if (accessToken) {
                        const objIdValue = getPropertyNumberValue(EmailTypeConst.OBJECTID);
                        const idObjectValue = getPropertyNumberValue(EmailTypeConst.IDOBJECT);

                        const draftBody = {
                            subject: newMail.value.subject || "Sin asunto",
                            body: {
                                contentType: "HTML", 
                                content: newMail.value.body || ""
                            },
                            toRecipients: newMail.value.to != '' ? newMail.value.to.split(';').map(email => email.trim()).map(recipient => ({
                                emailAddress: { address: recipient }
                            })) : [],
                            ccRecipients: newMail.value.cc != '' ? newMail.value.cc.split(';').map(email => email.trim()).map(cc => ({
                                emailAddress: { address: cc }
                            })) : [],
                        } as any;

                        const extendedProperties = [] as any;
                        if (objIdValue) {
                            extendedProperties.push({
                                id: "String {11223344-5566-7788-99AA-BBCCDDEEFF00} Name objectId", 
                                value: objIdValue.toString(),
                            });
                        }

                        if (idObjectValue) {
                            extendedProperties.push({
                                id: "String {AABBCCDD-EEFF-0011-2233-445566778899} Name idObject", 
                                value: idObjectValue.toString(),
                            });
                        }

                        // Solo asigna si hay propiedades extendidas
                        if (extendedProperties.length > 0) {
                            draftBody.singleValueExtendedProperties = extendedProperties;
                        }

                        const response = await fetch('https://graph.microsoft.com/v1.0/me/messages', {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(draftBody)
                        });

                        if (response.ok) {
                            const draft = await response.json();
                            emit('pageChange', { page: EmailTypeConst.TOP, skip: 0 }, true);
                            emit('update-email-id', draft.id);
                            MessageService.showToast(MessageType.Correcto, '', 'Borrador creado correctamente.');
                            return draft.id;
                        } else {
                            const error = await response.json();
                            MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al crear el borrador.');
                            return undefined;
                        }
                    } else {
                        console.error("No se pudo obtener el token de acceso.");
                        return undefined;
                    }
                } catch (error) {
                    MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al crear el borrador.');
                    return undefined;
                }
            /*} else {
                MessageService.showMessage(MessageType.Error, 'Error de validación', 'Todos los campos están vacíos, no se creó el borrador.');
                return undefined;
            }*/
        };

        const onSelectedFiles = (event: any) => {

            //uploadFiles.value = event.files;


            event.files.forEach(file => {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    // Acceder al contenido del archivo
                    const fileContent = e.target.result.split(',')[1];
                    uploadFiles.value.push({
                        documentId: null,
                        sourceDocument: null,
                        fileName: file.name,
                        size: file.size,
                        mimeType: file.type,
                        content: fileContent
                    });
                };

                // Leer el archivo como texto
                reader.readAsDataURL(file);
            });
        };

        const onRemoveFiles = (event: FileUploadRemoveEvent) => {
            uploadFiles.value = uploadFiles.value.filter(x => x.fileName !== event.file.name && x.size !== event.file.size && x.mimeType !== event.file.type);
        }

        const onClearFiles = () => {
            uploadFiles.value = [];
        }

        onMounted(async () => {
            await initializeMsalInstance();

            const account = msalInstance.getAllAccounts()[0];
            if (account) {
                hasMicrosoftSession.value = true;
            }

            if (hasLayoutTo.value) {
                watch(
                    getProperty(EmailTypeConst.TO) as any, (oldvalue, newvalue) => {
                        newMail.value.to = HelperUtils.addCharEndCad(newMail.value.to,';')
                        if (oldvalue?.value){
                            newMail.value.to += oldvalue?.value.join(';') +';'
                        }
                        
                        //opViewContacts.value = false;
                    },
                    {
                        flush: 'post',
                        //immediate: true, 
                        deep: false
                    }
                )

                watch(
                    getProperty(EmailTypeConst.CC) as any, (oldvalue, newvalue) => {                        
                        newMail.value.cc = HelperUtils.addCharEndCad(newMail.value.cc,';');
                        if (oldvalue?.value){
                            newMail.value.cc +=  oldvalue?.value.join(';') + ';'  
                        }
                        
                        //opViewContacts.value = false;
                    },
                    {
                        flush: 'post',
                        //immediate: true, 
                        deep: false
                    }
                )
                watch(
                    getProperty(EmailTypeConst.MSG) as any, (oldvalue, newvalue) => {

                        newMail.value.body = oldvalue?.value
                        //opViewTemplates.value = false;
                    },
                    {
                        flush: 'post',
                        //immediate: true, 
                        deep: false
                    }
                )

                watch(
                    getProperty(EmailTypeConst.SUBJECT) as any, (oldvalue, newvalue) => {

                        newMail.value.subject = oldvalue?.value
                        //opViewTemplates.value = false;
                    },
                    {
                        flush: 'post',
                        //immediate: true, 
                        deep: false
                    }
                )

                watchEffect(() => {
                    if (loaded.value == true && !isLoaded) {
                        isLoaded = true;
                        newMail.value.to = props.paramEmail?.toRecipients?.map(r => r.emailAddress.address).join(';') ?? (getPropertyValue(EmailTypeConst.TO) ?? '');
                        newMail.value.cc = props.paramEmail?.ccRecipients?.map(r => r.emailAddress.address).join(';') ?? (getPropertyValue(EmailTypeConst.CC) ?? '');
                        newMail.value.subject = props.paramEmail?.subject ?? (getPropertyValue(EmailTypeConst.SUBJECT) ?? '');
                        newMail.value.body = props.paramEmail?.body?.content ?? (getPropertyValue(EmailTypeConst.MSG) ?? '');
                    }
                })





            }

        })

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })


        const openCenteredPopup = (url: string, title: string, width: number, height: number) => {
            const left = (window.screen.width / 2) - (width / 2);
            const top = (window.screen.height / 2) - (height / 2);

            // Abre una ventana emergente centrada con un tamaño específico
            return window.open(
                url, 
                title, 
                `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=${width}, height=${height}, top=${top}, left=${left}`
            );
        };

        const openOutlookCompose = async (draftId: string) => {
            var draftUrl = '';
            if (draftId) {
                draftUrl = `https://outlook.office365.com/owa/?ItemID=${draftId}&exvsurl=1&viewmodel=ReadMessageItem`;
            }
            else {
                //draftUrl = `https://outlook.office365.com/mail/deeplink/compose`;
                const draft_id = await createDraft();
                draftUrl = `https://outlook.office365.com/owa/?ItemID=${draft_id}&exvsurl=1&viewmodel=ReadMessageItem`;
            }

            // Abrir en una ventana centrada con dimensiones específicas
            openCenteredPopup(draftUrl, 'Redactar Borrador', 800, 600);
        };

        const openMailTo = (to: string, cc: string, subject: string, body: string) => {

            const composeUrl = `https://outlook.office.com/mail/deeplink/compose?to=${encodeURIComponent(to)}&cc=${encodeURIComponent(cc)}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

            // Abre la URL en una nueva ventana o pestaña
            window.open(composeUrl, '_blank');
        };

        return {            
            newMail,
            sendMail,
            createDraft,
            v$,
            submitted,
            onSelectedFiles,
            BaseControlTypeConst,
            getPropertyBooleanValue,
            vmodel,
            EmailTypeConst,
            getPropertyValue,
            getPropertyNumberValue,
            hasLayoutTo,
            opViewContacts,
            multipleSelectionData,
            onRemoveFiles,
            onClearFiles,
            fileupload,
            loaded,
            canDoOperation,
            ObjectGroupConst,
            hasLayoutTemplateBody,
            opViewTemplates,
            checked,
            componentKey,
            hasMicrosoftSession,
            openOutlookCompose,
            openMailTo,
        };
    },
});
</script>
<style scoped></style>
