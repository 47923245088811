import gql from 'graphql-tag';

export const GET_ALL_DATASOURCE_SERVICE_CONFIGURATION = gql(`
query($applicationId: Int!,$applicationVersion: Int!) {
  dataSourceServiceConfigurationQuery {
      getAll(applicationId:$applicationId,applicationVersion:$applicationVersion) {
        id,
        dataSourceId,
        idServiceConfigurationType,
        key,            
        value,
        IdEnvironmentType,
        description,
        fcr,
        ucr
        
      }
    }
}
`);

export const GET_DATASOURCE_SERVICE_CONFIGURATION_BY_ID = gql(`
query($applicationId: Int!,$applicationVersion: Int!,$datasourceId:Int!) {
    dataSourceServiceConfigurationQuery {
      getById(applicationId:$applicationId,applicationVersion:$applicationVersion,datasourceId:$datasourceId) {
        id,
        dataSourceId,
        idServiceConfigurationType,
        key,            
        value,
        idEnvironmentType,
        description,
        fcr,
        ucr
      }
    }
}
`);

