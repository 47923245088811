/* eslint-disable hexagonal-architecture/enforce */
/* eslint-disable hexagonal-architecture/enforce */

import { createApp, h } from 'vue'
import MainWrapper from '@ilinium/shared/src/common/infrastructure/funciones/HelperMainWrapper'
import AppWrapper from './AppWrapper.vue'
import router from './router'
import { container } from './infrastructure/container'
import routes from './router'
import store from '@ilinium/shared/src/common/infrastructure/almacen'
import HelperLogin from '@ilinium/shared/src/entidades/builderMaster/auth/infrastructure/component/HelperLogin'
import Environment from '@ilinium/shared/src/common/infrastructure/funciones/environment'


export const app = createApp({
  render () {
    return h(AppWrapper)
  }
})


// esto se usa para cuando se pulsa F5
if (store.getters.getRoutes) {
  HelperLogin.rebuildRoutes(false, routes, Environment.APPID, Environment.APPVERSION, container, window.location.pathname.substring(1), true);
}



app.use(router)
MainWrapper.init(app,container, [])



