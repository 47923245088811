export default class ControlTypeConst {


    
    static CTA = 'tipocontrol-a'
    static CTBUTTON = 'tipocontrol-btn'
    static CTTGLBUTTON = 'tipocontrol-tglbtn'
    static CTSPLITBUTTON = 'tipocontrol-splitbtn'
    static CTCALENDAR = 'tipocontrol-cld'
    static CTCRUD = 'tipocontrol-crudtbl'
    static CTEMAIL = 'tipocontrol-email'
    static CTDASHBOARDEMAIL = 'tipocontrol-dynamicemaildashboard'
    static CTBROWSING = 'tipocontrol-browsing'
    static CTLOGOUT = 'tipocontrol-logout'
    static CTPROFILES = 'tipocontrol-profiles'
    static CTSETUP = 'tipocontrol-setup'
    static CTLAYOUT = 'tipocontrol-layout'
    static CTLAYOUTTOPBAR = 'layouttemp-layout-topbar'
    static CTLAYOUTFOOTER = 'layouttemp-layout-footer'
    static CTLANGUAGES = 'tipocontrol-languages'
    static CTSETPWD = 'tipocontrol-setpwd'
    static CTBASE7 = 'tipocontrol-base7'
    static CTCHECKBOX = 'tipocontrol-checkb'
    static CTTRISTATECHECKBOX = 'tipocontrol-tristatecheckbox'
    static CTTREE = 'tipocontrol-tree'
    static CTTREESELECT = 'tipocontrol-treeselect'   
    static CTTREETABLE = 'tipocontrol-treetable'   
    static CTSELECTBUTTON = 'tipocontrol-selectbutton'
    static CTCHIP = 'tipocontrol-chip'
    static CTDIVIDER = 'tipocontrol-divid'
    static CTDROPDOWN = 'tipocontrol-dropwn'
    static CTDROPDOWNCASCADE = 'tipocontrol-dropwncascade'
    static CTIMAGE = 'tipocontrol-img'
    static CTINPUTSWITCH = 'tipocontrol-inpsw'
    static CTINPUTTEXT = 'tipocontrol-inptx'
    static CTINPUTNUMBER = 'tipocontrol-inumb'
    static CTLABEL = 'tipocontrol-lbl'
    static CTMULTISELECT = 'tipocontrol-mltsel'
    static CTPANEL = 'tipocontrol-pnl'
    static CTRADIOBUTTON = 'tipocontrol-radbut'
    static CTSPAN = 'tipocontrol-span'
    static CTTABPANEL = 'tipocontrol-tabpnl'
    static CTTABVIEW = 'tipocontrol-tabv'
    static CTTAG = 'tipocontrol-tag'
    static CTSCROLLPANEL = 'tipocontrol-scrollp'
    static CTTOOLBAR = 'tipocontrol-toolb'
    static CTTEXAREA = 'tipocontrol-txta'
    static CTRATING='tipocontrol-rat'
    static CTTIMELINE='tipocontrol-tlin'
    static CTAVATAR='tipocontrol-avt'
    static CTEM = 'tipocontrol-em'
    static CTH1 = 'tipocontrol-h1'
    static CTH2 = 'tipocontrol-h2'
    static CTH3 = 'tipocontrol-h3'
    static CTH4 = 'tipocontrol-h4'
    static CTH5 = 'tipocontrol-h5'
    static CTH6 = 'tipocontrol-h6'
    static CTHR = 'tipocontrol-hr'
    static CTI = 'tipocontrol-i'
    static CTB = 'tipocontrol-b'
    static CTBR = 'tipocontrol-br'
    static CTSMALL = 'tipocontrol-small'
    static CTP= 'tipocontrol-p'
    static CTCARD = 'tipocontrol-card'
    static CTSTRONG = 'tipocontrol-strong'
    static CTDIV = 'tipocontrol-div'
    static CTUL = 'tipocontrol-ul'
    static CTLI = 'tipocontrol-li'
    static CTMENU = 'tipocontrol-menu'
    static CTMENUITEM= 'tipocontrol-menui'
    static CTFIELDSET= 'tipocontrol-fieldset'
    static CTLOOKUPEDITOR='tipocontrol-lookupedit'
    static CTSEARCH='tipocontrol-search'
    static CTLAYOUT_PNL_FOOTER='layouttemp-pnl-footer'
    static CTLAYOUT_PNL_HEADER='layouttemp-pnl-header'
    static CTLAYOUT_PNL_ICON='layouttemp-pnl-icon'
    static CTDATATABLE='tipocontrol-dtable'
    static CTCOLUMN='tipocontrol-col'
    static CTROW='tipocontrol-row'
    static CTINPUT='tipocontrol-input'
    static CTIFRAME='tipocontrol-ifr'
    static CTDATAVIEW='tipocontrol-dview'
    static CTPASSWORD = 'tipocontrol-pwd'
    static CTBADGE = 'tipocontrol-badge'
    static CTKNOB = 'tipocontrol-knob'
    static CTEDITOR = 'tipocontrol-editor'
    static CTCOLORPICKER = 'tipocontrol-colorpicker'
    static CTDIALOG = 'tipocontrol-dialog'
    static CTAUTOCOMPLETE = 'tipocontrol-autocomp'
    static CTCHART = 'tipocontrol-chart'
    static CTCHARTCIRC = 'tipocontrol-chartcirc'

    static CTFULLCALENDAR='tipocontrol-fullcal'
    static CTINPUTMASK = 'tipocontrol-inputmask'

    static CTLAYOUT_SPLITBTN_MENU= 'layouttemp-splitbtn-menu'
    static CTLAYOUT_DVIEW_FOOTER='layouttemp-dview-footer'
    static CTLAYOUT_DVIEW_HEADER='layouttemp-dview-header'
    static CTLAYOUT_DVIEW_PAGINATORSTART='layouttemp-dview-paginatorstart'
    static CTLAYOUT_DVIEW_PAGINATOREND='layouttemp-dview-paginatorend'
    static CTLAYOUT_DVIEW_EMPTY='layouttemp-dview-empty'
    static CTLAYOUT_DVIEW_LIST='layouttemp-dview-list'
    static CTLAYOUT_DVIEW_GRID='layouttemp-dview-grid'

    static CTLAYOUT_TLIN_CONNECTOR ='layouttemp-tlin-connector'
    static CTLAYOUT_TLIN_CONTENT ='layouttemp-tlin-content'
    static CTLAYOUT_TLIN_MARKER ='layouttemp-tlin-marker'
    static CTLAYOUT_TLIN_OPPOSITE='layouttemp-tlin-opposite'

    static CTLAYOUT_CARD_CONTENT ='layouttemp-card-content'
    static CTLAYOUT_CARD_FOOTER ='layouttemp-card-footer'
    static CTLAYOUT_CARD_HEADER ='layouttemp-card-header'
    static CTLAYOUT_CARD_SUBTITLE ='layouttemp-card-subtitle'
    static CTLAYOUT_CARD_TITLE ='layouttemp-card-title'

    static CTLAYOUT_CRUDTABLE_DETAIL ='layouttemp-crudtbl-detail'
    static CTLAYOUT_CRUDTABLE_HEADER ='layouttemp-crudtbl-header'
    static CTLAYOUT_CRUDTABLE_HEADER_BUTTONS ='layouttemp-crudtbl-headerbtns'
    
    static CTLAYOUT_CRUDTABLE_MENU= 'layouttemp-crudtbl-menu'
    static CTLAYOUT_CRUDTABLE_CUSTOMNEW ='layouttemp-crudtbl-new'    
    
    static CTLAYOUT_CRUDTABLE_SEARCH_CONTROLS ='layouttemp-crudtbl-controlssearch'    
    static CTLAYOUT_CRUDTABLE_BUTTONS_RESULT ='layouttemp-crudtbl-buttonsresult'   
    static CTLAYOUT_CRUDTABLE_BUTTONS_SEARCH ='layouttemp-crudtbl-buttonssearch'   

    static CTLAYOUT_CRUDTABLE_COL_GROUPS ='layouttemp-crudtbl-colgroups'   




    static CTLAYOUT_MENU_END= 'layouttemp-menu-end'
    static CTLAYOUT_MENU_ITEM= 'layouttemp-menu-item'
    static CTLAYOUT_MENU_ITEMICON= 'layouttemp-menu-itemicon'
    static CTLAYOUT_MENU_START= 'layouttemp-menu-start'
    static CTLAYOUT_MENU_SUBHEADER= 'layouttemp-menu-submheader'
    static CTLAYOUT_TABPANEL_DEFAULT= 'layouttemp-tabpnl-default'
    static CTLAYOUT_TABPANEL_HEADER= 'layouttemp-tabpnl-header'
    static CTLAYOUT_FIELDSET_LEGEND= 'layouttemp-fieldset-legend'
    static CTLAYOUT_DIALOG_HEADER= 'layouttemp-dialog-header'
    static CTLAYOUT_DIALOG_FOOTER= 'layouttemp-dialog-footer'
    static CTLAYOUT_DIALOG_CLOSEICON= 'layouttemp-dialog-closeicon'
    static CTLAYOUT_DIALOG_MAXIMIZEICON= 'layouttemp-dialog-maximizeicon'

    static CTLAYOUT_AUTOCOMPLETE_CONTENT='layouttemp-autocomp-content'
    static CTLAYOUT_AUTOCOMPLETE_EMPTY='layouttemp-autocomp-empty'
    static CTLAYOUT_AUTOCOMPLETE_FOOTER='layouttemp-autocomp-footer'
    static CTLAYOUT_AUTOCOMPLETE_HEADER='layouttemp-autocomp-header'
    static CTLAYOUT_AUTOCOMPLETE_OPTION='layouttemp-autocomp-option'
    static CTLAYOUT_AUTOCOMPLETE_OPTIONGROUP='layouttemp-autocomp-optiongroup'


    static CTLAYOUT_COLUMN_BODY='layouttemp-col-body'
    static CTLAYOUT_COLUMN_EDITOR='layouttemp-col-editor'
    static CTLAYOUT_COLUMN_FILTER='layouttemp-col-filter'
    static CTLAYOUT_COLUMN_HEADER='layouttemp-col-header'
    

    static CTLAYOUT_LOOKUP_DETAIL='layouttemp-lookupeditor-detail'
    static CTLAYOUT_LOOKUP_DIALOGHEAD='layouttemp-lookupeditor-dialoghead'
    static CTLAYOUT_LOOKUP_ADDONTHEFLY='layouttemp-lookupeditor-addonthefly'

    static CTDOCUMENT = 'tipocontrol-document'


    static CTEXPRESSION = 'tipocontrol-expression'
    static CTSTRUCTURED_FIELD = 'tipocontrol-structuredfield'

    static CTLAYOUT_CTSTRUCTURED_FIELD_FORM ='layouttemp-struct-new'  

    //structureField 
    static CTLAYOUT_STRUCTFIELD_ADDFORM ='layouttemp-struct-new'


    // email
    static CTLAYOUT_MAIL_TO= 'layouttemp-email-to'
    static CTLAYOUT_MAIL_BODY= 'layouttemp-email-body'

    static CTLAYOUT_DASHBOARDMAIL_TO= 'layouttemp-dashboardemail-to'
    static CTLAYOUT_DASHBOARDMAIL_BODY= 'layouttemp-dashboardemail-body'
    static CTLAYOUT_DASHBOARDMAIL_BUTTONS= 'layouttemp-dashboardemail-buttons'

    // tree
    static CTLAYOUT_TREE_MENU = 'layouttemp-tree-menu'

    // code mirror
    static CT_CODEMIRROR = 'tipocontrol-codemirror'

    // organization chart
    static CT_ORG_CHART = 'tipocontrol-orgchart'

    // svg
    static HTML_SVG = 'tipocontrol-svg'

    // picklist
    static CTPICKLIST = 'tipocontrol-pl'

    static CTSEARCHCONTROL = 'tipocontrol-searchctrl'


    // controles de tipo input
    static VALIDABLE_CONTROLS: string[] = [ControlTypeConst.CTDROPDOWN,ControlTypeConst.CT_CODEMIRROR,
        ControlTypeConst.CTINPUTTEXT,ControlTypeConst.CTAUTOCOMPLETE,ControlTypeConst.CTCALENDAR,ControlTypeConst.CTCHECKBOX,ControlTypeConst.CTCOLORPICKER,
        ControlTypeConst.CTDROPDOWNCASCADE,ControlTypeConst.CTKNOB,ControlTypeConst.CTLOOKUPEDITOR,
        ControlTypeConst.CTMULTISELECT,ControlTypeConst.CTPASSWORD,ControlTypeConst.CTRADIOBUTTON,ControlTypeConst.CTRATING,ControlTypeConst.CTTEXAREA,
        ControlTypeConst.CTINPUTMASK,ControlTypeConst.CTINPUTNUMBER,ControlTypeConst.CTINPUTSWITCH,ControlTypeConst.CTINPUT,ControlTypeConst.CTEDITOR,ControlTypeConst.CTTRISTATECHECKBOX,        
        ControlTypeConst.CTINPUTMASK, ControlTypeConst.CTSELECTBUTTON
    ];
    
    //


    

}