<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <InputMask v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(InputMaskTypeConst.ID)" v-tooltip="getPropertyValue(InputMaskTypeConst.TOOLTIP)"
        :style="getPropertyValue(InputMaskTypeConst.STYLE)"
        :class="{ [getPropertyValue(InputMaskTypeConst.CLASS) ?? '']: true, 'customrequired': getPropertyBooleanValue(InputMaskTypeConst.REQUIRED) }"
        :name="getPropertyValue(InputMaskTypeConst.NAME)" :disabled="getPropertyBooleanValue(InputMaskTypeConst.DISABLED)"
        :visible="getPropertyBooleanValue(InputMaskTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(InputMaskTypeConst.PLACEHOLDER)" 
        :slotChar="getPropertyValue(InputMaskTypeConst.SLOTCHAR)"
        :mask="getPropertyValue(InputMaskTypeConst.MASK)"
        :autoClear="getPropertyBooleanValue(InputMaskTypeConst.AUTOCLEAR)"
        :unmask="getPropertyBooleanValue(InputMaskTypeConst.UNMASK)"
        :readonly="getPropertyBooleanValue(InputMaskTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
        @blur="processEventComponent(CatalogEventConst.BLUR,Component)"
        @focus="processEventComponent(CatalogEventConst.FOCUS,Component)"

        >
        
    </InputMask>
</template>
<script lang="ts">


import { defineComponent, onMounted, onBeforeUnmount, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import InputMaskTypeConst from '../../../../domain/Constants/InputMaskTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';

export default defineComponent({
    name: 'dynamic_inputmask',
    components: {
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },
     
    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel, canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded,  baseOnMounted, baseOnBeforeUnmount, v$, submitted, canEdit, isValidData
            , fieldKey, fieldKeyComponentDataSourceId,componentKey,processEventComponent
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);



        onMounted(() => {
            baseOnMounted();
        })

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
        })


        return {

            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            InputMaskTypeConst,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst,
            canDoOperation
            ,componentKey
            ,processEventComponent
            ,CatalogEventConst
        };
    },

})




</script>
<style scoped></style>
