import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Row = _resolveComponent("Row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_Row, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.RowTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.RowTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.RowTypeConst.CLASS) ?? '']: true, 'customrequired': _ctx.getPropertyBooleanValue(_ctx.RowTypeConst.REQUIRED) }),
          name: _ctx.getPropertyValue(_ctx.RowTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.RowTypeConst.DISABLED),
          visible: _ctx.getPropertyBooleanValue(_ctx.RowTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
          pt: _ctx.getPropertyValue(_ctx.RowTypeConst.PT)
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "content")
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "pt"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.RowTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}