<template>
       <SummaryDebug v-if="getPropertyBooleanValue(CalendarTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    <Calendar ref="calendarComponentRef" v-if="loaded && getPropertyBooleanValue(CalendarTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :key="componentKey" :id="getPropertyValue(CalendarTypeConst.ID)"
        v-tooltip="getPropertyValue(CalendarTypeConst.TOOLTIP)" :style="getPropertyValue(CalendarTypeConst.STYLE)"
        :class="{ [getPropertyValue(CalendarTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted, 'p-readonly': getPropertyBooleanValue(CalendarTypeConst.READONLY) }"
        :name="getPropertyValue(CalendarTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(CalendarTypeConst.DISABLED) || (!canEdit)"
        :visible="getPropertyBooleanValue(CalendarTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(CalendarTypeConst.PLACEHOLDER)"
        :showOtherMonths="getPropertyBooleanValue(CalendarTypeConst.SHOWOTHERMONTHS)"
        :selectOtherMonths="getPropertyBooleanValue(CalendarTypeConst.SELECTOTHERMONTHS)"
        :showButtonBar="getPropertyBooleanValue(CalendarTypeConst.SHOWBUTTONBAR)"
        :showTime="getPropertyBooleanValue(CalendarTypeConst.SHOWTIME)"
        :showSeconds="getPropertyBooleanValue(CalendarTypeConst.SHOWSECONDS)"
        :manualInput="getPropertyBooleanValue(CalendarTypeConst.MANUALINPUT)"
        :inline="getPropertyBooleanValue(CalendarTypeConst.INLINE)"
        :showIcon="getPropertyBooleanValue(CalendarTypeConst.SHOWICON)"
        :touchUI="getPropertyBooleanValue(CalendarTypeConst.TOUCHUI)" :minDate="getPropertyDateValue(CalendarTypeConst.MINDATE)"
        :maxDate="getPropertyDateValue(CalendarTypeConst.MAXDATE)"
        :readonly="getPropertyBooleanValue(CalendarTypeConst.READONLY) || !canDoOperation(ObjectGroupConst.EDIT)"
        :numberOfMonths="getPropertyNumberValue(CalendarTypeConst.NUMBEROFMONTHS)"
        :view="getCatalogValue(CalendarTypeConst.VIEW)" :selectionMode="getCatalogValue(CalendarTypeConst.SELECTIONMODE)"
        :dateFormat="getPropertyValue(CalendarTypeConst.DATEFORMAT)" v-model="vmodel"
        @blur="processEventComponent(CatalogEventConst.BLUR,Component)"
        @focus="processEventComponent(CatalogEventConst.FOCUS,Component)"

        >
    </Calendar>
    <CustomValidate v-if="loaded && getPropertyBooleanValue(CalendarTypeConst.VISIBLE)" v-model:submitted="submitted"
        v-model:vObject="v$" />
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount,  watch } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import CalendarTypeConst from '../../../../domain/Constants/CalendarTypeConst';
import { Container } from 'inversify';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import CatalogEventConst from '../../../../../catalog/domain/const/CatalogEventConst';

export default defineComponent({
    name: 'dynamic_calendar',

    components: {
        CustomValidate,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
       
        slotProps: {
            type: Object,
            default: () => ({})
        },

    },
    setup(props, context) {
        //
        
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyNumberValue, getPropertyBooleanValue, 
            getPropertyDateValue,loaded,  resolveExpressions, getCatalogValue, baseOnBeforeUnmount, v$, submitted, canEdit,
             baseOnMounted,  isValidData, fieldKey, fieldKeyComponentDataSourceId
             ,componentKey, processEventComponent
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);

        const calendarComponentRef = ref();



        /*
                    INICIO Parche para el calendario que nu¡o rula bien al insertar manual las fechas
                    Hilo del caso en: https://github.com/primefaces/primevue/issues/3624
        */

        /**
         * Prevent PrimeVue's Calendar component from stealing focus from its own
         * input, preventing users from manually entering dates
         *
         * see https://github.com/primefaces/primevue/issues/3624
         */
        
        const fixCalendarFocusTrap = () => {
            const componentExists = !!calendarComponentRef.value;
            if (!componentExists) return;

            const alreadyMonkeyPatched =
                !!calendarComponentRef.value.originalUpdateFocusFunc;
            if (alreadyMonkeyPatched) return;

            calendarComponentRef.value.originalUpdateFocusFunc =
                calendarComponentRef.value.updateFocus;

            calendarComponentRef.value.updateFocus = () => {
                const inputEl = calendarComponentRef.value.input;
                const inputElHasFocus = inputEl && inputEl == document.activeElement;
                if (!inputElHasFocus) {
                    calendarComponentRef.value.originalUpdateFocusFunc();
                }
            };
        };

        watch(calendarComponentRef, () => fixCalendarFocusTrap(), {
            immediate: true,
        });

            /* 
              FIN  Parche para el calendario que nu¡o rula bien al insertar manual las fechas
            
            */

        onMounted(() => {

            baseOnMounted();

        })

        onBeforeUnmount(() => {
            calendarComponentRef.value = null;
            baseOnBeforeUnmount();
        })


      

        return {          
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            CalendarTypeConst,
            getPropertyNumberValue,
            getCatalogValue,
            loaded,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            calendarComponentRef,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
            ,processEventComponent
            ,CatalogEventConst
            ,getPropertyDateValue
        };
    },
});
</script>
<style scoped></style>
