<template  src="./DynamicPanel.html">
</template>
<script lang="ts">


import { defineComponent, onBeforeUnmount, onMounted } from 'vue';
import ComponentCommonRender from '../../../../../domain/Functions/ComponentCommonRender';
import PanelTypeConst from '../../../../../domain/Constants/PanelTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../../shared/SummaryDebug.vue';
import DynamicPanelFunc from '../../../Functions/DynamicPanelFunc';


export default defineComponent({
    name: 'dynamic_panel',
    components: {
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },     
        slotProps: {
            type: Object,
            default: () => ({})
        },
    },
    setup(props) {
             
        const store = useStore();
        const { vmodel,canDoOperation, loaded, getPropertyValue, getPropertyBooleanValue, baseOnBeforeUnmount
            ,fieldKey,fieldKeyComponentDataSourceId ,componentKey, getPropertyId,processEvent,setPropertyValueByName,getCompClass
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);


        
        const { attrsInput,formBuildComp,basePanelOnMounted,basePanelOnBeforeUnmount, collapsed, toggle,pt } = DynamicPanelFunc( props.Component,loaded,getPropertyId,processEvent,getPropertyBooleanValue,setPropertyValueByName,getPropertyValue)
        
        
        onMounted(()=>{
            basePanelOnMounted();
        })


        onBeforeUnmount(() => {            
            baseOnBeforeUnmount();
            basePanelOnBeforeUnmount();
        })



        return {
          
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            PanelTypeConst,
            loaded,
            BaseControlTypeConst,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey
            ,formBuildComp
            ,attrsInput
            ,toggle
            ,collapsed
            ,pt
            ,getCompClass
        };
    },
});
</script>
<style scoped></style>
