import { ClassicPreset as Classic, ClassicPreset } from 'rete';
import {  DataflowEngine } from 'rete-engine';
import { AreaExtra, Schemes } from '../rete/helperRete';
export class EndNode extends Classic.Node<{ ejecutar: Classic.Socket,objectOutput: Classic.Socket },{}, {}> {
    width = 180;
    height = 150;
    
    private callBackEnd:any;
    private dataflow: DataflowEngine<Schemes>;
  
    constructor(socket:Classic.Socket, callBackEndFunction:any=undefined, dataflow: DataflowEngine<Schemes>) {
      super("Fin");
      this.callBackEnd = callBackEndFunction;
      this.dataflow = dataflow;
      this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));
      this.addInput("objectOutput", new ClassicPreset.Input(socket, "ObjectOutput", true));
    }
  
    async execute(_: never, forward: (output: "ejecutar") => void) {

      //forward("ejecutar");
      let response :any = true;
      
      const inputs = (await this.dataflow.fetchInputs(this.id)) as {
        objectOutput: any;
    };
      
      if (inputs?.objectOutput){
        if (Array.isArray(inputs.objectOutput) && inputs.objectOutput.length > 0){
          response = inputs.objectOutput[0]
        }
        else{
          response = inputs.objectOutput;
        }
      }

      if (this.callBackEnd){
        this.callBackEnd(response);
      }
      forward('ejecutar')
    }
    data() {
      return {};
    }
      
  }
