import { shallowRef,watchEffect,computed } from "vue";
import EventConst from "@ilinium/shared/src/common/domain/constantes/EventConst";
import ComponentDataForm from "../../../../designer/domain/ComponentDataForm";
import { IapComponentAttribute } from "../../../../component/domain/iapComponentAttribute";
import CatalogObjectTypeConst from "../../../../catalog/domain/const/CatalogObjectTypeConst";
import EventBusCustom from '@ilinium/shared/src/common/infrastructure/event-bus-custom';
import PanelTypeConst from "../../../domain/Constants/PanelTypeConst";
import { PanelToggleEvent } from "primevue/panel";
import HelperCommon from "@ilinium/shared/src/common/infrastructure/funciones/HelperCommon";


export default function DynamicPanelFunc(component:ComponentDataForm,loaded:any,getPropertyId:any,processEvent:any,getPropertyBooleanValue:any,setPropertyValueByName:any,getPropertyValue:any) {


    const attrsInput = shallowRef<IapComponentAttribute[]>([]);
    const formBuildComp = shallowRef(-1);
    const keyEventComponent = 'currentpnl_' + component.formKey + (component.rootParentId ?? -1).toString() + '_' + component.id.toString() + '_' + EventConst.SHOWCOMPONENT;
    const collapsed = shallowRef(getPropertyBooleanValue(PanelTypeConst.COLLAPSED,component) ?? false);
    let ptValue=getPropertyValue(PanelTypeConst.PT,component)
    let pt=undefined;

    if(ptValue && !HelperCommon.isNullOrWhitespace(ptValue)){
        try {
            ptValue=ptValue.replace(/([a-zA-Z0-9_]+)(?=\s*:)/g, '"$1"').replace(/'([^']+)'/g, '"$1"');// coloca comilla en las claves para poder parsearlo.
            pt= JSON.parse(ptValue);
        } catch (error) {
        }
    }

    const doComponentEvent = (evt: { componentId: number, header: string, modal: boolean, attrs: IapComponentAttribute[], callBackResponse: any }) =>{
        
        formBuildComp.value = evt.componentId;
        attrsInput.value  = evt.attrs;

            if (evt.callBackResponse) {
                evt.callBackResponse(true)
            }
    }

    const toggle = (event: PanelToggleEvent) =>{
        setPropertyValueByName(PanelTypeConst.COLLAPSED,event.value);
        collapsed.value = event.value;
    }

    const loadedOk = computed(() => { return  loaded.value });
   
    const basePanelOnMounted = () => {
        
        const propsIdCmd = getPropertyId(PanelTypeConst.CMD_DETAIL,component) ?? '';
        const hasWf = component?.workFlows?.find(x => x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId == propsIdCmd && x.active == true)
        if (propsIdCmd && hasWf) { 
            // esto es para mostrar los detalles
            EventBusCustom.on(keyEventComponent,doComponentEvent);
            
            watchEffect(() => {
                
            if (loadedOk.value == true && (getPropertyBooleanValue(PanelTypeConst.TOGGLEABLE,component) == false || (
                 getPropertyBooleanValue(PanelTypeConst.TOGGLEABLE,component) == true && collapsed.value == false))) {
            
                processEvent(CatalogObjectTypeConst.ATTRCOMP, propsIdCmd, component,keyEventComponent);
            }
            else{
                formBuildComp.value = -1;
            }
        })
           
        }
    }

        const basePanelOnBeforeUnmount = () => {
            EventBusCustom.off(keyEventComponent,doComponentEvent);   
            formBuildComp.value = -1;
            attrsInput.value.length = 0; 
        }
            
   
   
   return {
        attrsInput,
        formBuildComp,
        basePanelOnMounted,
        basePanelOnBeforeUnmount,
        collapsed,
        toggle,
        pt
        
    };  
  }

  
  

