<template>
    <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
         :data="Component"
         :vmodel="vmodel"
         />
  
 <Row v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" :key="componentKey"
     :id="getPropertyValue(RowTypeConst.ID)" v-tooltip="getPropertyValue(RowTypeConst.TOOLTIP)"
     :style="getPropertyValue(RowTypeConst.STYLE)"
     :class="{ [getPropertyValue(RowTypeConst.CLASS) ?? '']: true, 'customrequired': getPropertyBooleanValue(RowTypeConst.REQUIRED) }"
     :name="getPropertyValue(RowTypeConst.NAME)" :disabled="getPropertyBooleanValue(RowTypeConst.DISABLED)"
     :visible="getPropertyBooleanValue(RowTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
     :pt="getPropertyValue(RowTypeConst.PT)"
     >

     <slot name="content"></slot>

 </Row>
      

</template>
<script lang="ts">


import { defineComponent, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import RowTypeConst from '../../../../domain/Constants/RowTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import { useStore } from 'vuex';	
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';
export default defineComponent({
 name: 'dynamic_row',
 components: {
     SummaryDebug,
     //Column,
     //Row
 },
 props:
 {
     container: {
         type: Object as () => Container
     },
     Component: {
         type: Object as () => ComponentDataForm,
         default: () => ({})
     },     
     slotProps: {
         type: Object,
         default: () => ({})
     },

 },
 setup(props, context) {
     //

     const store = useStore();
     const { vmodel,canDoOperation, loaded, getPropertyValue, getPropertyBooleanValue, getPropertyNumberValue,
          resolveExpressions, baseOnBeforeUnmount,fieldKey,fieldKeyComponentDataSourceId
          ,componentKey
     } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);

  


     onBeforeUnmount(() => {
         baseOnBeforeUnmount();
     })

     return {
      
         getPropertyValue,
         getPropertyBooleanValue,
         vmodel,
         RowTypeConst,
         getPropertyNumberValue,
         loaded,
         BaseControlTypeConst,
         fieldKey,
         fieldKeyComponentDataSourceId,
         ObjectGroupConst
         ,canDoOperation
         ,componentKey
     };
 },
});
</script>
<style scoped></style>
