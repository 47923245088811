import { shallowRef,watchEffect,computed } from "vue";
import HtmlTypeConst from "../../../domain/Constants/HtmlTypeConst";
import EventConst from "@ilinium/shared/src/common/domain/constantes/EventConst";
import ComponentDataForm from "../../../../designer/domain/ComponentDataForm";
import { IapComponentAttribute } from "../../../../component/domain/iapComponentAttribute";
import CatalogObjectTypeConst from "../../../../catalog/domain/const/CatalogObjectTypeConst";
import EventBusCustom from '@ilinium/shared/src/common/infrastructure/event-bus-custom';


export default function DynamicHtmlDivFunc(component:ComponentDataForm,loaded:any,getPropertyId:any,processEvent:any) {


    const attrsInput = shallowRef<IapComponentAttribute[]>([]);
    const formBuildComp = shallowRef(-1);
    const keyEventComponent = 'currentdiv_' + component.formKey + (component.rootParentId ?? -1).toString() + '_' + component.id.toString() + '_' + EventConst.SHOWCOMPONENT;


    const doComponentEvent = (evt: { componentId: number, header: string, modal: boolean, attrs: IapComponentAttribute[], callBackResponse: any }) =>{
        
        formBuildComp.value = evt.componentId;
        attrsInput.value  = evt.attrs;

            if (evt.callBackResponse) {
                evt.callBackResponse(true)
            }
    }

    const loadedOk = computed(() => { return  loaded.value });
   
    const baseDivOnMounted = () => {
        
        const propsIdCmd = getPropertyId(HtmlTypeConst.CMD_DETAIL,component) ?? '';
                const hasWf = component?.workFlows?.find(x => x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId == propsIdCmd && x.active == true)
                if (propsIdCmd && hasWf) { 
                    // esto es para mostrar los detalles
                    EventBusCustom.on(keyEventComponent,doComponentEvent);

                    watchEffect(() => {
                        
                        if (loadedOk.value == true) {
                        
                            processEvent(CatalogObjectTypeConst.ATTRCOMP, propsIdCmd,component,keyEventComponent);
                        }
                        else{
                            formBuildComp.value = -1;
                        }
                    })

                   
                    
                }
    }

        const baseDivOnBeforeUnmount = () => {
            EventBusCustom.off(keyEventComponent,doComponentEvent);   
            formBuildComp.value = -1;
            attrsInput.value.length = 0; 
        }
            
   
   
   return {
        attrsInput,
        formBuildComp,
        baseDivOnMounted,
        baseDivOnBeforeUnmount
        
    };  
  }

  
  

