export default class CatalogEventConst {
    static CLICK='event-click'
    static ONCRUDCLEAR='event-crudclear'
    static ONCRUDCLOSEACTIVETAB='event-crudcloseactivetab'
    static ONCRUDCLOSEALLTAB='event-crudclosealltab'
    static ONCRUDREFRESHACTIVETAB='event-crudrefreshactivetab'
    static ONCRUDSEACH='event-crudsearch'
    static ONCRUDLOADFROMDS='event-crudloadfromds'
    static CUSTOM='event-custom'
    static CHANGE='event-change'
    static ONACTIVATED='event-onactivated'
    static ONBEFOREMOUNT='event-onbeforemount'
    static ONBEFOREUNMOUNT='event-onbeforeunmounted'
    static ONBEFOREUPDATE ='event-onbeforeupdate'
    static ONCLOUSED='event-onclose'
    static ONDEACTIVATED='event-ondeactivated'
    static ONERRORCAPTURED='event-onerrorcapture'
    static ONMOUNTED='event-onmounted'
    static ONSELECTED='event-onselect'
    static ONUNMOUNTED='event-onunmounted'
    static ONUPDATED ='event-onupdate'
    static ONDIALOGCLOSECANCEL ='event-ondialogclose-cancel'
    static ONDIALOGCLOSEOK ='event-ondialogclose-ok'
    static BLUR='event-blur'
    static FOCUS='event-focus'
    }
    