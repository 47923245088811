import gql from 'graphql-tag';

export const GET_ALL_DATASOURCE = gql(`
query($applicationId: Int!,$applicationVersion: Int!) {
    dataSourceQuery {
      getAll(applicationId:$applicationId,applicationVersion:$applicationVersion) {
        id,
        applicationId,
        applicationVersion,
        name,
        description,
        idDataSourceType,
        fcr,
        ucr,
        uum,
        fum,
        iapDataSourceTableAliases{
            id,
            dataSourceId,
            tableName,
            tableAlias,
            shortDescription
            idIcon,
            join,
            isInput,
            fcr,
            ucr,
            uum,
            fum

        }
        iapDataSourceServiceConfigurations {
          id,
          dataSourceId,
          idServiceConfigurationType,
          key,            
          value,
          idEnvironmentType,
          description,
          fcr,
          ucr
        },
        iapDataSourceDataBase{
          id
          dataSourceId
          idBaseDatos
          idDataBaseType
          querySelect
          queryFrom
          queryWhere
          queryOrderBy
          queryGroupBy
          queryHaving
          filterDistinct
          fcr
          ucr
          uum
          fum
        },
        iapDataSourceService{
          dataSourceId
          idHttpMethodType
          idAuthType
          methodName
          type
          url
          fileJson
          host
          fcr
          ucr
          uum
          fum         
        },
        iapDataSourceFields{
          id,
          dataSourceId,
          dataSourceTableAliasId
          tableName,
          field,
          tableNameAlias,
          fieldAlias,
          primaryKey,
          foreignKey,
          identityColumn,
          computed
          dataSourceLookUpId,
          dataSourceLookUp{
                  id
                  dataSourceId
                  dataSource{
                    id,
                    iapDataSourceFields{
                          id,
                          dataSourceId,
                          tableName,
                          field,
                  }
                  }
          }
          position,
          defaultValue,
          nullable,
          encrypted,
          sqlType,
          length,
          precision,
          idDataType,
          catalogTypeId,
          foreignTableName,
          foreignTableFieldId,
          foreignTableFieldDescription
          shortDescription
          description
          localizable
          fcr
          ucr
          fum
          uum
          
        }
        
      }
    }
}
`);

export const GET_DATASOURCE_BY_ID = gql(`
query($applicationId: Int!,$applicationVersion: Int!,$datasourceId:Int!) {
    dataSourceQuery {
      getById(applicationId:$applicationId,applicationVersion:$applicationVersion,datasourceId:$datasourceId) {
        id,
        applicationId,
        applicationVersion,
        name,
        description,
        idDataSourceType,
        fcr,
        ucr,
        uum,
        fum,
        iapDataSourceTableAliases{
            id,
            dataSourceId,
            tableName,
            tableAlias,
            shortDescription
            idIcon,
            join,
            isInput,
            fcr,
            ucr,
            uum,
            fum

        }
        iapDataSourceServiceConfigurations {
          id,
          dataSourceId,
          idServiceConfigurationType,
          key,            
          value,
          idEnvironmentType,
          description,
          fcr,
          ucr
        },
        iapDataSourceDataBase{
          id
          dataSourceId
          idBaseDatos
          idDataBaseType
          querySelect
          queryFrom
          queryWhere
          queryOrderBy
          queryGroupBy
          queryHaving
          filterDistinct
          fcr
          ucr
          uum
          fum
        },
        iapDataSourceService{
          dataSourceId
          idHttpMethodType
          idAuthType
          methodName
          type
          url
          fileJson
          host
          fcr
          ucr
          uum
          fum         
        },
        iapDataSourceFields{
          id,
          dataSourceId,
          dataSourceTableAliasId
          tableName,
          field,
          tableNameAlias,
          fieldAlias,
          primaryKey,
          foreignKey,
          identityColumn,
          computed,
          dataSourceLookUpId,
          dataSourceLookUp{
                  id
                  dataSourceId
                  dataSource{
                    id,
                    iapDataSourceFields{
                          id,
                          dataSourceId,
                          tableName,
                          field,
                  }
                  }
          }
          position,
          defaultValue,
          nullable,
          encrypted,
          sqlType,
          length,
          precision,
          idDataType,
          catalogTypeId,
          foreignTableName,
          foreignTableFieldId,
          foreignTableFieldDescription
          shortDescription
          description
          localizable
          fcr
          ucr
          fum
          uum
          
        }
        
      }
    }
}
`);

