import {  injectable } from 'inversify'
import 'reflect-metadata'

import { watch } from 'vue';
import { SEARCH, SEARCHLOOKUP,SEARCH_TABLESVIEWS, SEARCH_FIELDSTABLE, PREVIEW_DATA , SEARCH_PROCEDURES_AND_FUNCTIONS, EXECUTE_PROC} from './query/searchQuery';


import MyApolloClient from '../../../../common/infrastructure/funciones/HelperApolloClient';
import ApolloConst from '../../../../common/domain/constantes/Apollo/ApolloConst';
import { ISearchRepository } from '../domain/ISearchRepository';
import { ResultadSearch } from '../domain/resultSearch';
import { ProcData, GroupSearch, LazyParams  } from '../domain/search';
import HelperUtils from '../../../../common/infrastructure/funciones/HelperUtils';
import { IapComponentDataSource } from '../../component/domain/iapComponentDataSource';
import { SearchMultiple } from '../domain/searchMultiple';
import combineQuery from 'graphql-combine-query'

@injectable()
export class SearchApolloClientRepository implements ISearchRepository {
  private apolloClient : MyApolloClient
  private clientId: String
  constructor (apolloInstance: MyApolloClient, clientId=ApolloConst.DEFAULT) {
    this.apolloClient = apolloInstance
    this.clientId = clientId
  }
  

    async search (applicationId: number, applicationversion: number, componentId: number, requestData: GroupSearch, parameters: any[], maxregs: number, componentDataSource?:IapComponentDataSource, dataSourceId?: number,count?:boolean,lazyParams?:LazyParams): (Promise<ResultadSearch>) {
        return await new Promise<ResultadSearch>(async (resolve, reject) => {     
          const result = await this.apolloClient.executeQuery(SEARCH,{applicationId:applicationId,applicationVersion:applicationversion,componentId:componentId,data:requestData, parameters: parameters,maxregs : maxregs,componentDataSource:componentDataSource,dataSourceId:dataSourceId,count:count,lazyParams:lazyParams},this.clientId)
          
          watch(result, (value: any) => {            
            try {
              HelperUtils.handleDatesApollo(value) 
              resolve(value.searchQuery.search)
            }
            catch(e) {
              reject()
            }
          });    
        });
      }
    

      async searchMultiple (data:SearchMultiple[]): (Promise<ResultadSearch[]>) {
        return await new Promise<ResultadSearch[]>(async (resolve, reject) => {  
                    
            const { document, variables } = (() => combineQuery('SearchMultipleQuery')  
                .addN(SEARCH, data.map(x=> ({applicationId:x.applicationId,applicationVersion:x.applicationversion,componentId:x.componentId,data:x.requestData, parameters: x.parameters,maxregs : x.maxregs,componentDataSource:x.componentDataSource,dataSourceId:x.dataSourceId,count:x.count,lazyParams:x.lazyParams}))    
            )
          )()


          const result = await this.apolloClient.executeQuery(document,variables,this.clientId)
          
          watch(result, (value: any) => {            
            try {
              
              HelperUtils.handleDatesApollo(value) 
              const outPut = new Array<ResultadSearch>();
              Object.keys(value).forEach((key:string)=>{
                outPut.push(value[key]['search'])
              })
              resolve(outPut)
            }
            catch(e) {
              reject()
            }
          });    
        });
      }
    

  
      async searchLookUp (applicationId:number,applicationversion:number,lookUpId:number,requestData: GroupSearch, maxregs : number,where?:string): (Promise<ResultadSearch>) {
        return await new Promise<ResultadSearch>(async (resolve, reject) => {     
          const result = await this.apolloClient.executeQuery(SEARCHLOOKUP,{applicationId:applicationId,applicationVersion:applicationversion,lookUpId:lookUpId,data:requestData,maxregs : maxregs,where:where},this.clientId)
          
          watch(result, (value: any) => {            
            try {
              HelperUtils.handleDatesApollo(value) 
              resolve(value.searchQuery.searchLookUpData)
            }
            catch(e) {
              reject()
            }
          });    
        });
      }
    
      async searchTablesViews (applicationId: number, applicationversion: number, databaseId: string, databaseTypeId: string): (Promise<ResultadSearch>) {
        return new Promise<ResultadSearch>(async (resolve, reject) => {     
          const result = this.apolloClient.executeQuery(SEARCH_TABLESVIEWS,{applicationId:applicationId,applicationVersion:applicationversion,databaseId:databaseId,databaseTypeId:databaseTypeId},this.clientId)
          
          watch(result, (value: any) => {            
            try {
              HelperUtils.handleDatesApollo(value) 
              resolve(value.searchQuery.getTablesAndViews)
            }
            catch(e) {
              reject()
            }
          });    
        });
      }

      async searchProceduresAndFunctions (applicationId: number, applicationversion: number, databaseId: string, databaseTypeId: string): (Promise<ResultadSearch>) {
        return new Promise<ResultadSearch>(async (resolve, reject) => {     
          const result = this.apolloClient.executeQuery(SEARCH_PROCEDURES_AND_FUNCTIONS,{applicationId:applicationId,applicationVersion:applicationversion,databaseId:databaseId,databaseTypeId:databaseTypeId},this.clientId)
          
          watch(result, (value: any) => {            
            try {
              HelperUtils.handleDatesApollo(value) 
              resolve(value.searchQuery.getProceduresAndFunctions)
            }
            catch(e) {
              reject()
            }
          });    
        });
      }




      
      
      async searchFieldsTable (applicationId:number,applicationversion:number,databaseTypeId:string, databaseId:string, tableNames:string[]): (Promise<ResultadSearch>) {
        return new Promise<ResultadSearch>(async (resolve, reject) => {     
          const result = this.apolloClient.executeQuery(SEARCH_FIELDSTABLE,{applicationId:applicationId,applicationVersion:applicationversion,databaseTypeId:databaseTypeId,databaseId:databaseId, tableNames:tableNames},this.clientId)
          
          watch(result, (value: any) => {            
            try {
              HelperUtils.handleDatesApollo(value) 
              resolve(value.searchQuery.getFieldsTable)
            }
            catch(e) {
              reject()
            }
          });    
        });
      }


      async getPreviewData (applicationId:number,applicationversion:number,databaseTypeId:string, databaseId:string, querySelect:string, queryFrom:string, queryWhere:string, queryOrderBy:string, queryGroupBy:string, queryHaving:string, maxRegs:number): (Promise<ResultadSearch>) {
        return new Promise<ResultadSearch>(async (resolve, reject) => {     
          const result = this.apolloClient.executeQuery(PREVIEW_DATA,{applicationId:applicationId,applicationVersion:applicationversion,databaseTypeId:databaseTypeId,databaseId:databaseId, querySelect:querySelect,queryFrom:queryFrom,queryWhere:queryWhere,queryGroupBy:queryGroupBy,queryHaving:queryHaving,queryOrderBy:queryOrderBy,maxRegs:maxRegs},this.clientId)
          
          watch(result, (value: any) => {            
            try {
              HelperUtils.handleDatesApollo(value) 
              resolve(value.searchQuery.getPreviewData)
            }
            catch(e) {
              reject()
            }
          });    
        });
      }


      async executeProcedureOrFunction (applicationId: number, applicationversion: number, databaseId: string, databaseTypeId: string,requestData: ProcData[]): (Promise<ResultadSearch>) {
        return new Promise<ResultadSearch>(async (resolve, reject) => {     
          const result = this.apolloClient.executeQuery(EXECUTE_PROC,{applicationId:applicationId,applicationVersion:applicationversion,databaseId:databaseId,databaseTypeId:databaseTypeId,data:requestData},this.clientId)
          
          watch(result, (value: any) => {            
            try {
              HelperUtils.handleDatesApollo(value) 
              resolve(value.searchQuery.executeProc)
            }
            catch(e) {
              reject()
            }
          });    
        });
      }
      

}


