import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_CustomValidate = _resolveComponent("CustomValidate")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.dataValue && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_MultiSelect, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.MultiSelectTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.MultiSelectTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.MultiSelectTypeConst.CLASS) ?? '']: true, 'p-invalid': _ctx.v$.vmodel.$error && _ctx.submitted,'p-readonly': _ctx.getPropertyBooleanValue(_ctx.MultiSelectTypeConst.READONLY) }),
          name: _ctx.getPropertyValue(_ctx.MultiSelectTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.MultiSelectTypeConst.DISABLED) || (!_ctx.canEdit),
          visible: _ctx.getPropertyBooleanValue(_ctx.MultiSelectTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
          placeholder: _ctx.getPropertyValue(_ctx.MultiSelectTypeConst.PLACEHOLDER),
          filter: _ctx.getPropertyBooleanValue(_ctx.MultiSelectTypeConst.FILTER),
          filterPlaceholder: _ctx.getPropertyValue(_ctx.MultiSelectTypeConst.FILTERPLACEHOLDER),
          display: _ctx.getDisplay(),
          selectedItemsLabel: _ctx.getPropertyValue(_ctx.MultiSelectTypeConst.SELECTEDITEMSLABEL),
          maxSelectedLabels: _ctx.getPropertyNumberValue(_ctx.MultiSelectTypeConst.MAXSELECTEDLABELS),
          selectionLimit: _ctx.getPropertyNumberValue(_ctx.MultiSelectTypeConst.SELECTIONLIMIT),
          showToggleAll: _ctx.getPropertyBooleanValue(_ctx.MultiSelectTypeConst.SHOWTOGGLEALL),
          selectAll: _ctx.getPropertyBooleanValue(_ctx.MultiSelectTypeConst.SELECTALL),
          resetFilterOnHide: _ctx.getPropertyBooleanValue(_ctx.MultiSelectTypeConst.RESETFILTERONHIDE),
          autoOptionFocus: _ctx.getPropertyBooleanValue(_ctx.MultiSelectTypeConst.AUTOOPTIONFOCUS),
          autoFilterFocus: _ctx.getPropertyBooleanValue(_ctx.MultiSelectTypeConst.AUTOFILTERFOCUS),
          filterMessage: _ctx.getPropertyValue(_ctx.MultiSelectTypeConst.FILTERMESSAGE),
          selectionMessage: _ctx.getPropertyValue(_ctx.MultiSelectTypeConst.SELECTIONMESSAGE),
          emptyMessage: _ctx.getPropertyValue(_ctx.MultiSelectTypeConst.EMPTYMESSAGE),
          dataKey: _ctx.getPropertyValue(_ctx.MultiSelectTypeConst.DATAKEY),
          readonly: _ctx.getPropertyBooleanValue(_ctx.MultiSelectTypeConst.READONLY) || !_ctx.canDoOperation(_ctx.ObjectGroupConst.EDIT),
          options: _ctx.dataValue,
          optionLabel: _ctx.getOptionLabel(),
          optionValue: _ctx.getOptionValue(),
          optionDisabled: _ctx.getPropertyValue(_ctx.MultiSelectTypeConst.OPTIONDISABLED),
          optionGroupLabel: _ctx.getOptionGroupLabel(),
          optionGroupChildren: _ctx.getOptionGroupChildren(),
          scrollHeight: _ctx.getPropertyValue(_ctx.MultiSelectTypeConst.SCROLLHEIGHT),
          modelValue: _ctx.arrayModel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.arrayModel) = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setArrayToVModel())),
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.processEventComponent(_ctx.CatalogEventConst.BLUR,_ctx.Component))),
          onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.processEventComponent(_ctx.CatalogEventConst.FOCUS,_ctx.Component)))
        }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "filter", "filterPlaceholder", "display", "selectedItemsLabel", "maxSelectedLabels", "selectionLimit", "showToggleAll", "selectAll", "resetFilterOnHide", "autoOptionFocus", "autoFilterFocus", "filterMessage", "selectionMessage", "emptyMessage", "dataKey", "readonly", "options", "optionLabel", "optionValue", "optionDisabled", "optionGroupLabel", "optionGroupChildren", "scrollHeight", "modelValue"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.MultiSelectTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE))
      ? (_openBlock(), _createBlock(_component_CustomValidate, {
          key: 2,
          submitted: _ctx.submitted,
          "onUpdate:submitted": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.submitted) = $event)),
          vObject: _ctx.v$,
          "onUpdate:vObject": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.v$) = $event))
        }, null, 8 /* PROPS */, ["submitted", "vObject"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}