import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_InputMask = _resolveComponent("InputMask")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_InputMask, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.InputMaskTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.InputMaskTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.InputMaskTypeConst.CLASS) ?? '']: true, 'customrequired': _ctx.getPropertyBooleanValue(_ctx.InputMaskTypeConst.REQUIRED) }),
          name: _ctx.getPropertyValue(_ctx.InputMaskTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.InputMaskTypeConst.DISABLED),
          visible: _ctx.getPropertyBooleanValue(_ctx.InputMaskTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW),
          placeholder: _ctx.getPropertyValue(_ctx.InputMaskTypeConst.PLACEHOLDER),
          slotChar: _ctx.getPropertyValue(_ctx.InputMaskTypeConst.SLOTCHAR),
          mask: _ctx.getPropertyValue(_ctx.InputMaskTypeConst.MASK),
          autoClear: _ctx.getPropertyBooleanValue(_ctx.InputMaskTypeConst.AUTOCLEAR),
          unmask: _ctx.getPropertyBooleanValue(_ctx.InputMaskTypeConst.UNMASK),
          readonly: _ctx.getPropertyBooleanValue(_ctx.InputMaskTypeConst.READONLY) || !_ctx.canDoOperation(_ctx.ObjectGroupConst.EDIT),
          onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.processEventComponent(_ctx.CatalogEventConst.BLUR,_ctx.Component))),
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.processEventComponent(_ctx.CatalogEventConst.FOCUS,_ctx.Component)))
        }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "slotChar", "mask", "autoClear", "unmask", "readonly"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.InputMaskTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}