import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'
import { IServiceCache } from './IServiceCache'
import type { ICacheRepository } from '../domain/ICacheRepository'





@injectable()
export class CacheService implements IServiceCache {
  private readonly cfgRepo: ICacheRepository

  public constructor (@inject(TYPES.CACHE_REPOSITORY) _cfgRepo: ICacheRepository) {
    this.cfgRepo = _cfgRepo
  }
     
    async refresh(applicationId: number, applicationVersion:number,typeId: string, id: string,optionalData:string) :Promise<boolean> {
      return await this.cfgRepo.refresh(applicationId, applicationVersion,typeId,id,optionalData)
    }
 
}
