<template>
            <div class="card">
                <div>
                    <h5 v-html="caption"></h5>
                    <FileUpload name="files[]" :customUpload="true" @uploader="myUploader($event, null)"
                        :multiple="multiple" :maxFileSize="maxFileSize" :key="generateKey" :accept="fileExtensions"
                        
                        >
                        <template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
                            <div v-if="files.length > 0">
                                <div class="flex flex-wrap p-0 sm:p-5 gap-5">
                                    <div v-for="(file, index) of files" :key="file.name + file.type + file.size"
                                        class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                                        <div>
                                            <img v-if="file.objectURL" role="presentation" :alt="file.name"
                                                :src="file.objectURL" width="100" height="50" />
                                            <i v-else-if="file.name.toLowerCase().endsWith('.pdf')"
                                                class="pi pi-file-pdf" style="font-size: 3rem"></i>
                                            <i v-else-if="file.name.toLowerCase().endsWith('.eml') || file.name.toLowerCase().endsWith('.msg')"
                                                class="pi pi-envelope" style="font-size: 3rem"></i>
                                            <i v-else class="pi pi-file" style="font-size: 3rem"></i>
                                        </div>
                                        <span class="font-semibold">{{ file.name }}</span>
                                        <div>{{ formatSize(file.size) }}</div>
                                        <span class="font-semibold customrequired"
                                            :class="{ 'p-invalid': ((documentsDataType[index] ?? '') == '' ) && submitted }">Tipo</span>
                                        <Dropdown filter :showClear="true" v-model="documentsDataType[index]" 
                                            :options="docTypes" optionValue="id" optionLabel="description"  @change="stopPropagateEventDropDown($event)"/>
                                        <span v-if="submitted && 1< 0">
                                            <span v-for="error in v$.itemsdt.$each.$response.$errors[index].value"
                                                :key="error">
                                                <small class="p-error">{{ error.$message }}</small>
                                            </span>
                                        </span>

                                        <span v-if="isUserAdmin()" class="font-semibold">Visibilidad</span>
                                        <MultiSelect v-if="isUserAdmin()" filter :showClear="true"
                                            v-model="documentsDataVisibility[index]" filtered :options="identityTypes"
                                            optionValue="id" optionLabel="description"  @change="stopPropagateEventDropDown($event)" />


                                        <span class="font-semibold customrequired"
                                            :class="{ 'p-invalid': ((documentsDataDescription[index] ?? '') == '') && submitted }">Descripción</span>
                                        <InputText v-model="documentsDataDescription[index]" />
                                        <span v-if="submitted  && 1< 0">
                                            <span v-for="error in v$.itemsdes.$each.$response.$errors[index].value"
                                                :key="error">
                                                <small class="p-error">{{ error.$message }}</small>
                                            </span>
                                        </span>

                                        <Badge value="Pendiente" severity="warning" />
                                        <Button icon="pi pi-times"
                                            @click="onRemoveTemplatingFile(file, removeFileCallback, index)" outlined
                                            rounded severity="danger" />
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #empty>
                            <div class="flex align-items-center justify-content-center flex-column">
                                <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400" />
                                <p class="mt-4 mb-0"> <span v-html="captionDropArea"></span></p>
                            </div>
                        </template>

                    </FileUpload>
                </div>
            </div>
  



    
</template>
<script lang="ts">

import { defineComponent, ref, onMounted } from 'vue';
import HelperCommon from '../../../../../common/infrastructure/funciones/HelperCommon';
import HelperLoading from '../../../../../common/infrastructure/funciones/HelperLoading';
import { MessageService } from '../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../common/infrastructure/servicios/MessageService';
import { useStore } from 'vuex';
import { FileUploadUploaderEvent } from 'primevue/fileupload';

import HelperUtils from '../../../../../common/infrastructure/funciones/HelperUtils';
import { IServiceDocument } from '../../application/IServiceDocument';
import { TYPES } from '../../../../../common/domain/types';
import { Container } from 'inversify';
import ComponentDataForm from '../../../../builder/designer/domain/ComponentDataForm';
import helperCatalog from '../../../../builder/catalog/infrastructure/helper/helperCatalog';
import fechaComp from '../../../../../common/infrastructure/funcionescompartidas/fechaComp';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { IapCatalog } from '../../../../builder/catalog/domain/iapCatalog';
import { usePrimeVue } from 'primevue/config';
import { useI18n } from 'vue-i18n';
import { messages } from '../../../../builder/catalog/infrastructure/localization/Messages';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Environment from '../../../../../common/infrastructure/funciones/environment';
import { StorageDocument } from '../../domain/storageDocument';
import DataBaseTypeConst from '../../../../../common/domain/constantes/DataBaseTypeConst';
import { DropdownChangeEvent } from 'primevue/dropdown';
import { MultiSelectChangeEvent } from 'primevue/multiselect';

export default defineComponent({
    name: 'upload_file',
    events:['document_added','document_modified'],
    components: {
        
    },
    props: {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
       
        idObject:
        {
            type: String,
            default: ('')
        },
        objectId:
        {
            type: Number,
            default: (-1)
        },
        entityMainIntegracionId:{
            type:Number,
            default:1
        },
        caption:
        {
            type: String,
            default: ('')
        },

        fileExtensions:
        {
            type: String,
            default: ('.doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .pdf, .zip, .rar, .gz, .tar, .tgz, .7z, .bmp, .jpg, .jpeg, .tiff, .png, .ico, .gif, .msg')
        },
        maxFileSize:
        {
            type: Number,
            default: (15728640)
        },

        multiple:
        {
            type: Boolean,
            default: (false)
        },

        captionDropArea:
        {
            type: String,
            default: ('Arrastre los ficheros aqui o pulse para buscarlos.')
        },
        source:
        {
            type: String,
            default: (DataBaseTypeConst.DOCUMENTOS_MODEL)
        },
        documentId:{
            type:Number,
            default:undefined
        },
        integrate:
        {
            type: Boolean,
            default: (false)
        },
        integrationTypeId:
        {
            type: String,
            default: 'Mpm'
        },
    },
    setup(props,{emit}) {
        const { t, locale } = useI18n(messages)
        const store = useStore();
        const generateKey = ref('');
        const documentsDataType = ref([])
        const documentsDataDescription = ref([])
        const documentsDataVisibility = ref([[]])
        const doctype = (Environment.APPID == 3 || Environment.APPID == 32) ? 'documentTypeTable': 'documentoTipo'
        const profiletype = (Environment.APPID == 3 || Environment.APPID == 32) ? 'entityTypeTable': 'identidad'
        // 
        const docTypes = ref<IapCatalog[]>(helperCatalog.getCatalog(doctype).filter(x => (doctype == 'documentoTipo' &&  x.parentId == props.idObject) || doctype == 'documentTypeTable')) 
        const identityTypes = ref<IapCatalog[]>(helperCatalog.getCatalog(profiletype).filter(x => (profiletype == 'identidad' && x.additionalValue == 'Perfil') || profiletype == 'entityTypeTable' )) 
      
        const submitted = ref(false);


        const rulesValidation = {
            itemsdt: {
                $each: helpers.forEach({

                    required: helpers.withMessage('El tipo de documento es obligatorio', requiredIf((...args) => {
                            var datos = args as any;
                            return HelperCommon.isNullOrWhitespace(datos[args.length - 1])

                        }
                        ))
                })
            },
            itemsdes: {
                $each: helpers.forEach({
                    required: helpers.withMessage('La descripción es obligatoria', requiredIf((...args) => {
                            var datos = args as any;
                            return HelperCommon.isNullOrWhitespace(datos[args.length - 1])

                        }
                        ))
                })
            }
        }

        //requiredIf(prop => prop.type === 'input')


        const v$ = useVuelidate(rulesValidation, { itemsdt: documentsDataType, itemsdes: documentsDataDescription })


        const isUserAdmin = () => {
            const adminProfiles = ['identidad-ADM','identidad-MED']
            return store.getters.getCurrentUser.isAdmin || adminProfiles.includes(store.getters.getCurrentUser?.perfil?.profileId);
        }



      

        const isValidData = (): boolean => {
            submitted.value = true;
            v$.value.$reset();
            v$.value.$touch();
            if (v$.value.$invalid) {
                HelperCommon.setFocusOnFirstError();
                return false;
            }

            return true;
        }

        const myUploader = (event: any, data: any) => {
          
            if (isValidData()) {
                
                let totalFiles = event.files.length;
                const totalSize = event.files.reduce((accumulator, currentValue) => accumulator.size + currentValue.size);
                if (totalSize.size > 52428800) {
                    MessageService.showToast(MessageType.Error, '', 'La suma del tamaño total no puede superar los 20 Mb.');
                }
                else {
                    HelperLoading.showLoading();
                    event.files.forEach((element, index) => {
                        HelperCommon.toBase64(element).then((value) => {
                            
                            //const sessionId = store.getters.sessionID;
                            const type = (element.type == '' ? 'application/octet-stream' : element.type);
                            const base64 = value as any;
                            const content = base64.substr(base64.indexOf(',') + 1);
                            //const doctype = document.getElementById('type_' + element.name) as HTMLInputElement;
                            //const description = document.getElementById('description_' + element.name) as HTMLSelectElement;

                            const originalDescription = (documentsDataDescription.value[index] ?? '') == '' ? element.name : documentsDataDescription.value[index]
                            const descriptionWithoutAccent = originalDescription.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                            const originalFile = element.name
                            const fileWithoutAccent = originalFile.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

                            const docu: StorageDocument = ({
                                id: props.documentId == undefined ? 0 : props.documentId,
                                idOld: null,
                                idObjeto: props.idObject ?? '',
                                objetoId: props.objectId ?? -1,
                                fileName: fileWithoutAccent,
                                contentType: type,
                                entityMainIntegracionId: props.entityMainIntegracionId,
                                documentTypeId: HelperCommon.isNullOrWhitespace(documentsDataType.value[index] ?? '') ? 'documentoTipo-11' : documentsDataType.value[index],
                                externalStorageId: null,
                                storageConfigurationId: null,
                                urlLink: null,
                                markForDelete: null,
                                description: descriptionWithoutAccent,
                                visibility: isUserAdmin() ? (documentsDataVisibility.value[index]?.join(',') ?? null) : store.getters.getCurrentUser?.perfil?.profileId,
                                fcr: new Date(),
                                ucr: store.getters.getCurrentUser.id,
                                fum: null,
                                uum: null,
                                ucrName: null,
                                uumName: null,
                                content: null

                            });

                            if (props.container) {
                                const _srv = props.container.get<IServiceDocument>(TYPES.STORAGE_REPOSITORY)

                                if (props.documentId == undefined){
                                    if (content){

                                            _srv.add(JSON.parse(JSON.stringify(docu)), content,props.Component.id as any,props.source, props.integrate, props.integrationTypeId).then(response => {
                                            if (response) {
                                                totalFiles--;
                                                if (totalFiles <= 0) {
                                                    //  reloadData();
                                                    HelperLoading.hideLoading();
                                                    generateKey.value = HelperUtils.newGuid();
                                                    emit('document_added',response)
                                                    MessageService.showToast(MessageType.Correcto, '', 'Se han añadido los documentos correctamente');                                            
                                                    documentsDataType.value=[]
                                                    documentsDataVisibility.value=[]
                                                    documentsDataDescription.value=[]
                                                }
                                            }                                   
                                        }).finally(() => {
                                            HelperLoading.hideLoading();
                                        })
                                    }    
                                    else{
                                        totalFiles--;
                                        MessageService.showToast(MessageType.Info,'',`El documento '${docu.description}' no se ha añadido porque no tiene contenido.`)
                                    }
                                }
                                else{
                                    _srv.update(JSON.parse(JSON.stringify(docu)), content, props.Component.id as any, props.source).then(response => {
                                        if (response) {
                                            
                                            generateKey.value = HelperUtils.newGuid();
                                            emit('document_modified',response)
                                            MessageService.showToast(MessageType.Correcto, '', 'Se ha modificado el documentos correctamente');                                            
                                            documentsDataType.value=[]
                                            documentsDataVisibility.value=[]
                                            documentsDataDescription.value=[]

                                        }
                                    }).finally(()=>{
                                        HelperLoading.hideLoading();
                                    });
                                }

                              
                            }
                        });
                    })
                }
               

            }

        }



        const formatSize = (bytes:any) => {
            return HelperUtils.formatSize(bytes)
        };

        const onRemoveTemplatingFile = (file, removeFileCallback, index) => {
            removeFileCallback(index);
        }

        const stopPropagateEventDropDown = (event: DropdownChangeEvent | MultiSelectChangeEvent) => {
            
            event.originalEvent.stopPropagation();
            event.originalEvent.preventDefault();
        }

      

        onMounted(() => {
       
        });


        return {
            docTypes,
            documentsDataVisibility,
            myUploader,
            generateKey,
            formatSize,
            onRemoveTemplatingFile,
            documentsDataType,
            documentsDataDescription,
            identityTypes,
            t,
            v$,
            submitted,
            isUserAdmin,
            stopPropagateEventDropDown
        };
    },
});
</script>
<style scoped></style>
