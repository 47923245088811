import { ClassicPreset } from 'rete';
import { AreaPlugin } from 'rete-area-plugin';
import { AreaExtra, Schemes } from '../rete/helperRete';
import { Router } from 'vue-router';
import { Container } from 'inversify';
import { IapComponent } from '../../../../../component/domain/iapComponent';
import { IapWorkFlowActivity } from '../../../../domain/service/iapWorkFlowActivity';
import { CacheControl } from '../controls/cacheControl';
import { DataflowEngine } from 'rete-engine';
import OperationDataTypeConst from '../../../../../../../common/domain/constantes/OperationDataTypeConst';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import { TYPES } from '../../../../../../../common/domain/types';
import { IServiceCache } from '../../../../../cache/application/IServiceCache';

export class CacheNode extends ClassicPreset.Node<
  { ejecutar: ClassicPreset.Socket  },
  { true: ClassicPreset.Socket, false: ClassicPreset.Socket },
  { value: CacheControl }
> {
  height = 220;
  width = 420;


  private variableOption: string;

  private area: AreaPlugin<Schemes, AreaExtra>;
  private updateNode: any;
  private getNodeInternalData: any;
  private showExpression: any;
  private rdControlId: '';
  private router: Router;
  private container: Container | undefined;
  private rootComponentId: number;
  private currentComponentId: number;
  private store: any;
  private activity: IapWorkFlowActivity | undefined;
  private componentData: IapComponent[];
  private dataflow: DataflowEngine<Schemes>
  private formKey:String;
  private currentElementKey:string;
  private applicationId: number;
  private applicationVersion: number;
  constructor(area: AreaPlugin<Schemes, AreaExtra>, socket: ClassicPreset.Socket,public formKeyInput: String,public currentElementKeyInput:string, public rootComponentInputId: number, public currentComponentInputId: number, public applicationIdInput: number, public applicationVersionInput: number, public variableOptionInput: string, updateNode: any = undefined, getNodeInternalData: any = undefined, showExpressionFunction: any = undefined, router: Router, container: Container | undefined, storeInput: any, itemActivity: IapWorkFlowActivity | undefined, dataflow: DataflowEngine<Schemes>,componentDataInput: IapComponent[]) {
    super("Caché");
    this.area = area;
    this.dataflow = dataflow;
    this.updateNode = updateNode;
    this.getNodeInternalData = getNodeInternalData;
    this.showExpression = showExpressionFunction;

    this.variableOption = variableOptionInput;

    
    this.router = router;
    this.container = container;
    this.rootComponentId = rootComponentInputId;
    this.currentComponentId = currentComponentInputId;
    this.store = storeInput;
    this.activity = itemActivity;    
    this.componentData = componentDataInput;
    this.formKey = formKeyInput;
    this.currentElementKey = currentElementKeyInput;
    this.applicationId = applicationIdInput;
    this.applicationVersion = applicationVersionInput;

    const dsControl = new CacheControl(formKeyInput,rootComponentInputId, currentComponentInputId, applicationIdInput, applicationVersionInput, variableOptionInput,container, this.updateData, this.getNode, this.showExp);

    this.rdControlId = (dsControl as any).id;

    this.addInput("ejecutar", new ClassicPreset.Input(socket, "Ejecutar", true));
    this.addControl(
      "value",
      dsControl

      //new ClassicPreset.InputControl("text", { initial })
    );

    this.addOutput("true", new ClassicPreset.Output(socket, "True", true));
    this.addOutput("false", new ClassicPreset.Output(socket, "False", true));

  }

  showExp = (evt: any) => {
    if (this.showExpression) {
      return this.showExpression(evt)
    }
    return null;
  }

  getNode = (key: string) => {
    if (this.getNodeInternalData) {
      return this.getNodeInternalData(this.id, key, true, false)
    }
    return null;
  }
  updateData = (evt: any) => {

    //this.value = evt
    //@ts-ignore:disable-next-line
    this.controls.value[evt.key] = evt.data;
    //this.controls[evt.key].valueConnection = evt.data;

    this.area.update("control", this.rdControlId)


    if (this.updateNode) {
      this.updateNode(this.id, evt.key, JSON.stringify(evt.data), (evt?.operation ?? OperationDataTypeConst.UPDATE))
    }


  }


  




  async execute(_: never, forward: (output: "true" | "false") => void) {        
    if (this.container){
        HelperLoading.showLoading()
    
        const _srv = this.container.get<IServiceCache>(TYPES.CACHE_REPOSITORY)
        _srv.refresh(this.applicationId, this.applicationVersion, this.variableOption, '','').then((response:boolean) => {
            if (response) {
                forward("true"); 
            }
            else {
                forward("false"); 
            }
                
            })
            .finally(() => {                
                HelperLoading.hideLoading();
                
            })
    }
    else{
        forward("false"); 
    }

 

  }


  data(): { value: string } {
    return {
      //@ts-ignore:disable-next-line
      value: this.controls.value['value'] || ""
    };
  }

}