import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_CustomValidate = _resolveComponent("CustomValidate")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.getPropertyBooleanValue(_ctx.CheckBoxTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.CheckBoxTypeConst.VISIBLE) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW))
      ? _withDirectives((_openBlock(), _createBlock(_component_Checkbox, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.CheckBoxTypeConst.ID),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.CheckBoxTypeConst.STYLE)),
          class: _normalizeClass({ [_ctx.getPropertyValue(_ctx.CheckBoxTypeConst.CLASS) ?? '']: true, 'p-invalid': _ctx.v$.vmodel.$error && _ctx.submitted ,'p-readonly': _ctx.getPropertyBooleanValue(_ctx.CheckBoxTypeConst.READONLY)}),
          name: _ctx.getPropertyValue(_ctx.CheckBoxTypeConst.NAME),
          disabled: _ctx.getPropertyBooleanValue(_ctx.CheckBoxTypeConst.DISABLED) || (!_ctx.canEdit),
          visible: _ctx.getPropertyBooleanValue(_ctx.CheckBoxTypeConst.VISIBLE),
          placeholder: _ctx.getPropertyValue(_ctx.CheckBoxTypeConst.PLACEHOLDER),
          binary: _ctx.getPropertyBooleanValue(_ctx.CheckBoxTypeConst.BINARY),
          readonly: _ctx.getPropertyBooleanValue(_ctx.CheckBoxTypeConst.READONLY) || !_ctx.canDoOperation(_ctx.ObjectGroupConst.EDIT),
          required: _ctx.getPropertyBooleanValue(_ctx.CheckBoxTypeConst.REQUIRED),
          tabIndex: _ctx.getPropertyNumberValue(_ctx.CheckBoxTypeConst.TABINDEX),
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vmodel) = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.processEventComponent(_ctx.CatalogEventConst.CHANGE))),
          onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.processEventComponent(_ctx.CatalogEventConst.BLUR,_ctx.Component))),
          onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.processEventComponent(_ctx.CatalogEventConst.FOCUS,_ctx.Component)))
        }, null, 8 /* PROPS */, ["id", "style", "class", "name", "disabled", "visible", "placeholder", "binary", "readonly", "required", "tabIndex", "modelValue"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.CheckBoxTypeConst.TOOLTIP)]
        ])
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.CheckBoxTypeConst.VISIBLE))
      ? (_openBlock(), _createBlock(_component_CustomValidate, {
          key: 2,
          submitted: _ctx.submitted,
          "onUpdate:submitted": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.submitted) = $event)),
          vObject: _ctx.v$,
          "onUpdate:vObject": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.v$) = $event))
        }, null, 8 /* PROPS */, ["submitted", "vObject"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}