/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { Actions, Mutations } from '../enums/StoreEnums'
import { Module, Action, VuexModule, Mutation } from 'vuex-module-decorators'
// import { _whoAmiRequest } from '@frontcommon/shared/src/modelos/login/_whoAmiRequest'
// import CookieService from '../../servicios/CookieService'
// import HelperSecurity from '../../funciones/HelperSecurity'
// import HelperRfp from '../../funciones/HelperRfp'
import { TYPES } from '../../../domain/types'
import 'reflect-metadata'
import MessageService, { MessageType } from '../../servicios/MessageService'
import { Container } from 'inversify'
import { CustomMenu, CustomMenu as menu } from '../../../domain/modelos/menu/CustomMenu'
import { authResponse } from '../../../../entidades/builderMaster/auth/domain/authResponse'
import { authRequest } from '../../../../entidades/builderMaster/auth/domain/authRequest'
import { IServiceAuth } from '../../../../entidades/builderMaster/auth/application/IServiceAuth'
import { IServiceEncrypt } from '../../../../entidades/builderEncrypt/application/IServiceEncrypt'
import { LocalService, CookieService } from '../../servicios'
import { IServiceMenu } from '../../../../entidades/builder/menu/application/IServiceMenu'
import HelperMenu from '../../../../entidades/builder/menu/infrastructure/HelperMenu'
import { IapLocalization } from '../../../../entidades/builder/localization/domain/iapLocalization';
import * as vueRouter from 'vue-router'
import { IapmUser } from '../../../../entidades/builderMaster/user/domain/iapmUser'
import { IapmGroup } from '../../../../entidades/builderMaster/user/domain/iapmGroup'
import {IapApplicationConfiguration } from '../../../../entidades/builder/application/domain/iapApplicationConfiguration';
import { autoAuthRequest } from '../../../../entidades/builderMaster/auth/domain/autoAuthRequest'
import { AppLayoutConfig } from '../../../../entidades/builder/template/infrastructure/prime/AppLayoutConfig'
import Environment from '../../funciones/environment'
import { IapmoConfiguration } from '../../../../entidades/builderModel/configuration/domain/iapmoConfiguration'
import { uploadFile } from '@ilinium/shared/src/entidades/builderMaster/auth/domain/uploadFile'
import { dobleAuthenticationRequest } from '../../../../entidades/builderMaster/auth/domain/dobleAuthenticationRequest'
import { IServiceDobleAuthenticationUser } from '../../../../entidades/builderMaster/user/application/IServiceDobleAuthenticationUser'
import { IServiceComponent } from '@ilinium/shared/src/entidades/builder/component/application/IServiceComponent'
import CatalogConfigurationConst from '../../../../entidades/builder/catalog/domain/const/CatalogConfigurationConst'
import HelperApplication from '@ilinium/shared/src/entidades/builder/application/infrastructure/functions/helperApplication'

@Module
export default class AuthModule extends VuexModule implements authResponse {
  access_token = '' as string
  expires_in = '' as string
  refresh_token = '' as string
  user = {} as IapmUser
  dobleFactorCode = '' as string
  dobleFactorFechaCaducidad = '' as string
  dobleFactorCodeFechaCaducidad = '' as string
  currentProfileId = null as number | null
  currentProfileType = '' as string
  isAuthenticated = false
  menusApp = []
  menusLocalizations = {}
  computerName = '' as string
  routes = false
  appConfig = [] as IapApplicationConfiguration[];
  appLayoutConfig = {} as AppLayoutConfig;
  external_session = '' as string;
  app_version = '' as string
  entityMainConfig :IapmoConfiguration[]=([])
  

  get getAppConfig(): IapApplicationConfiguration[] {
    return this.appConfig;
  }

  get getAppLayoutComponentId(): number | null {
    const { getAppKeyValue } = HelperApplication(undefined)
    const keyValue=getAppKeyValue(CatalogConfigurationConst.COMP_LAYOUT,Environment.ENVIRONMENT,this.appConfig)
    return parseInt(keyValue ?? '-1');
  }

  get getAppLayoutConfig(): AppLayoutConfig {
    return this.appLayoutConfig;
  }

  
  get isUserAuthenticated(): boolean {
    return this.access_token !== undefined && this.access_token !== '' && this.isAuthenticated// && this.expires_in > now
  }

  get getRoutes(): boolean {
    return this.routes
  }

  get getAcessToken(): string {
    return this.access_token
  }

  get getSessionId(): string {
    return this.external_session;
  }

  

  get getDobleFactorCode(): string {
    return this.dobleFactorCode
  }

  get getDobleFactorCodeFechaCaducidad(): string {
    return this.dobleFactorCodeFechaCaducidad
  }

  get getDobleFactorFechaCaducidad(): string {
    return this.dobleFactorFechaCaducidad
  }

  get getApplicationMenus(): CustomMenu[] {
    return this.menusApp
  }

  get getMenusLocalizations(): Object {
    return this.menusLocalizations
  }

  get getRefreshToken(): string {
    return this.refresh_token
  }

  get getCurrentUser(): IapmUser {
    return this.user
  }

  get getCurrentProfile(): number | null {
    return this.currentProfileId
  }

  get getCurrentProfileType(): string {
    return this.currentProfileType;
  }

  get getGroups(): IapmGroup[]  {
    return this.user?.groups;
  }



  get currentBrokerId(): number | null {
    return this.user?.entityMainCurrentId
  }

  get currentBrokerLogo(): string | null {
    
    if (this.currentBrokerId){
      return Environment.URL_IMG_ENTITY + this.currentBrokerId.toString() +'.png';
    }
    else{
      return Environment.URL_LOGO_PNG;
    }

  }

  get currentAppLogo(): string | null {
    
    return Environment.URL_LOGO_PNG;
  }


  get getComputerName(): string {
    return this.computerName
  }


  get getAppVersion(): string {
    return this.app_version
  }


  get entityMainCfgValue(): any {            
    const cfg = this.entityMainConfig;
    return function ( key: string) {
        return cfg.find(x=> x.idKey == key)?.value
        
    }
}

get userOptions(): any {            
  const usr = this.user;
  return function ( key: string) {
      return usr?.iapmUserOptions?.find(x=> x.optionId == key)?.value
      
  }
}

get encrypt(): any {            
  
  return async function ( container: Container,key: string) {
        
    return await new Promise<string>((resolve, reject) => {
      const _srv = container.get<IServiceEncrypt>(TYPES.ENCRYPT_SERVICE)
      _srv.encrypt(key)
        .then(async (response: any) => {
          resolve(response)
        })
        .catch((e) => {
          reject(e)
        })
    })
      
  }
}


get initUrlPage():string | null | undefined{
  return this.user?.iapmUserOptions.find(x=> x.optionId== '' )?.value ?? this.appConfig.find(x=> x.idKeyType == CatalogConfigurationConst.PAGE_INIT)?.keyValue;
}

@Action({ rawError: true })
  async [Actions.AUTHMICROSOFT](params: any) {
    const request: authRequest = params[0].value
    const container: Container = params[1]
    await new Promise<void>((resolve, reject) => {
      const _srvAuth = container.get<IServiceAuth>(TYPES.AUTH_SERVICE)
      _srvAuth.authMicrosoft(request)
        .then(async (response: any) => {          
          if (response.data.failed === true) {
            //MessageService.showMessage(MessageType.Error, 'Error de validación', response.data.message)
            reject(response.data.message)
          } else {
            
            const cfg= this.appConfig
            this.context.commit(Mutations.PURGE_AUTH);
            this.context.commit(Mutations.SET_APPCONFIG, cfg)            
            this.context.commit(Mutations.SET_AUTH, response.data.data)
            if (request.brokerId != null && request.external == false) {
              this.context.commit(Mutations.SETPROFILE, request.profileId)
            }
            else if (request.brokerId != null && request.external == true) {
              this.context.commit(Mutations.SETPROFILE, response.data.data.profileId)
            }
            //resolve()

            await new Promise<void>((resolve2, reject) => {
              _srvAuth.whoAmI()
                .then((response: any) => {
                  var user = response
                  this.context.commit(Mutations.WHOAMI, [this, user])
                  resolve2()
                  resolve()
                })
                .catch((e) => {
                  reject(e.data.Message)
                })
            })

          }
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.AUTHGOOGLE](params: any) {
    const request: authRequest = params[0].value
    const container: Container = params[1]
    await new Promise<void>((resolve, reject) => {
      const _srvAuth = container.get<IServiceAuth>(TYPES.AUTH_SERVICE)
      _srvAuth.authGoogle(request)
        .then(async (response: any) => {
          if (response.data.failed === true) {
            //MessageService.showMessage(MessageType.Error, 'Error de validación', response.data.message)
            reject(response.data.message)
          } else {
            
            const cfg= this.appConfig
            this.context.commit(Mutations.PURGE_AUTH);
            this.context.commit(Mutations.SET_APPCONFIG, cfg)            
            this.context.commit(Mutations.SET_AUTH, response.data.data)
            if (request.brokerId != null && request.external == false) {
              this.context.commit(Mutations.SETPROFILE, request.profileId)
            }
            else if (request.brokerId != null && request.external == true) {
              this.context.commit(Mutations.SETPROFILE, response.data.data.profileId)
            }
            //resolve()

            await new Promise<void>((resolve2, reject) => {
              _srvAuth.whoAmI()
                .then((response: any) => {
                  var user = response
                  this.context.commit(Mutations.WHOAMI, [this, user])
                  resolve2()
                  resolve()
                })
                .catch((e) => {
                  reject(e.data.Message)
                })
            })

          }
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.AUTH](params: any) {
    const request: authRequest = params[0].value
    const container: Container = params[1]
    this.context.commit(Mutations.SET_NOAUTH, null)
    await new Promise<void>((resolve, reject) => {
      const _srvAuth = container.get<IServiceAuth>(TYPES.AUTH_SERVICE)
      _srvAuth.auth(request)
        .then(async (response: any) => {
          if (response.data.failed === true) {
            //MessageService.showMessage(MessageType.Error, 'Error de validación', response.data.message)
            reject(response.data.message)
          } else {
            
            const cfg= this.appConfig
            this.context.commit(Mutations.PURGE_AUTH);
            this.context.commit(Mutations.SET_APPCONFIG, cfg)            
            this.context.commit(Mutations.SET_AUTH, response.data.data)
            if (request.brokerId != null && request.external == false) {
              this.context.commit(Mutations.SETPROFILE, request.profileId)
            }
            else if (request.brokerId != null && request.external == true) {
              this.context.commit(Mutations.SETPROFILE, response.data.data.profileId)
            }
            //resolve()

            await new Promise<void>((resolve2, reject) => {
              _srvAuth.whoAmI()
                .then((response: any) => {
                  var user = response
                  this.context.commit(Mutations.WHOAMI, [this, user])
                  resolve2()
                  resolve()
                })
                .catch((e) => {
                  reject(e.data.Message)
                })
            })

          }
        })
        .catch((e) => {
          reject(e)
        })
    })
  }


  @Action({ rawError: true })
  async [Actions.AUTOAUTH](params: any) {
    const request: autoAuthRequest = params[0].value
    const container: Container = params[1]
    await new Promise<void>((resolve, reject) => {
      const _srvAuth = container.get<IServiceAuth>(TYPES.AUTH_SERVICE)
      _srvAuth.autoAuth(request)
        .then(async (response: any) => {
          if (response.data.failed === true) {
            //MessageService.showMessage(MessageType.Error, 'Error de validación', response.data.message)
            reject()
          } else {
            this.context.commit(Mutations.SET_AUTH, response.data.data)
            // Siempre es externo el autoauth
            //if (request.brokerId != null && request.external == false) { // pq el whoami pone siempre el perfil principal de la correduría, al cambiar a otro perfil pondremos el que se selecciona
            //  this.context.commit(Mutations.SETPROFILE, request.profileId)
            //}
            //if (request.brokerId != null && request.external == true) {
              this.context.commit(Mutations.SETPROFILE, response.data.data.profileId)
            //}
            //resolve()

            await new Promise<void>((resolve2, reject) => {
              _srvAuth.whoAmI()
                .then((response: any) => {
                  var user = response
                  this.context.commit(Mutations.WHOAMI, [this, user])
                  resolve2()
                  resolve()
                })
                .catch((e) => {
                  reject(e.data.Message)
                })
            })

          }
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.DECRYPT](params: any) {
    
    if (params[0]) {
      const request: string = params[0].value
      const container: Container = params[1]
      await new Promise<void>((resolve, reject) => {
        const _srv = container.get<IServiceEncrypt>(TYPES.ENCRYPT_SERVICE)
        _srv.decrypt(request)
          .then(async (response: any) => {
            
            if (response.data.failed === true) {
              //MessageService.showMessage(MessageType.Error, 'Error de validación', response.data.message)
              reject();
            } else {
              resolve();

            }
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }

  @Action({ rawError: true })
  async [Actions.SENDMAIL](params: any) {
    
    if (params[0]) {
      const userId: number = params[0]
      const to: string = params[1]
      const cc: string = params[2]
      const subject: string = params[3]
      const body: string = params[4]
      const attachments: [uploadFile] = params[5]
      const container: Container = params[6]

      return await new Promise<boolean>((resolve, reject) => {
        const _srv = container.get<IServiceDobleAuthenticationUser>(TYPES.DOBLE_FACTOR_USER_SERVICE)
        _srv.sendMail(userId, to, cc, subject, body, attachments)
          .then(async (response: any) => {
            
            resolve(response)
          })
          .catch(() => {
            
            resolve(false)
          })
      })
    }
  }


  @Action({ rawError: true })
  async [Actions.FORGOT_PASSWORD](params: any) {
    const email: string = params[0]
    const microsoftEmail: string | null = params[1]
    const googleEmail: string | null = params[2]
    const applicationId: string = params[3]
    const applicationVersion: string = params[4]
    const container: Container = params[5]

    const request: authRequest = {
      grant_type: 'resetPass',
      username: email,
      password: '',
      microsoftEmail: microsoftEmail,
      googleEmail: googleEmail,
      client_id: '',
      client_secret: '',
      refresh_token: '',
      brokerId: -1,
      profileId: '',
      applicationId: parseInt(applicationId ?? '-1'),
      applicationVersion: parseInt(applicationVersion ?? '-1'),
      external: false ,
      sessionId:''
    };

    await new Promise<void>((resolve, reject) => {
      const _srv = container.get<IServiceAuth>(TYPES.AUTH_SERVICE)
      _srv.resetPass(request)
        .then((response: any) => {
           if (response.data.failed === true) {
             MessageService.showMessage(MessageType.Error, 'Error de validación', response.data.message)
           } else {
             //this.context.commit(Mutations.SET_AUTH, response.data.data)
           }

          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.LINK_MICROSOFTACCOUNT](params: any) {
    const email: string = params[0]
    const microsoftEmail: string | null = params[1]
    const googleEmail: string | null = params[2]
    const applicationId: string = params[3]
    const applicationVersion: string = params[4]
    const container: Container = params[5]

    const request: authRequest = {
      grant_type: 'setMicrosoftAccount',
      username: email,
      password: '',
      microsoftEmail: microsoftEmail,
      googleEmail: googleEmail,
      client_id: '',
      client_secret: '',
      refresh_token: '',
      brokerId: -1,
      profileId: '',
      applicationId: parseInt(applicationId ?? '-1'),
      applicationVersion: parseInt(applicationVersion ?? '-1'),
      external: false ,
      sessionId:''
    };

    await new Promise<void>((resolve, reject) => {
      const _srv = container.get<IServiceAuth>(TYPES.AUTH_SERVICE)
      _srv.setMicrosoftAccount(request)
        .then((response: any) => {
           if (response.data.failed === true) {
             MessageService.showMessage(MessageType.Error, 'Error al vincular la cuenta', response.data.message)
           } else {
             //this.context.commit(Mutations.SET_AUTH, response.data.data)
           }

          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.RESET_PASSWORD](params: any) {
    const password: string = params[0]
    const requestId: string = params[1]
    const applicationId: string = params[2]
    const applicationVersion: string = params[3]
    const container: Container = params[4]

    const request: authRequest = {
      grant_type: 'setPassword',
      username: '',
      microsoftEmail: null,
      googleEmail: null,
      password: password,
      client_id: requestId,
      client_secret: '',
      refresh_token: '',
      brokerId: -1,
      profileId: '',
      applicationId: parseInt(applicationId ?? '-1'),
      applicationVersion: parseInt(applicationVersion ?? '-1'),
      external: false ,
      sessionId:''
    };

    await new Promise<void>((resolve, reject) => {
      const _srv = container.get<IServiceAuth>(TYPES.AUTH_SERVICE)
      _srv.setPassword(request)
        .then((response: any) => {
           if (response.data.failed === true) {
             MessageService.showMessage(MessageType.Error, 'Error de validación', response.data.message)
           } else {
             //MessageService.showMessage(MessageType.Correcto, '', 'Ha sido modificada la contraseña de la cuenta en nuestro sistema correctamente.');
           }

          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.RESET_PASSWORD_MANUAL](params: any) {
    const email: string = params[0]
    const password: string = params[1]
    const oldPassword: string = params[2]
    const type: string = params[3]
    const applicationId: string = params[4]
    const applicationVersion: string = params[5]
    const container: Container = params[6]

    const request: authRequest = {
      grant_type: type,
      username: email,
      password: password,
      microsoftEmail: null,
      googleEmail: null,
      client_id: oldPassword,
      client_secret: '',
      refresh_token: '',
      brokerId: -1,
      profileId: '',
      applicationId: parseInt(applicationId ?? '-1'),
      applicationVersion: parseInt(applicationVersion ?? '-1'),
      external: false ,
      sessionId:''
    };

    await new Promise<void>((resolve, reject) => {
      const _srv = container.get<IServiceAuth>(TYPES.AUTH_SERVICE)
      
      _srv.setPasswordManual(request)
        .then((response: any) => {
           if (response.data.failed === true) {
             MessageService.showMessage(MessageType.Error, 'Error de validación', response.data.message)
           } else {
             MessageService.showMessage(MessageType.Correcto, '', 'Ha sido modificada la contraseña de la cuenta en nuestro sistema correctamente.');
           }

          resolve()
        })
        .catch(() => {
          reject()
        })
    })
    
  }


  @Action({ rawError: true })
  async [Actions.SET_DOBLE_FACTOR_USER_CODE](params: any) {
    const applicationId: number = params[0]
    const applicationVersion: number = params[1]
    const userId: number = params[2]
    const dobleFactorType: string = params[3]
    const container: Container = params[4]

    const request: dobleAuthenticationRequest = {
      userId: userId,
      applicationId: applicationId,
      applicationVersion: applicationVersion,
      dobleFactorType: dobleFactorType
    };

    await new Promise<void>((resolve, reject) => {
      const _srv = container.get<IServiceAuth>(TYPES.AUTH_SERVICE)
      _srv.setDobleFactorUserCode(request)
        .then((response: any) => {
          /* if (response.data.failed === true) {
             MessageService.showMessage(MessageType.Error, 'Error de validación', response.data.message)
           } else {
             this.context.commit(Mutations.SET_AUTH, response.data.data)
           }*/
           this.context.commit(Mutations.SET_DF_CODE, response)
          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.CONFIRM_DOBLE_FACTOR_USER](params: any) {
    const applicationId: number = params[0]
    const applicationVersion: number = params[1]
    const userId: number = params[2]
    const container: Container = params[3]

    const request: dobleAuthenticationRequest = {
      userId: userId,
      applicationId: applicationId,
      applicationVersion: applicationVersion,
      dobleFactorType: ''
    };

    await new Promise<void>((resolve, reject) => {
      const _srv = container.get<IServiceAuth>(TYPES.AUTH_SERVICE)
      _srv.confirmDobleFactorUser(request)
        .then((response: any) => {
          /* if (response.data.failed === true) {
             MessageService.showMessage(MessageType.Error, 'Error de validación', response.data.message)
           } else {
             this.context.commit(Mutations.SET_AUTH, response.data.data)
           }*/
           this.context.commit(Mutations.SET_DF_CODE, response)
          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.CHANGEPROFILE](profileId: number) {
    this.context.commit(Mutations.SETPROFILE, profileId)
  }


  @Mutation
  async [Mutations.SETVERSION](version: string) {
    this.app_version = version
  }

  
  @Mutation
  async [Mutations.SET_ENTITYMAIN_CFG](data: IapmoConfiguration[]) {
    this.entityMainConfig = data
  }

  @Mutation
  async [Mutations.WHOAMI](params: any) {
    const _this = params[0]
    const user: IapmUser = params[1]
    _this.context.commit(Mutations.SET_USER, user)
  }

  @Mutation
  async [Mutations.SETPROFILE](profileId: number) {
    this.currentProfileId = +profileId
  }


  @Mutation
  [Mutations.SET_USER](data: any) {
    

    const _user = ({
      id: data.data.user.id,
      applicationId: data.data.user.applicationId,
      applicationVersion: data.data.user.applicationVersion,
      userName: data.data.user.userName,
      fullName: data.data.user.fullName, 
      enabled: data.data.user.enabled, 
      isAdmin: data.data.user.isAdmin, 
      lastConnection: data.data.user.lastConnection, 
      entityMainId: data.data.user.entityMainId, 
      entityMainCurrentId: data.data.user.entityMainCurrentId, 
      locked: data.data.user.locked, 
      loginTries: data.data.user.loginTries, 
      lastLoginTryDate: data.data.user.lastLoginTryDate, 
      languageId: data.data.user.languageId, 
      multiFactorAuthenticationId: data.data.user.multiFactorAuthenticationId, 
      iapmUserEntityMains: data.data.user.iapmUserEntityMains,
      iapmUserOptions: data.data.user.userOptions,
      language: data.data.user.language,
      phone: data.data.user.phone,
      microsoftAccount: data.data.user.microsoftAccount,
      googleAccount: data.data.user.googleAccount,
      iapmUserEntityMain: data.data.user.iapmUserEntityMain, 
      entityMains: data.data.user.entityMains, 
       multiFactorAuthentication: data.data.user.multiFactorAuthentication, 
       userEntityGroups : data.data.userEntityGroups,
       groups : data.data.groups
    })

    this.user = _user as any ;//data.data.user;
    this.currentProfileType = data.data.perfil?.profileId;
    this.currentProfileId = data.data.perfil?.id ?? 0;    
  }

  @Mutation
  async [Mutations.SET_MICROSOFTACCOUNT](microsoftAccount: string) {
    this.user.microsoftAccount = microsoftAccount 
  }

  @Mutation
  async [Mutations.SET_GOOGLEACCOUNT](googleAccount: string) {
    this.user.googleAccount = googleAccount 
  }

  @Mutation
  [Mutations.SET_DF_CODE](response: any) {
    this.dobleFactorCode = response.data.data.userMultifactorAuthentication.code,
    this.dobleFactorCodeFechaCaducidad = response.data.data.userMultifactorAuthentication.expireDateCode,
    this.dobleFactorFechaCaducidad = response.data.data.userMultifactorAuthentication.expireDateMultiFactor
  }

  @Mutation
  [Mutations.SET_AUTH](data: any) {   
    this.access_token = data.access_token.toString()
    this.expires_in = data.expires_in.toString()
    this.refresh_token = data.refresh_token.toString()
    this.user.entityMainCurrentId = data.brokerId
    this.external_session = data.external_session?.toString() ?? ''
  }

  @Mutation
  [Mutations.SET_NOAUTH](data: any) {
    this.isAuthenticated = false;
    this.access_token = '';
  }

  @Mutation
  [Mutations.SET_AUTHCOMPLETE](data: any) {
    this.isAuthenticated = true;
  }

  @Mutation
  [Mutations.SET_MENU](params: any) {
    const menus: never[] = params[0]
    const localizations: any = params[1]
    this.menusApp = menus
    this.menusLocalizations = localizations
  }

  @Mutation
  [Mutations.SET_ROUTES](routes: vueRouter.Router) {
    this.routes = true; // routes.getRoutes()
  }

  @Mutation
  async [Mutations.SET_APPCONFIG](cfgData: IapApplicationConfiguration[]) {
    this.appConfig = cfgData    
  }

  @Mutation
  async [Mutations.SET_TOKEN](token: string) {
    this.access_token = token 
  }


  @Mutation
  async [Mutations.SET_APPLAYOUTCONFIG](cfgData: any) {
    this.appLayoutConfig = cfgData    
  }

  @Action({ rawError: true })
  async [Actions.SET_ROUTES](routes: any) {
    this.context.commit(Mutations.SET_ROUTES, routes)
  }

  @Action({ rawError: true })
  async [Actions.SET_MENU](params: any) {
    const applicationId: number = params[0]
    const applicationVersion: number = params[1]
    const container: Container = params[2]

    await new Promise<CustomMenu[] | void>((resolve, reject) => {
      if (container){
        const _srvMenu = container.get<IServiceMenu>(TYPES.MENU_SERVICE)
        _srvMenu.getByApplicationId(this.user?.isAdmin ? 1 : 0, this.currentProfileId ?? 0, applicationId, applicationVersion)
          .then((response) => {
            HelperMenu.addMenuBuilder(this.user,applicationId, applicationVersion,response);
            const menus  = HelperMenu.buildMenu(response);
            const localizations = response.filter(x => x.localizations && x.localizations.length > 0).map(x => x.localizations)

            let messages = buildLocalizations(localizations.flat() as any);

            this.context.commit(Mutations.SET_MENU, [menus, messages])
            resolve()
          })
          .catch(() => {
            
            reject()
          })
      }
      else{
        resolve([])
      }

    })

    function buildLocalizations(localizations: IapLocalization[]) {
      let response = {
        locale: 'es',
        messages: {}
      };
    
      let distinctCultures = localizations.map(item => item.idCulture.split('-').pop()).filter((value, index, self) => self.indexOf(value) === index);
      let distinctMenus = localizations.map(item => item.objetoId).filter((value, index, self) => self.indexOf(value) === index);
    
      distinctCultures.forEach(c => {
        const objectCulture = {};
        const myCultureName = c;
    
        localizations.filter(l => l.idCulture.split('-').pop() == c).forEach(l => {
          const objectMenu = {};
          const myMenuName = l.objetoId;
          distinctMenus.forEach(m => {
            Object.assign(objectMenu, { label: l.title });
            Object.assign(objectMenu, { description: l.description });
          });
          Object.assign(objectCulture, { [myMenuName]: objectMenu });
          
        });
        Object.assign(response.messages, { [myCultureName as any]: objectCulture });
      });
            return response;
    }
  }

  

  @Mutation
  [Mutations.PURGE_AUTH]() { 
       
    this.access_token = ''
    this.external_session='';
    this.expires_in = ''
    this.refresh_token = ''
    this.dobleFactorCode = ''
    this.dobleFactorFechaCaducidad = ''
    this.dobleFactorCodeFechaCaducidad = ''
    this.isAuthenticated = false;
    this.currentProfileId = null;
    this.currentProfileId = null;
    this.user = {} as IapmUser;
    this.menusApp = [];
    this.routes = false;
    this.appConfig = [];
    this.appLayoutConfig = {} as AppLayoutConfig;
    this.menusLocalizations = {};
    this.app_version='';
    this.entityMainConfig = []
    LocalService.destroyAll();
    CookieService.destroyAll();
  }



  @Mutation
  [Mutations.SET_CLIENTCOMPUTERNAME]() {
    /*if (!this.computerName)
    {
      
      //const computerName = require('computer-name')
      //this.computerName = computerName()
    }
    */
  }


  @Action({rawError: true})
  [Actions.LOGOUT]() {
    return new Promise<void>((resolve) => {
      this.context.commit(Mutations.PURGE_AUTH);
      resolve();
    });
  }


  get menuUser(): any {
    // const user = this._user
    const menus = this.menusApp

    // return function () {
    // return HelperSecurity.buildUserMenu(user, menus)
    // }
    const data: menu[] = []

    menus.forEach(function (value: any) {
      // const itemsFiltered = value.items.filter((x) => {
      // return checkAccess(x.builtin, x.contrato, x.directivas, user)
      // })

      // if (itemsFiltered.length > 0) {
      // value.items = itemsFiltered
      data.push(value)
      // }
    })

    return data
  }

}
