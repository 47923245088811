import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pi pi-chevron-down"
}
const _hoisted_2 = {
  key: 1,
  class: "pi pi-chevron-up"
}

export function render(_ctx, _cache) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")
  const _component_FormBuilder = _resolveComponent("FormBuilder")
  const _component_Panel = _resolveComponent("Panel")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.VISIBLE,_ctx.Component) && _ctx.canDoOperation(_ctx.ObjectGroupConst.VIEW,_ctx.Component))
      ? _withDirectives((_openBlock(), _createBlock(_component_Panel, {
          key: _ctx.componentKey,
          id: _ctx.getPropertyValue(_ctx.PanelTypeConst.ID,_ctx.Component),
          style: _normalizeStyle(_ctx.getPropertyValue(_ctx.PanelTypeConst.STYLE,_ctx.Component)),
          class: _normalizeClass(_ctx.getCompClass(_ctx.Component)),
          name: _ctx.getPropertyValue(_ctx.PanelTypeConst.NAME,_ctx.Component),
          header: _ctx.getPropertyValue(_ctx.PanelTypeConst.HEADER,_ctx.Component),
          toggleable: _ctx.getPropertyBooleanValue(_ctx.PanelTypeConst.TOGGLEABLE,_ctx.Component),
          collapsed: _ctx.collapsed,
          pt: _ctx.pt,
          modelValue: _ctx.vmodel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.vmodel) = $event)),
          onToggle: _ctx.toggle
        }, {
          icons: _withCtx(() => [
            _renderSlot(_ctx.$slots, "icons")
          ]),
          togglericon: _withCtx((data) => [
            (data.collapsed)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1))
              : (_openBlock(), _createElementBlock("span", _hoisted_2))
          ]),
          header: _withCtx(() => [
            _renderSlot(_ctx.$slots, "header")
          ]),
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "content", {}, () => [
              (_ctx.formBuildComp>0 && (_ctx.getPropertyBooleanValue(_ctx.PanelTypeConst.TOGGLEABLE) == false || (
    _ctx.getPropertyBooleanValue(_ctx.PanelTypeConst.TOGGLEABLE) == true && _ctx.collapsed == false

 ) ) )
                ? (_openBlock(), _createBlock(_component_FormBuilder, {
                    key: 0,
                    container: _ctx.container,
                    componentId: _ctx.formBuildComp,
                    applicationId: _ctx.Component.applicationId,
                    applicationVersion: _ctx.Component.applicationVersion,
                    attrsInput: _ctx.attrsInput
                  }, null, 8 /* PROPS */, ["container", "componentId", "applicationId", "applicationVersion", "attrsInput"]))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["id", "style", "class", "name", "header", "toggleable", "collapsed", "pt", "modelValue", "onToggle"])), [
          [_directive_tooltip, _ctx.getPropertyValue(_ctx.PanelTypeConst.TOOLTIP,_ctx.Component)]
        ])
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}