export default class CacheConst {
    static ALL = 'all'
    static COMPONENT = 'comp'
    static ONECOMPONENT = 'onecomp'
    static MENUS = 'menu'
    static CATALOGS = 'cat'
    static ALLIMAGES = 'allimages'
    static ONEIMG = 'oneimage'
    static CSS = 'css'
  }
  