import BaseControlTypeConst from "./BaseControlTypeConst"

export default class ImageTypeConst extends BaseControlTypeConst {
    
  static SRC = 'src'
  static ALT = 'alt'
  static WIDTH = 'width'
  static HEIGHT = 'height'

  static IMG_FORMAT = 'imageFormat'
  static CAN_EDIT = 'canEdit'
  static CAN_DEL = 'canDelete'
  static DS_FIELD_DATA = 'dataSourceFieldData'
  static DS_FIELD_ID = 'dataSourceFieldId'
  static SERVER_PATH = 'serverPath'
  static IMG_SIZE = 'maxImageSize'

}
