<SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
:data="Component"
:vmodel="vmodel"
/>
<Panel v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE,Component) && canDoOperation(ObjectGroupConst.VIEW,Component)" 
:key="componentKey"
:id="getPropertyValue(PanelTypeConst.ID,Component)" v-tooltip="getPropertyValue(PanelTypeConst.TOOLTIP,Component)"
:style="getPropertyValue(PanelTypeConst.STYLE,Component)" :class="getCompClass(Component)"
:name="getPropertyValue(PanelTypeConst.NAME,Component)" :header="getPropertyValue(PanelTypeConst.HEADER,Component)" 
:toggleable="getPropertyBooleanValue(PanelTypeConst.TOGGLEABLE,Component)"
:collapsed="collapsed" :pt="pt" v-model="vmodel"    
@toggle="toggle">
<template #icons>
<slot name="icons"></slot>
</template>
<template #togglericon="data">
<span v-if="data.collapsed" class="pi pi-chevron-down"></span>
<span v-else class="pi pi-chevron-up"></span>
</template>
<template #header>
<slot name="header"></slot>
</template>

<!-- <template #footer>
<slot name="footer"></slot>
</template> -->

<slot name="content">

<FormBuilder v-if="formBuildComp>0 && (getPropertyBooleanValue(PanelTypeConst.TOGGLEABLE) == false || (
    getPropertyBooleanValue(PanelTypeConst.TOGGLEABLE) == true && collapsed == false

 ) ) "  :container="container" :componentId="formBuildComp" :applicationId="Component.applicationId"
:applicationVersion="Component.applicationVersion" :attrsInput="attrsInput" />                

</slot>



</Panel>