export default class CatalogConfigurationConst {
 
    static COLOR1='appconfig-color1'
    static COLOR2='appconfig-color2'
    static LANGUAGE='appconfig-language'
    static LOGOPNG='appconfig-logopng'
    static LOGOSVG='appconfig-logosvg'
    static TEMPLATE='appconfig-template'
    static FAVICON='appconfig-favicon'
    static TEMPLATETHEME='appconfig-template-theme'
    static TEMPLATETHEMESTYLE='appconfig-template-theme-style'
    static TEMPLATECONFIG='appconfig-template-config'
    static TEMPLATECONFIGSIMPLE='appconfig-template-config-simple'
    static URLREPORTLAUNCHER='appconfig-template-urlreportlauncher'
    static ISAPPEXTERNAL='appconfig-isapp-external'
    static SIGNINWITH='appconfig-signinwith'
    static SIGNINWITHGOOGLE='appconfig-signinwith-google'
    static SIGNINWITHMICROSOFT='appconfig-signinwith-microsoft'
    static FOOTER='appconfig-footer'
    static RENDER='appconfig-render'
    static COMP_LAYOUT='appconfig-componentlayout'
    static TITLE = 'appconfig-title';
    static VERSION = 'appconfig-version';
    static CUSTOMCSS='appconfig-customcss';

    static PAGE_INIT='appconfig-initialurlpage';

    static MICROSOFT_APPID = 'appconfig-ms-appid';
    static MICROSOFT_TENANTID = 'appconfig-ms-tenantid';
    static MICROSOFT_URL = 'appconfig-ms-urlredirect';
    static GOOGLE_APPID = 'appconfig-gg-appid';


    }
    