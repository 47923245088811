import { computed,ref } from "vue";
import CatalogCfgUbmChatConst from "../../../../catalog/domain/const/CatalogCfgUbmChatConst";
import InputTextTypeConst from "../../../domain/Constants/InputTextTypeConst";
import HelperCommon from "@ilinium/shared/src/common/infrastructure/funciones/HelperCommon";

export default function DynamicInputTextFunc(store:any,vmodel:any,getPropertyBooleanValue:any,sendUbmChat:any,sendMail:any) {


    const menu = ref();
    const vmodelRef = computed(() => { return  vmodel.value });

    const getItems = computed(() => {
        const items = [
            {
                label: 'Copiar',
                icon: 'pi pi-copy',
                visible: getPropertyBooleanValue(InputTextTypeConst.COPYTOCLIPBOARDB),
                command: () => {
                    HelperCommon.copyToClipBoard(vmodelRef.value);
                }
            },
            {
                label: 'Whatsapp',
                icon: 'pi pi-whatsapp',
                visible: getPropertyBooleanValue(InputTextTypeConst.CHAT) && hasUbmChat.value,
                command: () => {
                    sendUbmChat();
                }
            },
            {
                label: 'Correo',
                icon: 'pi pi-envelope',
                visible: getPropertyBooleanValue(InputTextTypeConst.EMAIL),
                command: () => {
                    sendMail();
                }
            },

        ];

        return items.filter(x => x.visible);

    })

    const toggle = (event: Event) => {
        if (getItems.value.length > 1){
            menu.value.toggle(event);
            event.stopPropagation();
        }
        
    };


    const hasUbmChat = computed(() => {
        
        return store.getters.entityMainCfgValue(CatalogCfgUbmChatConst.CFGUBMCHAT_ENABLE)?.toLowerCase().trim() == 'true'
    })

    const hasGroupControls = computed(() => {
        return getPropertyBooleanValue(InputTextTypeConst.MIC) || getPropertyBooleanValue(InputTextTypeConst.COPYTOCLIPBOARDB)
            || (getPropertyBooleanValue(InputTextTypeConst.CHAT) && hasUbmChat.value) || getPropertyBooleanValue(InputTextTypeConst.EMAIL)
    })

    const baseITOnBeforeUnmount = () => {
        menu.value=null;
    }
        
     
  
    return {
        
        getItems,
        toggle,
        menu,
        hasGroupControls,
        hasUbmChat,
        baseITOnBeforeUnmount
    };
  
  }
  

