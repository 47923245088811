export default class CatalogCommandConst {
        static CMD_CONDICION='cmd-condicion'
        static CMD_MSG='cmd-msg'
        static CMD_REDIRECT='cmd-redirect'
        static CMD_SAVE='cmd-save'
        static CMD_SETVALUE='cmd-setvalue'
        static CMD_START='cmd-start'
        static CMD_SELECTDATASOURCE="cmd-selectds"
        static DELAY="cmd-delay"
        static EXEC_PROC="cmd-proc"
        static EXEC_SHOWFORM="cmd-frm"    
        static CMD_CHANGECOMP="cmd-changefrm"    
        static CMD_VALIDATECOMP="cmd-validatefrm"    
        static CMD_TEXT='cmd-text'
        static CMD_END='cmd-end'
        static CMD_UBM ='cmd-chatubm'
        static CMD_SENDMAIL = 'cmd-sendmail'
        static CMD_MSG_HIDE='cmd-msgh'
        static CMD_ENCRYPT='cmd-encrypt'
        static CMD_KEYGEN='cmd-keygen'
        static CMD_HASH='cmd-hash'
        static CMD_SWITCH='cmd-switch'
        static CMD_CACHE='cmd-cache'
}
    