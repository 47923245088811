import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { TYPES } from '../../../../common/domain/types'
import { IServiceSearch } from './IServiceSearch'
import type { ISearchRepository } from '../domain/ISearchRepository'
import { ResultadSearch } from '../domain/resultSearch'
import {  ProcData,GroupSearch, LazyParams } from '../domain/search'
import { IapComponentDataSource } from '../../component/domain/iapComponentDataSource';
import { SearchMultiple } from '../domain/searchMultiple'




@injectable()
export class SearchService implements IServiceSearch {
  private readonly searchRepo: ISearchRepository

  public constructor (@inject(TYPES.SEARCH_REPOSITORY) _gestRepo: ISearchRepository) {
    this.searchRepo = _gestRepo
  }

  async search (applicationId: number, applicationversion: number, componentId: number, requestData: GroupSearch, parameters: any[], maxregs: number,componentDataSource?: IapComponentDataSource,dataSourceId?: number,count?:boolean,lazyParams?:LazyParams): (Promise<ResultadSearch>) {
    return await this.searchRepo.search(applicationId, applicationversion, componentId, requestData, parameters, maxregs, componentDataSource,dataSourceId,count,lazyParams);
  }

  async searchMultiple (data:SearchMultiple[]): (Promise<ResultadSearch[]>) {
    return await this.searchRepo.searchMultiple(data);
  }

   
  async searchLookUp (applicationId:number,applicationversion:number,lookUpId:number,requestData: GroupSearch, maxregs : number,where?:string): (Promise<ResultadSearch>) {
    return await this.searchRepo.searchLookUp(applicationId,applicationversion,lookUpId,requestData,maxregs,where);
  }


  async searchTablesViews (applicationId:number,applicationversion:number, databaseId: string,databaseTypeId:string): (Promise<ResultadSearch>) {
    return await this.searchRepo.searchTablesViews(applicationId,applicationversion, databaseId,databaseTypeId);
  }
  
  async searchFieldsTable (applicationId:number,applicationversion:number,databaseTypeId:string, databaseId:string, tableNames:string[]): (Promise<ResultadSearch>) {
    return await this.searchRepo.searchFieldsTable(applicationId,applicationversion,databaseTypeId,databaseId,tableNames);
  }

  async getPreviewData (applicationId:number,applicationversion:number,databaseTypeId:string, databaseId:string, querySelect:string, queryFrom:string, queryWhere:string, queryOrderBy:string, queryGroupBy:string, queryHaving:string, maxRegs:number): (Promise<ResultadSearch>) {
    return await this.searchRepo.getPreviewData(applicationId,applicationversion,databaseTypeId, databaseId, querySelect, queryFrom, queryWhere, queryOrderBy, queryGroupBy, queryHaving, maxRegs);
  }

  async searchProceduresAndFunctions (applicationId:number,applicationversion:number, databaseId: string,databaseTypeId:string): (Promise<ResultadSearch>) {
    return await this.searchRepo.searchProceduresAndFunctions(applicationId,applicationversion, databaseId,databaseTypeId);
  }
  
  async executeProcedureOrFunction (applicationId:number,applicationversion:number, databaseId: string,databaseTypeId:string,requestData: ProcData[]) : Promise<ResultadSearch>{
    return await this.executeProcedureOrFunction(applicationId,applicationversion, databaseId,databaseTypeId,requestData);
  }

}
