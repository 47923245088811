<template  src="./DynamicInputText.html">
</template>
<script lang="ts">


import { defineComponent, onMounted, onBeforeUnmount } from 'vue';
import ComponentCommonRender from '../../../../../domain/Functions/ComponentCommonRender';
import InputTextTypeConst from '../../../../../domain/Constants/InputTextTypeConst';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../../shared/CustomValidate.vue';
import HelperCommon from '../../../../../../../../common/infrastructure/funciones/HelperCommon';
import Mic from '../../../../../../../../common/infrastructure/componentes/base/common/mic/Mic.vue';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../../shared/SummaryDebug.vue';
import CatalogEventConst from '../../../../../../catalog/domain/const/CatalogEventConst';
import DynamicInputTextFunc from '../../../Functions/DynamicInputTextFunc';


export default defineComponent({
    name: 'dynamic_input-text',
    components: {
        CustomValidate,
        Mic,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        slotProps: {
            type: Object,
            default: () => ({})
        },
    },
    setup(props) {
        

        const store = useStore();
        const { vmodel, canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, baseOnMounted, baseOnBeforeUnmount, v$, submitted, canEdit, isValidData
            , fieldKey, fieldKeyComponentDataSourceId, sendUbmChat, sendMail,getCompClass, componentKey,processEventComponent
        } = ComponentCommonRender(props.Component, props.slotProps, props.container, store);


        const {getItems,toggle, menu,hasGroupControls,hasUbmChat,baseITOnBeforeUnmount} = DynamicInputTextFunc( store,vmodel,getPropertyBooleanValue,sendUbmChat,sendMail)


        onMounted(() => {
            baseOnMounted();
        })

        onBeforeUnmount(() => {
            baseOnBeforeUnmount();
            baseITOnBeforeUnmount();
        })


        return {

            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            InputTextTypeConst,
            loaded,
            BaseControlTypeConst,
            v$,
            submitted,
            canEdit,
            hasGroupControls,
            HelperCommon,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            , canDoOperation
            , hasUbmChat
            , sendUbmChat
            , sendMail
            , componentKey
            , getItems
            , menu
            , toggle
            ,getCompClass
            ,processEventComponent
            ,CatalogEventConst

        };
    },
});
</script>
<style scoped></style>
