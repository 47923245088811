<SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
:data="Component"
:vmodel="vmodel"
/>
<h4 v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE,Component) && canDoOperation(ObjectGroupConst.VIEW,Component)" 
:key="componentKey" :id="getPropertyValue(HtmlTypeConst.ID,Component)" v-tooltip="getPropertyValue(HtmlTypeConst.TOOLTIP,Component)"
:style="getPropertyValue(HtmlTypeConst.STYLE,Component)" :class="getCompClass(Component)" :name="getPropertyValue(HtmlTypeConst.NAME,Component)" >
{{ getPropertyValue(HtmlTypeConst.TEXT,Component) }}
{{ vmodel }}
</h4>

