/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */

import ComponentDataForm from "../../../designer/domain/ComponentDataForm";

import HelperUtils from "../../../../../common/infrastructure/funciones/HelperUtils";

import { Dictionary } from "../../../expression/domain/dictionary";
import { IapComponentAttribute } from '../../../component/domain/iapComponentAttribute';
import BaseControlTypeConst from "../Constants/BaseControlTypeConst";
import CatalogObjectTypeConst from "../../../catalog/domain/const/CatalogObjectTypeConst";
import CatalogExpConst from "../../../catalog/domain/const/CatalogExpConst";
import { ExpresionEngine } from "../../../expression/infrastructure/helper/expressionEngine";
import { Store } from "vuex";
import { IapExpression } from '../../../expression/domain/iapExpression';
import ExpressionNomenclatorConst from "../../../expression/domain/const/ExpressionNomenclatorConst";
import HtmlTypeConst from "../Constants/HtmlTypeConst";
import HelperSecurity from "@ilinium/shared/src/common/infrastructure/funciones/HelperSecurity";
import CatalogTypeConst from "@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogTypeConst";
import { IapComponentEvent } from "../../../component/domain/iapComponentEvent";
import { MessageType } from "../../../../../common/infrastructure/servicios/MessageService";
import HelperLoading from "../../../../../common/infrastructure/funciones/HelperLoading";
import EventConst from "../../../../../common/domain/constantes/EventConst";
import { IapWorkFlow } from "../../../workflow/domain/service/iapWorkFlow";
import EventBusCustom from '../../../../../common/infrastructure/event-bus-custom';
import { InteractionEvent } from "../../../interaction/domain/interactionEvent";
import { IapComponent } from "../../../component/domain/iapComponent";
import { LocalService, MessageService } from "../../../../../common/infrastructure/servicios";
import { useRouter } from 'vue-router'
import InteractionConst from "../../../interaction/domain/interactionConst";
import ObjectGroupConst from "@ilinium/shared/src/common/domain/constantes/ObjectGroupConst";
import { executeWorkFlow } from "../../../workflow/infrastructure/component/rete/rete/editor";
import { Container } from "inversify";
import helperCatalog from "../../../catalog/infrastructure/helper/helperCatalog";

export default function ComponentHelperRender(
  Component: ComponentDataForm,
  slotProps: any,
  store: Store<any>,
  container?: Container | undefined) {

  //const Component: ComponentDataForm = props.Component ?? props.data;
  const iapComponentAttributesRepeater: Dictionary<number, IapComponentAttribute>[] = ([]);
  const user = store?.getters.getCurrentUser;
  const router = useRouter();


  const getRepeaterCurrentIndex = () => {
    const index = slotProps?.slotProps?.index ?? -1

    return index;
  }


  const getPropertyValueOption = (propertyName: string, options: any, component = Component) => {

    try {
      const valueProp = getPropertyValue(propertyName, component) ?? '';
      const outPut = Object.keys(options.find((x: any) => x !== undefined)).find(x => x.toLowerCase().endsWith(valueProp.toLowerCase()))

      return outPut ?? valueProp ?? null;

    } catch {
      return null
    }

  };


  const getProperty = (propertyName: string, component = Component) => {
    return component.iapComponentAttributes.find((x) => x.name == propertyName);
  };



  const getLayoutComponent = (Component: ComponentDataForm, idType: string) => {
    return Component.get(Component.id, 'children').find((x: any) => x.idType == idType)
  }

  const getContentComponent = (Component: ComponentDataForm) => {
    return Component.get(Component.id, 'children').find((x: any) => !x.idType.startsWith('layouttemp'))
  }

  const getAllLayoutComponent = (Component: ComponentDataForm, idType: string) => {
    return Component.get(Component.id, 'children').filter((x: any) => x.idType == idType)
  }


  const isLayoutComponent = (Component: ComponentDataForm) => {
    return Component.idType.toLocaleLowerCase().startsWith('layouttemp')
  }

  const getPropertyValue = (propertyName: string, component = Component) => {
    const curInd = getRepeaterCurrentIndex()
    if (curInd > 0 && iapComponentAttributesRepeater.length > 0 && iapComponentAttributesRepeater.filter(x => x.key == curInd).flatMap(x => x.value).find((x) => x.name == propertyName)) {
      const attrcpy = iapComponentAttributesRepeater.filter(x => x.key == curInd).flatMap(x => x.value).find((x) => x.name == propertyName)
        ?.value;
      return attrcpy;
    }

    return component.iapComponentAttributes.find((x) => x.name == propertyName)
      ?.value;
  };



  const getPropertyNumberValue = (propertyName: string, component = Component) => {
    const curInd = getRepeaterCurrentIndex()
    if (curInd > 0 && iapComponentAttributesRepeater.length > 0 && iapComponentAttributesRepeater.filter(x => x.key == curInd).flatMap(x => x.value).find((x) => x.name == propertyName)) {
      const attrcpy = iapComponentAttributesRepeater.filter(x => x.key == curInd).flatMap(x => x.value).find((x) => x.name == propertyName)
        ?.value;
      return Number(attrcpy ?? "0");
    }
    return Number(
      component.iapComponentAttributes.find((x) => x.name == propertyName)
        ?.value ?? "0"
    );
  };

  const getPropertyBooleanValue = (propertyName: string, component = Component, onlyAttr = false) => {

    if (!onlyAttr) {
      const curInd = getRepeaterCurrentIndex()
      if (curInd > 0 && iapComponentAttributesRepeater.length > 0 && iapComponentAttributesRepeater.filter(x => x.key == curInd).flatMap(x => x.value).find((x) => x.name == propertyName)) {
        const attrcpy = iapComponentAttributesRepeater.filter(x => x.key == curInd).flatMap(x => x.value).find((x) => x.name == propertyName)
          ?.value;
        return HelperUtils.booleanify(attrcpy ?? "false");
      }
    }


    return HelperUtils.booleanify(
      component?.iapComponentAttributes?.find((x) => x.name == propertyName)
        ?.value ?? "false"
    );
  };

  const getPropertyDateValue = (propertyName: string, component = Component, onlyAttr = false) => {    
    if (!onlyAttr) {
      const curInd = getRepeaterCurrentIndex()
      if (curInd > 0 && iapComponentAttributesRepeater.length > 0 && iapComponentAttributesRepeater.filter(x => x.key == curInd).flatMap(x => x.value).find((x) => x.name == propertyName)) {
        const attrcpy = iapComponentAttributesRepeater.filter(x => x.key == curInd).flatMap(x => x.value).find((x) => x.name == propertyName)
          ?.value;
          if (attrcpy){
            return new Date(attrcpy)
          }
          else{
            return null;
          }
        
      }
    }

    const _value= component?.iapComponentAttributes?.find((x) => x.name == propertyName)?.value;
    if (_value){
      return new Date(_value);
    }

    return null;

   
  };

  const getPropertyId = (propertyName: string, component = Component) => {
    return component.iapComponentAttributes.find((x) => x.name == propertyName)
      ?.id;
  };


  const catalogTypes = () => {
    return helperCatalog.getAllCatalogApp();
  }

 
  const getCatalogValue = (propertyName: string,component = Component) => {
    const prop = component.iapComponentAttributes.find((x) => x.name == propertyName);

    const catalogType = prop?.valueListId;
    const propValue = prop?.value;

    return catalogTypes().find(x => x.id == catalogType)?.iapCatalogs?.find(x => x.id?.toLowerCase() == propValue?.toLowerCase())?.value?.toLowerCase();
  };


  const updateDictionary = (key: string, value: any, dictionary: any = null) => {
    if (dictionary != null) {
      const reg = dictionary.find((x: any) => x.key == key);

      if (reg) {

        reg.value = value;
      }
    }
    else {
      const localData = LocalService.getValue(Component.formKey + LocalService.COMPONENTS_EXP + (Component.rootParentId ?? -1).toString());
      const data = HelperUtils.jsonParse(localData, [])

      const reg = data.find((x: any) => x.key == key);

      if (reg) {

        reg.value = value;
        LocalService.setValue(Component.formKey + LocalService.COMPONENTS_EXP + (Component.rootParentId ?? -1).toString(), JSON.stringify(data))
      }
    }
  }


  const resolveExpressions = (idObjeto = CatalogObjectTypeConst.ATTRCOMP, objetoId: string | null = null, idTypeExpression = CatalogExpConst.EXP_SET, expressions: IapExpression[] | null = null, castToStringResult: boolean = true) => {
    let resu: any = null;

    const expressionsData = expressions ?? Component.expressions;
    const exps = expressionsData?.filter(x => x.idObjeto == idObjeto && x.idTypeExpression == idTypeExpression && (objetoId == null || x.objetoId == objetoId)).filter(x => x.iapExpressionDetails.length > 0);

    if (exps?.length > 0) {
      exps?.every(exp => {
        if (exp.iapExpressionDetails?.length > 0) {

          /*
          para los dataviews podria ir         
          updateDictionaryFromRepeaterData()
          */

          const localData = LocalService.getValue(Component.formKey + LocalService.COMPONENTS_EXP + (Component.rootParentId ?? -1).toString());
          const data = HelperUtils.jsonParse(localData, [])



          resu = ExpresionEngine.resolveExpressions(exp.iapExpressionDetails, data as any, store)

          //resu = resu?.toString();
          if (resu) {
            if (Object.keys(resu).length == 0 && castToStringResult) {
              resu = resu?.toString();
            }
          }

          /*if (idObjeto == CatalogObjectTypeConst.ATTRCOMP && idTypeExpression==CatalogExpConst.EXP_SET){
             setPropertyValue(exp.objetoId, resu?.toString())
          }
          else{
            if (idObjeto == CatalogObjectTypeConst.DSF && idTypeExpression==CatalogExpConst.EXP_FILTER){
              resu = resu?.toString();
              return resu;
            }
  
          }
          */
          /*
          if (idTypeExpression==CatalogExpConst.EXP_FILTER){
            setPropertyValue(exp.objetoId, resu?.toString())
          }
          */
        }
      })
    }

    return resu;
  }


  const isVisibleTabPanel = (comp: ComponentDataForm) => {
    //se asigna a la varible general el comp que llega para que pueda resolver la expresion
    Component = comp;
    return (resolveExpressions(CatalogObjectTypeConst.ATTRCOMP, Component.iapComponentAttributes.find(x => x.name == BaseControlTypeConst.VISIBLE)?.id, CatalogExpConst.EXP_SET, Component.expressions) ?? getPropertyBooleanValue(BaseControlTypeConst.VISIBLE, Component));
  }

  const includeDSExp = (objetoId: string, expressions: IapExpression[] = Component.expressions, idTypeExpression = CatalogExpConst.EXP_SET, idObjeto = CatalogObjectTypeConst.ATTRCOMP) => {
    const exps = expressions?.filter(x => x.idObjeto == idObjeto && x.idTypeExpression == idTypeExpression && x.objetoId == objetoId).filter(x => x.iapExpressionDetails.length > 0).flatMap(e => e.iapExpressionDetails).filter(y => y.expression.includes('[dsf.'));
    return (exps?.length > 0);
  }

  const compIdIndexTab = (actualIndex: number, compsTabs: ComponentDataForm[]): number => {
    const allVisibleTabs = compsTabs.filter(c => isVisibleTabPanel(c as any)).sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    return allVisibleTabs[actualIndex]?.id;
  }


  const getAttrValue = (comp: ComponentDataForm, attrName: string) => {
    const value = HelperUtils.resolveAttrValue(comp.iapComponentAttributes.find(x => x.name == attrName)?.idDataType ?? '', getPropertyValue(attrName, comp))
    return (resolveExpressions(CatalogObjectTypeConst.ATTRCOMP, comp.iapComponentAttributes.find(x => x.name == attrName)?.id, CatalogExpConst.EXP_SET, comp.expressions) ?? value);
  }

  //de momento solo resuelve tipo atributo
  const isInExpression = (idObjeto = CatalogObjectTypeConst.ATTRCOMP, objetoId: string, comps: ComponentDataForm[]): boolean => {
    const exptext = comps.flatMap(x => x.expressions).filter(x => x !== null).flatMap(x => x.iapExpressionDetails).filter(x => x !== null).map(x => x.expression).join("")
    const match = exptext.match(/\[.*?\]/g)
    //@ts-ignore:disable-next-line
    const pattern = match?.map(x => x?.replaceAll('[', '').replaceAll(']', ''));
    const exps = comps.flatMap(x => x.iapComponentAttributes).filter(attr => attr.id == objetoId && pattern?.includes([ExpressionNomenclatorConst.EXPNOM_ATTRIBUTES, attr.id].join('.')));
    return (exps?.length > 0)
  }

  const hasExpressions = (idObjeto = CatalogObjectTypeConst.ATTRCOMP, objetoId: string, idTypeExpression = CatalogExpConst.EXP_SET, component: ComponentDataForm = Component): boolean => {
    const exps = component.expressions?.filter(x => x.idObjeto == idObjeto && x.idTypeExpression == idTypeExpression && (objetoId == null || x.objetoId == objetoId) && x.iapExpressionDetails?.length > 0);
    return (exps?.length > 0)
  }


  const getCompClass = (component: ComponentDataForm = Component, stringify: boolean = false): string | object => {
    const resultClass = { [getPropertyValue(HtmlTypeConst.CLASS, component) ?? '']: true, 'customrequired': getPropertyBooleanValue(HtmlTypeConst.REQUIRED, component) }
    return stringify ? JSON.stringify(resultClass) : resultClass;
  };

  const canDoOperation = (action: string, component = Component) => {
    return HelperSecurity.canDoOperation(user, (component?.objectGroups ?? []), action)
  }

  const isTemplate = (component: ComponentDataForm = Component) => {

    if (!component) return false;

    const tmp = component.idType.split('-');
    return tmp.length > 0 ? (tmp[0].toLocaleLowerCase() == CatalogTypeConst.LAYOUTTEMP) : false;
  }


  const updateDictionaryFromRepeaterData = () => {
    //para los dataviews podria ir
    const indRepeater = getRepeaterCurrentIndex()
    if (iapComponentAttributesRepeater.length > 0 && indRepeater >= 0) {

      iapComponentAttributesRepeater.filter(x => x.key == indRepeater).flatMap(x => x.value).forEach(att => {

        updateDictionary([ExpressionNomenclatorConst.EXPNOM_ATTRIBUTES, att.id].join(ExpressionNomenclatorConst.SEPARATOR), att.value);
      })
    }
  }


  const processEvent = async (idObjeto: string, objetoId: string, componentInput: ComponentDataForm | null = null, currentElementKey: string = '', _doCallBackEnd: any = undefined,_data: InteractionEvent| undefined = undefined) => {
    let wf: IapWorkFlow | null | undefined = null;
    const comp = componentInput ?? Component
    

    switch (idObjeto) {
      case CatalogObjectTypeConst.ATTRCOMP:
        wf = comp.workFlows?.find((x: IapWorkFlow) => x.idObjeto == idObjeto && x.objetoId == objetoId)
        break;

      case CatalogObjectTypeConst.EVTCOMP:
        var event = comp.iapComponentEvents.find((x: IapComponentEvent) => x.id == objetoId && x.active == true);
        const eventId = event?.id ?? '';
        if (user.isAdmin || (event && HelperSecurity.canDoOperation(user, event.objectGroups, ObjectGroupConst.EXECUTE))) {
          wf = comp.workFlows?.find((x: IapWorkFlow) => x.idObjeto == idObjeto && x.objetoId == eventId);
        }
        else {
          MessageService.showMessage(MessageType.Error, 'Error de seguridad', 'No dispone de permisos para ejecutar el evento.');
        }

        break;

      default:
        break;
    }

    if (wf && ( Component.loaded || comp.loaded )) {
      
      const formKey=  (Component?.formKey ?? comp.formKey)
      const rootParentId=  (Component?.rootParentId ?? comp.rootParentId)
      const comps = comp.get(comp.id, InteractionConst.GET_ROOT_COMPONENTS)


      var doCallBackEnd = (response:any) => {
        
        HelperLoading.hideLoading();
        if (_data){
          _data.interactionResultWf = response;
        }
        if (_doCallBackEnd) {
          _doCallBackEnd(_data)
        }
      }

      updateDictionaryFromRepeaterData()

      await executeWorkFlow(wf as any, comp.applicationId, comp.applicationVersion, rootParentId, formKey, currentElementKey, comp.id, comps, [] as any, container, store, router, doCallBackEnd)

    }
    else {
      if (_doCallBackEnd) {
        _doCallBackEnd()
      }
    }
  }




  const processEventComponent = (idEventType: string, componentInput: ComponentDataForm | null = null, currentElementKey: string = '') => {
    
    const comp = componentInput ?? Component
    const eventId = comp.iapComponentEvents.find((x: IapComponentEvent) => x.idEventType == idEventType && x.active == true)?.id ?? ''
    if (eventId) {
      processEvent(CatalogObjectTypeConst.EVTCOMP, eventId, comp, currentElementKey)
    }
  }





  return {
    Component,
    iapComponentAttributesRepeater,
    getPropertyValueOption,
    getProperty,
    getRepeaterCurrentIndex,
    getLayoutComponent,
    getContentComponent,
    isLayoutComponent,
    getAllLayoutComponent,
    getPropertyValue,
    getPropertyNumberValue,
    getPropertyBooleanValue,
    isVisibleTabPanel,
    resolveExpressions,
    includeDSExp,
    compIdIndexTab,
    getAttrValue,
    hasExpressions,
    isInExpression,
    getCompClass,
    canDoOperation,
    isTemplate,
    updateDictionary,
    processEventComponent,
    processEvent,
    updateDictionaryFromRepeaterData,
    catalogTypes,
    getCatalogValue,
    getPropertyId,
    getPropertyDateValue


  };

}
