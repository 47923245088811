<template>
    <Dialog header="Método de autenticación" v-model:visible="computedShowModalAuthTypeChild" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '30vw' }" :modal="true" :maximizable="true" :closable="canClose">

        <div>
            <p>
                Para una mayor seguridad, deberá insertar un código (enviado por uno de los siguientes métodos) para iniciar sesión.
            </p>
            
            <div v-for="(doblefactorauth, index) in getDobleFactorAuthOptions" :key="doblefactorauth">
                <input type="radio" class="btn-check" v-model="picked_two_factor" name="auth_option" value="apple" :id="doblefactorauth" :checked="checkedValue == index">
                <label class="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-5" @click="chooseTwoFactorType(index, getDobleFactorAuthOptions[index])">
                    <i :class="getAutenticatorIcon(getDobleFactorAuthOptions[index])">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </i>
                    <span class="d-block fw-semibold text-start">                            
                        <span class="text-dark fw-bold d-block fs-3">{{ getAutenticatorTitle(getDobleFactorAuthOptions[index]) }}</span>
                        <span class="text-muted fw-semibold fs-6">
                            {{ getAutenticatorDescription(getDobleFactorAuthOptions[index]) }}
                        </span>
                    </span> 
                </label>
            </div>
            
        </div>

        <template #footer>
            <Button v-if="selectedFactorAuthType != ''" id="setDobleFactorUserCode" @click="showModalEnterCode(selectedFactorAuthType)" label="Continuar" class="p-button" />
            <Button v-if ="canClose" id="cerrar" label="Cerrar" @click.stop="$emit('update:showModalAuthTypeChild', false)" icon="pi pi-times"
                class="p-button-secondary" />
        </template>
    </Dialog>

    <Dialog header="Confirmar código doble autenticación" v-model:visible="showEnterCode" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '30vw' }" :modal="true" :maximizable="true" :closable="canClose">

        <div class="col-12">
            <div class="card p-fluid">
                <div class="field">
                    <label for="code">Introduzca el código de verificación enviado.</label>
                    <InputText id="code" type="text" v-model="inputCode" />
                </div>
            </div>
        </div>

        <template #footer>
            <Button id="checkCode" @click="confirmCode()" label="Confirmar" class="p-button" />
            <Button v-if ="canClose" id="cerrar" label="Cerrar" @click.stop="hideModalEnterCode(selectedFactorAuthType);" icon="pi pi-times"
                class="p-button-secondary" />
        </template>
    </Dialog>

</template>

<script lang="ts">import { computed, ref, watch } from 'vue';
import DobleFactorAuth from '../../../../../common/domain/constantes/DobleFactorAuth';
import store from '../../../../../common/infrastructure/almacen';
import HelperUtils from '../../../../../common/infrastructure/funciones/HelperUtils';
import { Actions } from '../../../../../common/infrastructure/almacen/enums/StoreEnums';
import { Container } from 'inversify';
import HelperLoading from "../../../../../common/infrastructure/funciones/HelperLoading";
import { CookieService, MessageService } from '../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../common/infrastructure/servicios/MessageService';
import Environment from '../../../../../common/infrastructure/funciones/environment';
import { authRequest } from '@ilinium/shared/src/entidades/builderMaster/auth/domain/authRequest';
import { getCatalogsFromService } from "../../../../builder/catalog/infrastructure/helper/helperCatalog";
import CatalogConfigurationConst from "../../../../builder/catalog/domain/const/CatalogConfigurationConst";
import HelperLogin from './HelperLogin';
import { Mutations } from '@ilinium/shared/src/common/infrastructure/almacen/enums/StoreEnums';
import ErrorCodesConst from '@ilinium/shared/src/common/domain/constantes/ErrorCodes/ErrorCodesConst';
import { appConfig } from "../../domain/appConfig";
import HelperApplication from '@ilinium/shared/src/entidades/builder/application/infrastructure/functions/helperApplication';

export default {
    name: 'dobleFactor',
    props: {
        routes: Object,
        showModalAuthTypeChild: Boolean,
          container: {
            type: Object as () => Container            
        }, 
        showModalAuthType: Boolean,
        canClose: {
            type: Boolean,
            default: true
        },
        appCfg: {
          type: Object as () => appConfig
        },
    },
    emits: ['update:showModalAuthTypeChild'],
    setup(props, { emit }) {

        let showModalAuthType = ref(true);
        showModalAuthType.value = true;//props.showModalAuthType;
        let picked_two_factor = ref(false);
        let showEnterCode = ref(false);
        let checkedValue = ref<number>()
        let selectedFactorAuthType = ref('')
        let inputCode = ref('')
      
        const replaceLink = (linkElement:any, href:any, onComplete:any) => {
            if (!linkElement || !href) {
                return;
            }

            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();

                const element = document.getElementById(id); // re-check
                element && element.remove();

                cloneLinkElement.setAttribute('id', id);
                onComplete && onComplete();
            });
        };
        

        const themeLink = document.getElementById('theme-css');
        const themeHref = themeLink?.getAttribute('href');
        const path = process.env.VUE_APP_PUBLIC_PATH;
        var newHref = themeHref;
        newHref = path + '/apollo/theme/theme-light/purple/theme.css'
        newHref = newHref?.replace('//','/');

        replaceLink(themeLink, newHref, () => {
            //store.commit(Mutations.SET_APPLAYOUTCONFIG, appLayoutCfg);
        });

        /*watch(
            showModalAuthType, (oldvalue, newvalue) => {
                showModalAuthType.value = newvalue;
                emit('update:showModalAuthTypeChild', newvalue)
            },
            {
                flush: 'post',
                //immediate: true, 
                deep: true
            }
        )*/
        
        const getDobleFactorAuthOptions = computed(() => {
            
            var options = (store.getters.getCurrentUser.iapmUserOptions.find((c: { optionId: string; value: string; }) =>
                                                                                c.optionId == 'cfgbroker-MFA'
                                                                            )?.value ?? '').split(',')
                                                                                        
            options = options.filter((o: string) => o != DobleFactorAuth.SMS || (o == DobleFactorAuth.SMS && store.getters.getCurrentUser.phone != null))
            
            return options;
            return [];
        })

        const computedShowModalAuthTypeChild = computed({
            get: () => props.showModalAuthTypeChild,
            set: (val) => emit('update:showModalAuthTypeChild', val),
        });

        function showModalEnterCode(_selectedFactorAuthType: string) {
            setDobleFactorUserCode(_selectedFactorAuthType);
            showEnterCode.value = true;
            showModalAuthType.value = false;
        }

        /*const cliente = computed({
            get: () => props.showModalAuthType,
            set: (val) => emit('update:showModalAuthTypeChild', val),
        });*/

        function confirmCode() {
            var _code = store.getters.getDobleFactorCode;
            var _dobleFactorCodeFechaCaducidad = store.getters.getDobleFactorCodeFechaCaducidad;

            if (!_dobleFactorCodeFechaCaducidad || (new Date(_dobleFactorCodeFechaCaducidad) < new Date())) {
                MessageService.showMessage(MessageType.Error, 'Error de validación', 'El código ha caducado.');
            }
            else if (_code == inputCode.value.trim()) {
                confirmDobleFactorUserCodeByMail();
            }
            else {
                MessageService.showToast(MessageType.Error, 'Error de validación', 'Código no válido.');
            }
        }

        function confirmDobleFactorUserCodeByMail() {
            setTimeout(() => {
                HelperLoading.showLoading();
                store
                    .dispatch(Actions.CONFIRM_DOBLE_FACTOR_USER, [Environment.APPID, Environment.APPVERSION, store.getters.getCurrentUser.id, props.container])
                    .then(() => {                            
                        
                        CookieService.setCookie(CookieService.COOKIE_DOBLEFACTOR, 'Ok', 60 * 60 * 24 * 30); // 30 días
                                           
                            const { getAppKeyValue } = HelperApplication(undefined)
                            const request = ref<authRequest>({
                                grant_type: 'login_with_mfa_code',
                                username: store.getters.getCurrentUser.userName,
                                password: store.getters.getDobleFactorCode,
                                client_id: '',
                                client_secret: '',
                                microsoftEmail: null,
                                googleEmail: null,
                                refresh_token: store.getters.getRefreshToken,
                                brokerId: null,
                                profileId: '',
                                applicationId: Environment.APPID ? +Environment.APPID : null,
                                applicationVersion: Environment.APPVERSION ? + Environment.APPVERSION : null,
                                external: (getAppKeyValue(CatalogConfigurationConst.ISAPPEXTERNAL,Environment.ENVIRONMENT??'',store.getters.getAppConfig) === "true")  ? true : false,
                           
                                sessionId:store.getters.getSessionId
                            });
                    
                            HelperLoading.showLoading();
                            store
                                .dispatch(Actions.AUTH, [request, props.container])
                                .then(() => {
                                    HelperLoading.showLoading();
                                    getCatalogsFromService(props.container, Environment.APPID, Environment.APPVERSION).then(response => {
                                        store.commit(Mutations.SET_AUTHCOMPLETE, null)
                                        HelperLoading.showLoading();
                                        if (response) {
                                            HelperLoading.showLoading();
                                                HelperLogin.getApplicationMenus(true, props.routes, Environment.APPID, Environment.APPVERSION, props.container,  true).then((resp)=>{ 
                                                    HelperLoading.showLoading();
                                                    showModalAuthType.value = true;
                                                    store.commit(Mutations.SETVERSION, props.appCfg.version);
                                                }).finally(() => {
                                                    HelperLoading.showLoading();
                                                });
                                        }
                                    })
                                }).catch((error: any) => {
                                    HelperLoading.hideLoading();
                                    if (error.status == ErrorCodesConst.NEED_MULTIFACTOR_CODE) {
                                        store.commit(Mutations.SET_USER, error.data);
                                        showModalAuthType.value = true;
                                    }
                                })
                        
                    })
                    .finally(() => {
                        //HelperLoading.hideLoading();
                    });
            }, 500);
        }

        function setDobleFactorUserCode(dobleFactorType: string) {
            setTimeout(() => {
                HelperLoading.showLoading();
                store
                    .dispatch(Actions.SET_DOBLE_FACTOR_USER_CODE, [Environment.APPID, Environment.APPVERSION, store.getters.getCurrentUser.id, dobleFactorType, props.container])
                    .then(() => {                            
                        
                    })
                    .finally(() => {
                        HelperLoading.hideLoading();
                    });
            }, 500);
        }

        function hideModalEnterCode(_selectedFactorAuthType: string) {
            inputCode.value = ''
            switch (_selectedFactorAuthType) {
                case DobleFactorAuth.APP:
                    break;
                case DobleFactorAuth.EMAIL:
                    //showEnterCode.value = false;
                    break;
                case DobleFactorAuth.SMS:
                    break;
            }

            showEnterCode.value = false;
            selectedFactorAuthType.value = '';
            checkedValue.value = -1;
            showModalAuthType.value = true;
        }

        function chooseTwoFactorType(index: number, _selectedFactorAuthType: string) {
            checkedValue.value = index;
            selectedFactorAuthType.value = _selectedFactorAuthType;
        }

        function getAutenticatorTitle(optionType: string) {
            switch (optionType) {
                case DobleFactorAuth.APP:
                    return "Autenticación con aplicación.";
                case DobleFactorAuth.EMAIL:
                    return "Autenticación por correo.";
                case DobleFactorAuth.SMS:
                    return "Autenticación por SMS.";
            }
        }

        function getAutenticatorDescription(optionType: string) {
            switch (optionType) {
                case DobleFactorAuth.APP:
                    return "Obtiene código mediante una aplicación Móvil de autenticación.";
                case DobleFactorAuth.EMAIL:
                    return "Obtiene código mediante una cuenta de correo.";
                case DobleFactorAuth.SMS:
                    return "Obtiene código mediante el envío de un SMS.";
            }
        }
  
        function getAutenticatorIcon(optionType: string) {
            switch (optionType) {
                case DobleFactorAuth.APP:
                    return "ki-duotone ki-setting-2 fs-4x me-4 pi pi-microsoft";
                case DobleFactorAuth.EMAIL:
                    return "ki-duotone ki-setting-2 fs-4x me-4 pi pi-inbox";
                case DobleFactorAuth.SMS:
                    return "ki-duotone ki-setting-2 fs-4x me-4 pi pi-envelope";
            }
        }

  
      return {
        showModalEnterCode,
        hideModalEnterCode,
        showEnterCode,
        checkedValue,
        selectedFactorAuthType,
        getAutenticatorTitle,
        getAutenticatorDescription,
        chooseTwoFactorType,
        picked_two_factor,
        getDobleFactorAuthOptions,
        inputCode,
        confirmCode,
        showModalAuthType,
        getAutenticatorIcon,
        computedShowModalAuthTypeChild,
      }
    }
  }

</script>

<style lang="css" scoped src="./css/dobleFactor.css"></style>