<template>
    <div :id="'searchComp_' + formKey">
        <Panel class="field col-12 md:col-12" :toggleable="true" :collapsed="collapsed" @update:collapsed="collapsedEvt">
            <template #header>
                <div :class="{ 'flex flex-wrap gap-1': true }">
                    <Button v-if="showBtnSearch && dataFilter.fields.length > 0" id="buscar" :label="t('btn.buscar')"
                        icon="pi pi-search" class="mr-2 p-button-primary" @click="executeSearch()"
                        style="width:fit-content;" />
                    <Button v-if="showBtnClear && dataFilter.fields.length > 0" id="limpiarfiltros"
                        :label="t('btn.limpiar')" icon="pi pi-trash" class="mr-2 p-button-outlined p-button-secondary"
                        @click="clearFilterData()" style="width:fit-content;" />
                    <Button v-if="showBtnCloseTabs && dataFilter.fields.length > 0" id="cerrarpestanias"
                        v-tooltip="t('tooltip.cerrar')" :label="t('btn.cerrar')" icon="pi pi-times"
                        class="mr-2 p-button-outlined p-button-secondary" @click="$emit('closealltabs')"
                        style="width:fit-content;" />
                    <Button v-if="showSaveSearch && dataFilter.fields.length > 0" id="saveSearch"
                        v-tooltip="t('tooltip.saveSearch')" class="mr-2 p-button-outlined p-button-secondary"
                        :label="t('btn.saveSearch')" icon="pi pi-save" :model="savedSearchs" @click="openSaveSearch"
                        style="width:fit-content;" />

                    <SelectButton v-model="searchButtonValue" :options="options" optionLabel="name" aria-labelledby="multiple"  @update:modelValue="onChangeSearch">
                        <template #option="slotProps">
                            <i :class="slotProps.option.icon" class="mr-1"></i>
                            <span>{{slotProps.option.name}}</span>
                        </template>
                    </SelectButton>
                                        
                        <!-- Se añaden botones al final  del boton de avanzada-->
                        <slot name="buttonssearch"></slot>
                </div>
            </template>
            <template #togglericon="data">
                <span v-if="data.collapsed" class="pi pi-chevron-down"></span>
                <span v-else class="pi pi-chevron-up"></span>
            </template>
            <div class="card">
                <ul v-if="!list && (searchButtonValue?.value==SearchTypeConst.ADVANCED || searchButtonValue?.value==SearchTypeConst.SIMPLE)" class="tree">
                    <searchTree :container="container" v-model="dataFilter" :catalogosData="catalogosData"
                        v-model:allTree="dataFilter" :isRoot="true" :compDataSource="compDataSource"
                        :advancedSearch="(searchButtonValue?.value==SearchTypeConst.ADVANCED)" :cleanFilter="cleanFilter" @search="executeSearch()"></searchTree>
                </ul>

                    <FormBuilder  v-if="!list && searchComponentId>0 && searchButtonValue?.value==SearchTypeConst.GENERAL" ref="searchForm" :key="searchFormKey" :container="container" :componentId="searchComponentId" :applicationId="applicationId"
                        :applicationVersion="applicationVersion" :attrsInput="[]" @searchFormChanges="processEvt($event)"/>     

              

                <Message v-if="list" severity="info" :closable="true" class="mr-2 ml-2" @close="closeListMessag">
                    <template #messageicon>
                        <i class="pi pi-list mr-1"></i>
                    </template>
                    <template #closeicon>
                        <i class="pi pi-arrow-circle-left " style="font-size: 1.5rem"  v-tooltip="'Volver a la búsqueda'"></i>
                    </template>
                    <span class="font-bold mb-1">Lista: </span> <span class="mr-1">{{ listDesc }}</span>
                </Message>
            </div>

        </Panel>
        <OverlayPanel ref="opSaveSearch" :dismissable="false" :showCloseIcon="true">
            <div class="card" style="height: 100%">
                <TabView v-model:activeIndex="indexActive">
                    <TabPanel v-for="(tab, index)  of searchTabPanel"  :key="index"  :header="tab.description">
                        <SaveSearch v-if="indexActive===index" :container="container" :applicationId="applicationId"
                            :applicationVersion="applicationVersion" :componentId="Component.id"
                            v-model:dataFilter="dataFilter" :compDataSource="compDataSource"
                            :catalogosData="catalogosData" @click:setSearch="loadSearch"
                            :searchType="tab.searchType" :columnFilters="columnFilters"
                            :sortData="sortData" :newAutomaticAdd="newAutomaticAdd" />
                    </TabPanel>
                </TabView>
            </div>
        </OverlayPanel>
    </div>
</template>
<script lang="ts">

import {  defineComponent, onMounted, ref, watch,  onBeforeMount,  onUnmounted,shallowRef,nextTick } from 'vue';
import { Container } from 'inversify';
import { useI18n } from 'vue-i18n';
import { messages } from './localization/MessagesBusqueda'
import { IapCatalog } from '../../../catalog/domain/iapCatalog';
import { SearchData, GroupSearch } from '../../../search/domain/search';
import DataSourceComp from '../functions/dataSourceComp';
import FiltroBusquedaConst from '../../../../../common/domain/constantes/FiltroBusquedaConst';
import CatalogTypeConst from '../../../../../common/domain/constantes/CatalogTypeConst';
import SqlTypesConst from '../../../../../common/domain/constantes/SqlTypesConst';
import { TYPES } from '../../../../../common/domain/types';
import HelperLoading from '../../../../../common/infrastructure/funciones/HelperLoading';
import { IapComponentDataSource } from '../../../component/domain/iapComponentDataSource';
import SaveSearch from './SaveSearch.vue'
import SearchTree from './SearchTree.vue'
import { IampSearchConfig } from '@ilinium/shared/src/entidades/builderMaster/user/domain/iapmSearchConfig';
import HelperUtils from '../../../../../common/infrastructure/funciones/HelperUtils';
import { useStore } from 'vuex';
import HelperCommon from '../../../../../common/infrastructure/funciones/HelperCommon';
import { IServiceSearchConfig } from '../../../../builderMaster/user/application/IServiceSearchConfig';
import OperatorLogicConst from '../../../search/domain/Const/OperatorLogicConst';
import SearchConfigTypeConst from '../../../../builderMaster/user/domain/const/SearchConfigTypeConst';
import { useRoute } from 'vue-router';
import ComponentDataForm from '../../../designer/domain/ComponentDataForm';
import searchFormHelper from '../functions/searchFormHelper';
import SearchFormEvtConst from '../../../form/domain/Constants/SearchFormEvtConst';
import SearchTypeConst from '../functions/searchTypeConst';

export default defineComponent({
    name: 'search',
    props: {
        container: {
            type: Object as () => Container
        },
        applicationId: {
            type: Number,
            default: -1
        },

        applicationVersion: {
            type: Number,
            default: -1
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        modelValue: {
            type: Object as () => GroupSearch,
            default: () => ([])
        },
        dataSearch: {
            type: Object as () => any,
            default: () => ([])
        },
        compDataSource: {
            type: Object as () => IapComponentDataSource,
            default: () => ({})
        },
        catalogosData: {
            type: Object as () => IapCatalog[],
            default: () => ([])
        },
        showBtnSearch: {
            type: Boolean,
            default: true
        },
        showBtnClear: {
            type: Boolean,
            default: true
        },
        showBtnCloseTabs: {
            type: Boolean,
            default: false
        },
        showSaveSearch: {
            type: Boolean,
            default: true
        },
        showTabMode: {
            type: Boolean,
            default: false
        },
        maxRegs: {
            type: Number,
            default: 100
        },
        searchComponentId: {
            type: Number,
            default: 0
        },
        formKey: {
            type: String,
            default: ''
        },
        tabIndex: {
            type: Number,
            default: 0
        },
        columnFilters: {
            type: Object as () => any,
            default: {}
        },
        sortData: {
            type: Object as () => any[],
            default: []
        },
        advancedSearch: {
            type: Boolean,
            default: false
        },
        collapsible: {
            type: Boolean,
            default: true
        },
  



    },
    emits: ['update:modelValue', 'update:catalogosData', 'update:dataSearch', 'closealltabs', 'buscarDatos', 'update:colFilters','update:sortData','clear:filterdata'],
    components: {

        SaveSearch,
        SearchTree

    },
    setup(props, { emit }) {
        const { t, locale } = useI18n(messages)
        const store = useStore();
        const {  dataFilter, isType,
            dataSearchResult,
            tablas,
            tablasData,
            getFieldData,initFilterField,getRequiredFilterFields,getDefaultvalue,getFilterValueData
        } = DataSourceComp(props.container as any, props, emit, t, props.compDataSource, [] as any, store)

        const fields: Partial<SearchData>[] = [];
        const opSaveSearch = ref();
        const containerSize = ref();
        const initialTabIndex = ref();
        const newAutomaticAdd = ref<IampSearchConfig>();
        const lg = ref(false);
        const md = ref(false);
        const xl = ref(false);
        const sm = ref(false);
        const list=shallowRef(false)
        const listDesc=shallowRef('')
        const cleanFilter=ref(false);
        const collapsed=shallowRef(false);
        const route = useRoute();
        const selectedSearch = ref('');
        const searchFormKey = ref();
        const searchForm = ref();
        let searchComps:ComponentDataForm[]=[];
        const indexActive = shallowRef(0);

        const searchTabPanel: { searchType: string; description: string }[] = [
        {  searchType: SearchConfigTypeConst.SEARCHCONFIGTYPE_CUS, description: "Búsquedas propias" },
        {  searchType: SearchConfigTypeConst.SEARCHCONFIGTYPE_NCUS, description: "Últimas búsquedas" },
        {  searchType: SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST, description: "Listas" },
        ];

       
        const options = ref([
            { name: 'Avanzada', value: SearchTypeConst.ADVANCED , icon: 'pi pi-arrow-up' },
            { name: 'Simple', value: SearchTypeConst.SIMPLE , icon: 'pi pi-search'  },
           
        ]);

        if(props.searchComponentId>0) options.value.push( { name: 'General', value: SearchTypeConst.GENERAL  , icon: 'pi pi-align-justify' })

        const searchButtonValue = ref(
        props.searchComponentId > 0 
            ? options.value.find(x => x.value ==SearchTypeConst.GENERAL) 
            : options.value.find(x => x.value == (props.advancedSearch ? SearchTypeConst.ADVANCED : SearchTypeConst.SIMPLE))
        );
            
        const fieldsRequired: Partial<SearchData>[] = getRequiredFilterFields( { operatorLogic: OperatorLogicConst.AND,fields:dataFilter.value.fields as any,children:[]});
 
        let savedSearchs = ref([{ label: 'Búsqueda 1' }, { label: 'Búsqueda 2' }]);


        //Cuando hay un cambio de route se fuerza a cerrar el overlay.
        watch(() => route, () => {
            if (opSaveSearch.value) {
                opSaveSearch.value.hide();
            }
        }, { immediate: true, deep: true });



        const initData = (forceInitData:boolean=false) => {
            //se resetean los campos
            fields.length=0;
            
            if (!dataFilter.value || forceInitData) {
 
                if(searchButtonValue.value?.value==SearchTypeConst.SIMPLE){
                    const firstTabla = tablas.value[0]?.tableName ?? ''
                        tablasData.value.filter(x => x.tableName.toLocaleLowerCase() == firstTabla.toLocaleLowerCase() && props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == x.id)?.defaultFilterSearch)
                            .sort((a, b) => { return (props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == a.id)?.defaultFilterSearchOrder ?? 0) - (props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == b.id)?.defaultFilterSearchOrder ?? 0); })
                            .forEach(c => {
                                    fields.push({
                                    id: HelperUtils.newGuid().toString().replaceAll('-', ''),
                                    fieldId: c.id as any,
                                    rangeDateTime: {
                                        from: null,
                                        to: null
                                    },
                                    rangeValue: {
                                        from: null,
                                        to: null
                                    },
                                    filter: initFilterField(c),
                                    value: c.filterValue ? getFilterValueData(c.filterValue, 1) :getDefaultvalue(c, props.compDataSource?.iapComponentDataSourceFieldConfigurations?.find(cfg => cfg.dataSourceFieldId == c.id)?.defaultValue, props.Component.expressions, true)
                                } as any)
                            
                            })
                }
                   

                dataFilter.value = {
                    operatorLogic: OperatorLogicConst.AND,
                    fields: fields.concat(fieldsRequired) as any,
                    children: []
                }
            }

        }


        const clearFilterData = () => {

            if(props.searchComponentId>0 && searchButtonValue?.value?.value==SearchTypeConst.GENERAL){  

                dataFilter.value.fields=[]
                initData()
                searchFormKey.value=HelperUtils.newGuid().toString();

            }
            else{
                if (!dataFilter) {
                    initData()
                }
                else {
                    cleanFilter.value=true;
                    clearTreeData(dataFilter.value);
                    //para que watch de sus hijos detecte el cambio
                    nextTick(() => {
                        cleanFilter.value = false;
                    });
                }
            }
          
            emit('clear:filterdata')


        }

        const clearTreeData = (groupSearch: GroupSearch) => {

            if (groupSearch.fields.length > 0) {
                groupSearch.fields.forEach(x => {
                    if (!('filterValue' in getFieldData(x.fieldId)) || (('filterValue' in getFieldData(x.fieldId)) && props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == x.fieldId)?.defaultFilterSearch)) {
                        x.value = null as any,
                            x.rangeDateTime = {
                                from: null,
                                to: null
                            },
                            x.rangeValue = {
                                from: null,
                                to: null
                            },
                            x.valueBool = undefined as any,
                            x.valueDateTime = undefined as any,
                            x.valueList = undefined as any,
                            x.valueNumber = undefined as any,
                            x.filter = initFilterField(getFieldData(x.fieldId))
                    }

                })

            }

            if (groupSearch.children.length > 0) {
                groupSearch.children.forEach(x => {
                    clearTreeData(x);
                });
            }

        };

        const executeSearch = () => {

            if(dataFilter.value){
                emit('buscarDatos');
                if(!props.showTabMode && props.collapsible){
                    collapsed.value=true;
                }
            }
            saveAutomaticSearchData();
        }

        const openSaveSearch = (event: any) => {
            opSaveSearch.value.toggle(event);

        }

        const closeSaveSearch = () => {
            opSaveSearch.value.hide();

        }

       

        const loadSearch = (data: IampSearchConfig) => {

            if(data.idSearchConfigType==SearchConfigTypeConst.SEARCHCONFIGTYPE_LIST){
                list.value=true;
                listDesc.value=data.name
            }
            else{
                list.value=false;
                listDesc.value=''
            }

            let savedData = JSON.parse(data.data)?.searchFilters as any;

            if (savedData) {

                HandleDatesTreeSearch(savedData);

                HelperLoading.showLoading();

                
                if(hasChildren(savedData)){//Si estoy cargando una busqueda guardada y tiene hijos se cambia automaticamente a busqueda avanzada.
                    searchButtonValue.value= { name: 'Avanzada', value: SearchTypeConst.ADVANCED, icon: 'pi pi-arrow-up' };
                }

                dataFilter.value = savedData;

                //informar al formulario que los controles carguen la busqueda
                if(props.searchComponentId>0 && searchButtonValue?.value?.value==SearchTypeConst.GENERAL){
                    //nos aseguramos que datafilter.value ya se ha seteado.
                    nextTick(() => {
                        const {loadSavedSearch } = searchFormHelper(tablasData.value,store, dataFilter.value,searchForm.value,searchComps );
                        loadSavedSearch()
                    });
                }
             
                emit('update:colFilters', JSON.parse(data.data)?.columnFilters);
                emit('update:sortData', JSON.parse(data.data)?.multiSortMeta);
                emit('buscarDatos');
              
            }
            else {
                    HelperLoading.hideLoading();
                }

                //cerramos ventana de busqueda
                closeSaveSearch();

            }

        const HandleDatesTreeSearch = (data: any) => {

            HelperUtils.handleDates(data.fields);

            if (data.children && data.children.length > 0) {
                data.children.forEach(x => {
                    HandleDatesTreeSearch(x.fields);
                })
            }
        }

        const hasChildren=(data:any)=>{
            return (data?.children && data.children.length > 0);
        }


        const saveAutomaticSearchData = () => {

            const formattedDate = HelperCommon.formateaFechaHora(new Date(), true);

            const data:IampSearchConfig = {
                id:0,
                userId: store.getters.getCurrentUser.id,
                applicationId: props.applicationId,
                applicationVersion: props.applicationVersion,
                componentId: props.Component.id,
                profileId: store.getters.getCurrentProfile != 0 ? store.getters.getCurrentProfile : null,
                name: formattedDate ? formattedDate.toString() : new Date().toString(),
                data: JSON.stringify(
                    {
                        searchFilters: dataFilter.value,
                        columnFilters: props.columnFilters,
                        multiSortMeta:[]

                    }),
                fcr: new Date(),
                idSearchConfigType:SearchConfigTypeConst.SEARCHCONFIGTYPE_NCUS,
                ucr:store.getters.getCurrentUser.id,
                group:null
            };
            

            if (props.container) {
                let dataInput = JSON.parse(JSON.stringify(data));
                const _srv = props.container.get<IServiceSearchConfig>(
                    TYPES.SEARCH_USR_REPOSITORY
                );
                HelperLoading.showSaving();
                _srv.add(dataInput as any)
                    .then((response) => {
                        newAutomaticAdd.value = response;
                    })
                    .finally(() => {
                        HelperLoading.hideSaving();
                    });
            }

        }

        const setSizeScreen = () => {

            sm.value = false;
            lg.value = false;
            md.value = false;
            xl.value = false;

            if (containerSize.value) {

                if (containerSize.value <= 641) {
                    sm.value = true;
                }
                else if (containerSize.value > 641 && containerSize.value <= 1007) {
                    md.value = true;
                }
                else if (containerSize.value > 1007 && containerSize.value <= 1200) {
                    lg.value = true;
                }
                else if (containerSize.value > 1200) {
                    xl.value = true;
                }

            }


        }



        const getContainerSize = () => {

            if (props.tabIndex == 1) {

                document?.getElementById('searchComp_' + props.formKey)?.parentNode?.parentNode?.parentNode?.firstElementChild?.removeAttribute('style');

                containerSize.value = document?.getElementById('searchComp_' + props.formKey)?.offsetWidth;

                document?.getElementById('searchComp_' + props.formKey)?.parentNode?.parentNode?.parentNode?.firstElementChild?.setAttribute('style', 'display: none;');

            }
            else if (props.tabIndex == 0 && initialTabIndex.value == 1) {
                containerSize.value = document?.getElementById('searchComp_' + props.formKey)?.offsetWidth;
            }


        }

        const handleWindowResize = () => {
            getContainerSize();
            setSizeScreen();

        }
        
        const onChangeSearch=(event:any)=>{
            searchButtonValue.value=event;
            initData(true);
        }

        const closeListMessag=()=>{
            initData(true);
            list.value=false;
            listDesc.value='';
        }

        const collapsedEvt=(evt:any)=>{
            collapsed.value=evt;
        }


        const processEvt = (evt: any) => {

        const { getFormDataFilter,updateFilter} = searchFormHelper(tablasData.value,store, dataFilter.value );

        switch (evt?.typeId) {
                   case SearchFormEvtConst.COMPS_LOADED: 
                   searchComps=evt.data
                   getFormDataFilter(evt.data);
                    break; 
                   case SearchFormEvtConst.VMODEL_CHANGE: 
                   updateFilter(evt.data.value,evt.data.component)
                    break; 
                   case SearchFormEvtConst.ATTR_CHANGE: 

                    break;
                default:
                   break;
            }
        };


   



        onMounted(() => {
            
            initialTabIndex.value = props.tabIndex;

            getContainerSize();
            setSizeScreen();

            //metemos evento
            window.addEventListener('resize', handleWindowResize);


        });

        onBeforeMount(() => {

            if(props.searchComponentId==0){
                initData();
            }
            
        });

        onUnmounted(() => {
            window.removeEventListener('resize', handleWindowResize);
        })



        return {
            t,
            locale,
            dataFilter,
            clearFilterData,
            SqlTypesConst,
            isType,
            CatalogTypeConst,
            dataSearchResult,
            savedSearchs,
            tablas,
            tablasData,
            opSaveSearch,
            openSaveSearch,
            loadSearch,
            executeSearch,
            newAutomaticAdd,
            FiltroBusquedaConst,
            md,
            lg,
            xl,
            sm,
            containerSize,
            getFieldData,
            SearchConfigTypeConst,
            onChangeSearch,
            list,
            listDesc,
            closeListMessag,
            cleanFilter,
            collapsed,
            collapsedEvt,
            processEvt,
            selectedSearch,
            searchFormKey,
            searchForm, 
            searchButtonValue,
            options,
            SearchTypeConst,
            indexActive,
            searchTabPanel
          
            
        };
    },
});
</script>
<style scoped>
.tree {
    --spacing: 1.5rem;
    --radius: 10px;
}
</style>
<style lang="scss" scoped>
::v-deep(.p-fluid .p-button) {
    width: fit-content;
}
</style>
