<template>
    <div id="tableincrs">
        <Panel class="field col-12 md:col-12" :toggleable="true" :collapsed="false"
            :style="{ padding: '0.25rem 0.5rem', margin: '0' }">
            <template #header>
                <div class="flex flex-wrap align-items-center gap-2">
                    <Button id="buscar" label="Buscar" icon="pi pi-search" class="mr-1 p-button-primary"
                        :style="{ padding: '0.25rem 0.5rem', margin: '0' }" @click="executeSearch" />
                    <Button id="limpiar" label="Limpiar filtros" icon="pi pi-trash"
                        class="mr-2 p-button-outlined p-button-secondary"
                        :style="{ padding: '0.25rem 0.25rem', margin: '0' }" @click="clearFilterData()" />
                    <Dropdown v-model="selectedSearch" :options="fieldsData" filter optionLabel="label"
                        optionGroupLabel="label" optionValue="value" @change="selectField($event)"
                        optionGroupChildren="items"
                        class="align-items-center justify-content-center w-full md:w-11rem mr-1"
                        :placeholder="'Seleccione filtro'">
                        <template #optiongroup="slotProps">
                            <div class="flex align-items-center">
                                <i :class="slotProps.option.icon"></i>
                                <div class="ml-2">{{ slotProps.option.label }}</div>
                            </div>
                        </template>
                    </Dropdown>
                </div>
            </template>
            <div>
                <div class="p-fluid formgrid grid">
                    <div v-for="(item, index) of dataSearchFilter"
                        :key="(dataBaseId + '.' + getFieldData(item.fieldId)?.tableName + '.' + getFieldData(item.fieldId)?.field)"
                        :class="{ 'field': true, 'col-12': colSize == 12, 'col-6': colSize == 6, 'col-4': colSize == 4, 'col-3': colSize == 3 }">
                        <!---Quitar campo, nombre campo y filtro-->
                        <div class="flex align-items-center mb-1">
                            <!---nombre campo-->    
                            <label class="mr-1" :for="getFieldData(item.fieldId)?.id">{{ getLabel(getFieldData(item.fieldId)) }}</label>
                              <!---filtros-->
                            <Dropdown v-if="showFilter(item?.fieldId??'')" v-model="item.filter"  @change="onChangeDropDown($event,item)"  @focus="onFilterFocus(item?.filter)"
                                :options="getCatalogFilterByFieldType(getFieldData(item.fieldId))" optionLabel="value" :title="toolTipDD" @mouseover="setTooltip(item?.filter??'')"
                                optionValue="id"  class="ml-1 mr-1 flex align-items-center justify-content-center wrap-input flex-grow-1">
                                <template #option="slotProps">
                            <div v-tooltip="getToolTipFilter(slotProps.option)" style="width: 100%; height: 100%;"> {{  slotProps.option.value}}</div>
                        </template>
                            </Dropdown>
                            <!---Campos-->
                        <div v-if="!notShowControl.includes(item?.filter??'')" :class="{'flex-2':true,'p-inputgroup':!isType(getFieldData(item.fieldId), SqlTypesConst.BIT)}">                            
                            <LookUpEditor v-if="getFieldData(item.fieldId)?.dataSourceLookUpId !==null"
                                     v-model="item.value" :dataSourceLookUp="getFieldData(item.fieldId)?.dataSourceLookUp" 
                                     :container="container" :filterConditions="[]" :showSearch="true" :dataSourceLookUpId="getFieldData(item.fieldId)?.dataSourceLookUpId"></LookUpEditor>
                            <div  v-else-if="isType(getFieldData(item.fieldId), SqlTypesConst.DATETIME) || isType(getFieldData(item.fieldId), SqlTypesConst.DATE)"  class="flex" style="width:100%">
                            <Calendar
                                v-if="item.filter !== FiltroBusquedaConst.FILTROBUSQUEDA_NONULO && item.filter !== FiltroBusquedaConst.FILTROBUSQUEDA_NULO
                                && item.filter!=FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO"
                                v-model="item.rangeDateTime.from" :showButtonBar="true" class="mr-1" :manualInput="true" :ref="calendarsFromRef[index]"  />
                            <Calendar
                               v-if="dateFilter.includes(item.filter) && item.filter!=FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO"
                                v-model="item.rangeDateTime.to" :showButtonBar="true" :manualInput="true" :ref="calendarsToRef[index]" @input="setDateFilterValues({value:item.filter},index)" @date-select="setDateFilterValues({value:item.filter},index)"/>
                                 <InputNumber v-if="item.filter==FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO" id="inputcalen" mode="decimal"
                            v-model="item.valueNumber" showButtons :format="false" :inputStyle="'width: 30px'" placeholder="Días..." @update:modelValue="setDateFilterValues({value:item.filter},index)" @input="setDateFilterValues({value:item.filter},index)"/>
                            </div>
                            <MultiSelect
                                v-else-if="isType(getFieldData(item.fieldId), SqlTypesConst.VARCHAR) && isCatalogType(getFieldData(item.fieldId))"
                                v-model="item.valueList" :options="getCatalogData(getFieldData(item.fieldId)?.catalogTypeId??'')"
                                :filter="true" optionLabel="description" optionValue="id"
                                @change="stopPropagateEventDropDown($event)" />
                            <!-- <InputSwitch v-else-if="isType(getFieldData(item.fieldId), SqlTypesConst.BIT)" v-model="item.value"
                                class="mr-2" :trueValue="'1'" :falseValue="'0'" /> -->
                            <TriStateCheckbox v-else-if="isType(getFieldData(item.fieldId), SqlTypesConst.BIT)" v-model="item.value"/>
                            <InputText v-else v-model="item.value" :maxlength="getFieldData(item.fieldId)?.length ?? 25"
                                v-on:keyup.enter="executeSearch"></InputText>
                        </div>

                            <!--borrar campo-->
                            <i v-if="isFilterVisible(getFieldData(item.fieldId))" :class="{'flex align-items-center text-gray-500 ml-1 mr-1':true,'pi pi-filter-slash':showFilter(item?.fieldId??''),'pi pi-filter':!showFilter(item?.fieldId??'')} " 
                            :style="{'font-size': '0.85rem'}"  @click="onClickFilter(item)"></i>
                            <i :class="{'pi pi-times flex align-items-center text-gray-500 ml-1 mr-1':true} " 
                            :style="{'font-size': '0.85rem'}" @click="removeField(dataSearchFilter[index])"></i>
                            
                        </div>

                       

                    </div>
                </div>
            </div>
            <Message v-if="dataSearchFilter?.length == 0" :closable="false">No hay filtros seleccionados</Message>

        </Panel>

    </div>

    <DataTable v-if="dataSearchFilter?.length > 0" :value="rows" paginator :rows="20" :rowsPerPageOptions="[5, 10, 20]"
        selectionMode="single" @rowSelect="onRowSelect" :filters="filters" reorderableColumns
        :style="{ padding: '1rem 1rem', margin: '0' }">

        <template #empty> No se han encontrado datos para los filtros indicados </template>

        <Column v-for="(col, index) of columnsFilteredList" :field="col.field" :header="col.header"
            :key="col.field + '_' + index">

            <template #body="{ data, field }">
                {{ (columnsToFormat.includes(col.field) ? data[field + DataSourceConst.DELIMITERFIELDFORMAT]  :data[field]) }}
            </template>
        </Column>
        <Column  headerStyle="width:2rem">
            <template #header>
                <div class="flex justify-content-start">
                    <span class="p-input-icon-left">
                        <InputText v-model="filters['global'].value" :placeholder="'Filtrar...'" />
                        <span class="p-input-icon-right ">
                            <i v-tooltip.top="'Borrar búsqueda'" @click="filters['global'].value = ''"
                                style="font-size: 0.75rem" class=" pi pi pi-times  text-gray-500" />
                        </span>
                    </span>
                </div>
            </template>
        </Column>

    </DataTable>
</template>
<script lang="ts">


import { defineComponent, computed, ref, onMounted, shallowRef,watch,onBeforeMount } from 'vue';
import { Container } from 'inversify';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { IServiceSearch } from '../../../search/application/IServiceSearch';
import HelperLoading from '@ilinium/shared/src/common/infrastructure/funciones/HelperLoading';
import { TYPES } from '@ilinium/shared/src/common/domain/types';
import FiltroBusquedaConst from '@ilinium/shared/src/common/domain/constantes/FiltroBusquedaConst';
import { GroupSearch, SearchData } from '../../../search/domain/search';
import { IapDataSourceLookUp } from '../../../datasource/domain/iapDataSourceLookUp';
import DataSourceConst from '../functions/dataSourceConst';
import { IapDataSourceField } from '../../../datasource/domain/iapDataSourceField';
import { IapCatalog } from '../../../catalog/domain/iapCatalog';
import CatalogTypeConst from '@ilinium/shared/src/common/domain/constantes/CatalogTypeConst';
import SqlTypesConst from '@ilinium/shared/src/common/domain/constantes/SqlTypesConst';
import HelperCommon from '@ilinium/shared/src/common/infrastructure/funciones/HelperCommon';
import { DropdownChangeEvent } from 'primevue/dropdown';
import { MultiSelectChangeEvent } from 'primevue/multiselect';
import DataSourceComp from '../functions/dataSourceComp';
import { useStore } from 'vuex';
import HelperUtils from '@ilinium/shared/src/common/infrastructure/funciones/HelperUtils';
import { Dictionary } from '../../../expression/domain/dictionary';
import CatalogObjectTypeConst from '../../../catalog/domain/const/CatalogObjectTypeConst';
import OperatorLogicConst from '../../../search/domain/Const/OperatorLogicConst';
import TriStateCheckbox from 'primevue/tristatecheckbox';

export default defineComponent({
    name: 'look_up_search_table',
    events: ['update:data', 'change:rowSelected','mounted'],
    components: {
        TriStateCheckbox
     },
    props: {
        container: {
            type: Object as () => Container
        },
        dataSourceLookUp: {
            type: Object as () => IapDataSourceLookUp,
            default: []
        },
        catalogsData: {
            type: Object as () => IapCatalog[],
            default: () => ([])
        },

        filterConditions: {
            type: Object as () => SearchData[],
            default: []
        },
        colSize: {
            type: Number,
            default: (12)
        },
        where: {
            type: String,
            default: null
        },
    },
 
    setup(props, { emit }) {

        const dataSearchFilter = ref<Partial<SearchData>[]>([]);
        const rows = ref();
        const selectedSearch = ref('');
        const fieldsData = ref();
        const store = useStore();
        const calendarsFromRef = ref(new Array());
        const calendarsToRef = ref(new Array());
        const visibleFilter = ref<string[]>([]); 
        const toolTipDD =shallowRef('');
        let oldFilter:string='';
        const dateFilter:string[]=[FiltroBusquedaConst.FILTROBUSQUEDA_ENTRE,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTMONTH,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTYEAR,
            FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSMONTH,FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSYEAR,FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSWEEK,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTWEEK,
            FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO
            ];

        const notShowControl:string[]=[FiltroBusquedaConst.FILTROBUSQUEDA_NONULO,FiltroBusquedaConst.FILTROBUSQUEDA_NULO,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTMONTH,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTYEAR,
           FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSMONTH,FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSYEAR,FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSWEEK,FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTWEEK,
            ]; 

        const { tablasData, dataBaseId, isType, isCatalogType, getCatalogData,isFilterVisible,mustFormatDataColuns,getFieldData,getFieldKeyColumn,initFilterField } = DataSourceComp(props.container as any, props, emit, {}, {} as any, props.catalogsData, store, props.dataSourceLookUp.dataSource as any)

        const columnsToFormat = ref(mustFormatDataColuns());
        
        const objDsfConfiguration = computed(() => {

            return tablasData.value.flatMap(x => x.iapObjectDataSourceFieldConfigurations).filter(cf => cf.idObjeto == CatalogObjectTypeConst.LOOKUP && cf.objetoId == props.dataSourceLookUp.id);

        })

      



        const filters = ref({
            'global': { value: '', matchMode: FilterMatchMode.CONTAINS },
            'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
        });

        const filterTables = computed(() => {
            const aux = dataSearchFilter.value.map(x => getFieldData(x.fieldId)?.tableName)
            const uniqueIds = [...new Set(aux)];
            return (uniqueIds ?? []) as any
        })


        const getField = (dataSourceField: IapDataSourceField) => {

            const fieldMapsDataSourceLookUpIds = props.dataSourceLookUp.iapDataSourceLookUpFieldMaps.filter(x => x.dataSourceFieldIdSource != null && x.isFieldLinked).flatMap(x => x.dataSourceFieldIdSource);
            const fieldLookUpDesc = props.dataSourceLookUp.idFieldDescription;

            if (fieldMapsDataSourceLookUpIds.includes(dataSourceField.id)) {
                return (DataSourceConst.LOOKUPID + DataSourceConst.DELIMITERFIELDS + dataSourceField.id)
            }

            if (fieldLookUpDesc.includes(dataSourceField.id)) {
                return DataSourceConst.LOOKUPDESC;
            }

            return getFieldKeyColumn(dataSourceField);

        }


        const columnsFilteredList = computed(() => {

            let _cols = [] as any;

            filterTables.value.forEach(tablaName => {
                const fields = tablasData.value.filter(x => x.dataSourceTableAliasId != null && x.tableName == tablaName && objDsfConfiguration.value.find(z => z.dataSourceFieldId == x.id)?.list)
                    .sort((a, b) => { return (objDsfConfiguration.value.find(z => z.dataSourceFieldId == a.id)?.filterResultOrder ?? 0) - (objDsfConfiguration.value.find(z => z.dataSourceFieldId == b.id)?.filterResultOrder ?? 0); })
                    .map(x => ({ id: x.id, header: x.shortDescription ?? x.field, field: getField(x) }))

                _cols = _cols.concat(fields)
            })

            const customFields = tablasData.value.filter(x => x.dataSourceTableAliasId == null && objDsfConfiguration.value.find(z => z.dataSourceFieldId == x.id)?.list)
                .sort((a, b) => { return (objDsfConfiguration.value.find(z => z.dataSourceFieldId == a.id)?.filterResultOrder ?? 0) - (objDsfConfiguration.value.find(z => z.dataSourceFieldId == b.id)?.filterResultOrder ?? 0); })
                .map(x => ({ id: x.id, header: x.shortDescription ?? x.fieldAlias, field: getFieldKeyColumn(x,true) }))

            if (customFields.length > 0) {
                _cols = _cols.concat(customFields)
            }

            return _cols;

        })



        const getLabel = (field: IapDataSourceField) => {

            if (!HelperCommon.isNullOrWhitespace(field.shortDescription ?? '')) {
                return field.shortDescription;
            }
            if (!HelperCommon.isNullOrWhitespace(field.fieldAlias ?? '')) {
                return field.fieldAlias;
            }

            return field.field;

        };


        const tables = computed(() => {
            return props.dataSourceLookUp.dataSource.iapDataSourceTableAliases;
        })

        const getCatalogFilterByFieldType = (item: any) => {
            let catalog = getCatalogData(CatalogTypeConst.FILTROBUSQUEDA);

            const text:string[]=[FiltroBusquedaConst.FILTROBUSQUEDA_CONTIENE,FiltroBusquedaConst.FILTROBUSQUEDA_NOTCONTAINS,FiltroBusquedaConst.FILTROBUSQUEDA_STARTWITH
                ,FiltroBusquedaConst.FILTROBUSQUEDA_ENDWITH
            ];
            const num:string[]=[FiltroBusquedaConst.FILTROBUSQUEDA_GREATTHAN,FiltroBusquedaConst.FILTROBUSQUEDA_LESSTHAN,FiltroBusquedaConst.FILTROBUSQUEDA_GREATTHANEQTO
                ,FiltroBusquedaConst.FILTROBUSQUEDA_LESSTHANEQTO,FiltroBusquedaConst.FILTROBUSQUEDA_DISTINTO
            ];


            if (isType(item, SqlTypesConst.VARCHAR)) {
                catalog = catalog.filter(x =>!num.includes(x.id) &&  !dateFilter.includes(x.id));
            }

            if (isType(item, SqlTypesConst.INT) || isType(item, SqlTypesConst.MONEY)) {
                catalog = catalog.filter(x =>!text.includes(x.id) && !dateFilter.includes(x.id));
            }

            if (isType(item, SqlTypesConst.DATETIME) || isType(item, SqlTypesConst.DATE)) {
                catalog = catalog.filter(x =>!text.includes(x.id));
            }


            return catalog.sort((a: { order: number; }, b: { order: number; }) => { return a.order - b.order; });
        }


        const removeField = (item: any) => {

            visibleFilter.value= visibleFilter.value.filter(x => x!==item?.fieldId);
            dataSearchFilter.value = dataSearchFilter.value.filter(x => x.fieldId !== item?.fieldId);
            const field=getFieldData(item?.fieldId);
            addFieldOnTheList(field);
        }

        const stopPropagateEventDropDown = (event: DropdownChangeEvent | MultiSelectChangeEvent) => {
            
            event.originalEvent.stopPropagation();
            event.originalEvent.preventDefault();
        }

        const onChangeDropDown=(event: DropdownChangeEvent | MultiSelectChangeEvent,item:any)=>{
            stopPropagateEventDropDown(event);
            updateFiltersValues(event,item);

        }

        const updateFiltersValues=(event: any, item: any)=>{

            const index = dataSearchFilter.value.findIndex((x: any) => x.id == item?.id);

            setItemEmpty(event,index);
            setDateFilterValues(event,index,true);

        }


        const selectField = (event: DropdownChangeEvent) => {

            stopPropagateEventDropDown(event);
            const aux = selectedSearch.value.split(DataSourceConst.DELIMITER)

            tablasData.value.filter(x => x.tableName == aux[0] && (x.field == aux[1] || x.fieldAlias == aux[1])).forEach(c => {
                dataSearchFilter.value.push({
                    id:HelperUtils.newGuid().toString().replaceAll('-',''),
                    fieldId: c.id as any,
                    rangeDateTime: {
                        from: null,
                        to: null
                    },
                    rangeValue: {
                        from: null,
                        to: null
                    },
                    filter: initFilterField(c)
                } as any)

                //quitamos el campo de la lista
                removeFieldOnTheList(c);
            })

            dataSearchFilter.value.sort((a, b) => { return (objDsfConfiguration.value.find(z => z.dataSourceFieldId == a.fieldId)?.defaultFilterSearchOrder ?? 0) - (objDsfConfiguration.value.find(z => z.dataSourceFieldId == b.fieldId)?.defaultFilterSearchOrder ?? 0); });

            selectedSearch.value = ''

            //arreglo input calendar por bug primevue
            assignCalendarsRef();
        }

        const onRowSelect = (event: any) => {

            emit('change:rowSelected', event);

        };

        const setDataModelValue = (data: any[]) => {


            const fieldMap = props.dataSourceLookUp.iapDataSourceLookUpFieldMaps?.filter(fm => fm.dataSourceFieldIdTarget != null && fm.isFieldLinked);

            data.forEach(obj => {

                let dic: Dictionary<String, String>[] = ([]);

                for (let prop in obj) {
                    if (prop.includes(DataSourceConst.LOOKUPID + '_')) {

                        const dsfSourceId = prop.replace((DataSourceConst.LOOKUPID + '_'), '');
                        const dataSourceTargetId = fieldMap.find(fm => fm.dataSourceFieldIdSource == dsfSourceId)?.dataSourceFieldIdTarget;

                        if (dataSourceTargetId) {
                            dic.push({
                                key: dataSourceTargetId,
                                value: obj[prop]
                            } as any)
                        }

                        obj[DataSourceConst.LOOKUPMODELVALUE] = dic;
                    }

                }

            });

            return data ?? [];

        }

        const fillDataSearchFilters = () => {

            tablasData.value.filter(x => objDsfConfiguration.value.find(z => z.dataSourceFieldId == x.id)?.defaultFilterSearch)
                .sort((a, b) => { return (objDsfConfiguration.value.find(z => z.dataSourceFieldId == a.id)?.defaultFilterSearchOrder ?? 0) - (objDsfConfiguration.value.find(z => z.dataSourceFieldId == b.id)?.defaultFilterSearchOrder ?? 0); })
                .forEach(c => {
                    dataSearchFilter.value.push({
                        id:HelperUtils.newGuid().toString().replaceAll('-',''),
                        fieldId: c.id as any,
                        filter: initFilterField(c),
                        rangeDateTime: {
                            from: null,
                            to: null
                        },
                        rangeValue: {
                            from: null,
                            to: null
                        },

                    } as any)
                })

            dataSearchFilter.value.sort((a, b) => { return (objDsfConfiguration.value.find(z => z.dataSourceFieldId == a.fieldId)?.defaultFilterSearchOrder ?? 0) - (objDsfConfiguration.value.find(z => z.dataSourceFieldId == b.fieldId)?.defaultFilterSearchOrder ?? 0); });

        };




        const getData = () => {

            var data = [] as any;
            let filterAlreadyExist: string[] = [];

            if (props.filterConditions.length > 0) {
                filterAlreadyExist = props.filterConditions.flatMap(x => x.fieldId);
            }

            if (tablasData.value.length > 0) {
                tables.value.forEach(x => {
                    const aux = {
                        label: !HelperCommon.isNullOrWhitespace(x.shortDescription ?? '') ? x.shortDescription : x.tableName,
                        icon: x.idIcon ?? 'pi pi-list',
                        code: x.tableName,
                        items: tablasData.value.filter(f => !filterAlreadyExist.includes(f.id)).filter(t => t.dataSourceTableAliasId != null && t.tableName == x.tableName && objDsfConfiguration.value.find(z => z.dataSourceFieldId == t.id)?.filterSearch &&  !existFilteredField(t)).sort((a, b) => { return (objDsfConfiguration.value.find(z => z.dataSourceFieldId == a.id)?.filterSearchOrder ?? 0) - (objDsfConfiguration.value.find(z => z.dataSourceFieldId == b.id)?.filterSearchOrder ?? 0); }).map(t => ({
                            label: (t.shortDescription ?? t.field), tabla: t.tableName, value: t.tableName + DataSourceConst.DELIMITER + t.field, disabled: false,
                            command: (event: any) => {
                                selectedSearch.value = event.item.value
                                selectField(event)
                            }
                        })),
                        visible: tablasData.value.filter(t => t.tableName == x.tableName && objDsfConfiguration.value.find(z => z.dataSourceFieldId == t.id)?.filterSearch && !existFilteredField(t)).length > 0

                    }


                    data.push(aux);
                })
            }


            const auxCustom = {
                label: 'Calculados',
                icon: 'pi pi-list',
                code: DataSourceConst.AGGREGATE_TABLE,
                items: tablasData.value.filter(t => t.dataSourceTableAliasId == null && objDsfConfiguration.value.find(z => z.dataSourceFieldId == t.id)?.filterSearch && !existFilteredField(t)).sort((a, b) => { return (objDsfConfiguration.value.find(z => z.dataSourceFieldId == a.id)?.filterSearchOrder ?? 0) - (objDsfConfiguration.value.find(z => z.dataSourceFieldId == b.id)?.filterSearchOrder ?? 0); }).map(t => ({
                    label: (t.shortDescription ?? t.fieldAlias), tabla: t.tableName, value: t.tableName + DataSourceConst.DELIMITER + t.fieldAlias, disabled: false,
                    command: (event: any) => {
                        selectedSearch.value = event.item.value
                        selectField(event)
                    }
                })),
                visible: tablasData.value.filter(t => t.dataSourceTableAliasId == null && objDsfConfiguration.value.find(z => z.dataSourceFieldId == t.id)?.filterSearch && !existFilteredField(t)).length > 0

            }

            if (auxCustom.items.length > 0) {
                data.push(auxCustom);
            }

            fieldsData.value = data;
        };


        const addFieldOnTheList = (field: IapDataSourceField) => {

            var newField = tablasData.value.filter(t => t.id == field.id && t.dataSourceTableAliasId != null && t.tableName == field.tableName).map(t => ({
                label: (t.shortDescription ?? t.field), tabla: t.tableName, value: t.tableName + DataSourceConst.DELIMITER + t.field, disabled: false,
                command: (event: any) => {
                    selectedSearch.value = event.item.value
                    selectField(event)
                }
            }))

            var indexVar = fieldsData.value.findIndex(x => x.code == field.tableName);
            if (indexVar > -1) {
                fieldsData.value[indexVar].items.push(newField[0]);
            }
        }

        const removeFieldOnTheList = (field: IapDataSourceField) => {

            var indexVar = fieldsData.value.findIndex(x => x.code == field.tableName);

            if (indexVar > -1) {
                var indexItem = fieldsData.value[indexVar].items.findIndex(x => x.label == (field.shortDescription ?? field.field) && x.tabla == field.tableName);

                if (indexItem > -1) {
                    fieldsData.value[indexVar].items.splice(indexItem, 1);
                }

            }
        }



        const clearFilterData = () => {
            if (dataSearchFilter.value.length == 0) {
                fillDataSearchFilters();
            }
            else {
                dataSearchFilter.value.forEach(x => {
                        x.value = undefined,
                        x.rangeDateTime = {
                            from: null,
                            to: null
                        },
                        x.rangeValue = {
                            from: null,
                            to: null
                        },
                        x.valueBool = undefined,
                        x.valueDateTime = undefined,
                        x.valueList = undefined,
                        x.valueNumber = undefined
                        x.filter= isType(getFieldData(x?.fieldId) as any, SqlTypesConst.DATETIME) || isType(getFieldData(x?.fieldId) as any, SqlTypesConst.DATE) || isType(getFieldData(x?.fieldId) as any, SqlTypesConst.MONEY) || isType(getFieldData(x?.fieldId) as any, SqlTypesConst.INT)? FiltroBusquedaConst.FILTROBUSQUEDA_IGUAL : FiltroBusquedaConst.FILTROBUSQUEDA_CONTIENE

                })
            }

            //Borramos los filtros aplicados
            visibleFilter.value=[];
        }

        const executeSearch = () => {
            const { generateFormatCol,adjustSearchRequest } = DataSourceComp(props.container as any, props, emit, {}, {} as any, props.catalogsData, store, props.dataSourceLookUp.dataSource as any)
            let data = JSON.parse(JSON.stringify(dataSearchFilter.value));
            data = data.concat(props.filterConditions);
            adjustSearchRequest(data);
            if (props.container && props.dataSourceLookUp.dataSource.applicationId && props.dataSourceLookUp.dataSource.applicationVersion && props.dataSourceLookUp.id) {
                const _srv = props.container.get<IServiceSearch>(
                    TYPES.SEARCH_REPOSITORY
                );
                const searchData:GroupSearch={
                    operatorLogic:OperatorLogicConst.AND,
                    fields: data,
                    children:[]
                }
                
                HelperLoading.showLoading();
                _srv.searchLookUp(props.dataSourceLookUp.dataSource.applicationId, props.dataSourceLookUp.dataSource.applicationVersion,
                    props.dataSourceLookUp.id, searchData as any ?? [], props.dataSourceLookUp?.maxRowsReturned ?? 5,props.where)
                    .then((response) => {

                        const obj = JSON.parse(response?.items ?? '[]');
                        generateFormatCol(obj)
                        HelperUtils.handleDates(obj);
 
                        if (obj) {
                            rows.value = setDataModelValue(obj);
                        }

                    })
                    .catch((err) => {

                    })
                    .finally(() => {

                        HelperLoading.hideLoading();
                    });

            }

        }

        const existFilteredField = (t: IapDataSourceField) => {
            return (dataSearchFilter.value.filter(x=>x?.fieldId==t.id).length > 0);
        }

        const setDateFilterValues=(event:any,index:number,isUpdateFilterValue:boolean=false)=>{

                const currentDate = new Date();
                let dateFrom: Date | null = null;
                let dateTo: Date | null = null;
                let changeDate:boolean=false;

                if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTMONTH){
                    const currMonthDates=HelperCommon.currentMonthDates();
                    dateFrom=currMonthDates.first;
                    dateTo=currMonthDates.last;
                    changeDate=true;
                }
                if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSMONTH){

                    const prevMonthDates=HelperCommon.previousMonthDates();
                    dateFrom=prevMonthDates.first;
                    dateTo=prevMonthDates.last;
                    changeDate=true;
                }

                if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTYEAR){

                    const currYearDates=HelperCommon.currentYearDates();
                    dateFrom=currYearDates.first;
                    dateTo=currYearDates.last;
                    changeDate=true;
                }

                if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSYEAR){
                    const prevYearDates=HelperCommon.previousYearDates();
                    dateFrom=prevYearDates.first;
                    dateTo=prevYearDates.last;
                    changeDate=true;
                }

                if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_CURRENTWEEK){
                    const currWeekDates=HelperCommon.currentWeekDates();
                    dateFrom=currWeekDates.first;
                    dateTo=currWeekDates.last;
                    changeDate=true;
                }

                if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_PREVIOUSWEEK){
                    const prevWeekDates=HelperCommon.previousWeekDates();
                    dateFrom=prevWeekDates.first;
                    dateTo=prevWeekDates.last;
                    changeDate=true;
                }
                const searchFilter = dataSearchFilter.value[index];

                if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_DAYSAGO){
                    const valueNumber: number | undefined = searchFilter.valueNumber !== undefined ? Number(searchFilter.valueNumber) : undefined;;
                    if (valueNumber !== undefined && !isNaN(valueNumber)) {
                        const daysAgo = HelperCommon.daysAgoDates(valueNumber);
                        dateFrom = daysAgo.first;
                        dateTo = daysAgo.last;
                    } 
                    changeDate=true;
              }

              if (event.value == FiltroBusquedaConst.FILTROBUSQUEDA_ENTRE && !isUpdateFilterValue){
                dateFrom=searchFilter?.rangeDateTime?.from??null;
                if(searchFilter?.rangeDateTime?.to!=null){
                    dateTo=new Date(searchFilter?.rangeDateTime?.to.setHours(23,59,59,999));
                }
                else{
                    dateTo=searchFilter?.rangeDateTime?.to??null;
                }
                changeDate=true;
            }
               
                if (searchFilter && searchFilter.rangeDateTime && changeDate) {
                    searchFilter.rangeDateTime.from = dateFrom;
                    searchFilter.rangeDateTime.to = dateTo;
                }


        }

        
        const setItemEmpty = (event: any, index: number) => {

            const filterDel=dateFilter.filter(x=>x!==FiltroBusquedaConst.FILTROBUSQUEDA_ENTRE);
            
            if (notShowControl.includes(event?.value??event) || filterDel.includes(oldFilter)/*o se ha cambiado de un filtro calculado fecha a otro*/) {

                if (index > -1) {
                        if (dataSearchFilter.value?.[index]?.rangeDateTime?.from !== undefined) {
                                    dataSearchFilter.value[index].rangeDateTime!.from = null;
                        } 
                        
                        if (dataSearchFilter.value?.[index]?.rangeDateTime?.to !== undefined) {
                                    dataSearchFilter.value[index].rangeDateTime!.to = null;
                        } 
                                                                        
                    if ('value' in dataSearchFilter.value[index]) {
                        dataSearchFilter.value[index].value = undefined;
                    }

                    if ('valueNumber' in dataSearchFilter.value[index]) {
                        dataSearchFilter.value[index].valueNumber = undefined;
                    }

                    if ('valueBool' in dataSearchFilter.value[index]) {
                        dataSearchFilter.value[index].valueBool = undefined;
                    }
                }
         }

          
        }

        const onFilterFocus = (filter: any) => {
            oldFilter=filter;
        };

        const showFilter = (fieldId:string) => {
            return  (visibleFilter.value.includes(fieldId));
         };

         const onClickFilter = (item:any) => {
            const index = visibleFilter.value.indexOf(item?.fieldId);
            if (index !== -1) {
                visibleFilter.value.splice(index, 1);
                const itemIndex = dataSearchFilter.value.findIndex((x: any) => x.id == item?.id);
                if(notShowControl.includes(item.filter) ){
                    setItemEmpty( item.filter,itemIndex);
                    item.filter=initFilterField( getFieldData(item?.fieldId));
                }
                
            } else {
                visibleFilter.value.push(item?.fieldId); 
            }
        };

        const setTooltip = (filter:string) => {
            const catalog = getCatalogData(CatalogTypeConst.FILTROBUSQUEDA);
            toolTipDD.value= catalog.find(x=>x.id==filter)?.description??'';
         };

         const getToolTipFilter=(option:any)=>{
            return option?.description;
        }

        const assignCalendarsRef=()=>{
            
            dataSearchFilter.value.forEach((item: any, index: any) => {
            const field=getFieldData(item.fieldId);
            if((isType(field, SqlTypesConst.DATETIME) || isType(field, SqlTypesConst.DATE)) ){
                calendarsFromRef.value[index] = ref(null) as any;
            }
          });

            dataSearchFilter.value.forEach((item: any, index: any) => {
            const field=getFieldData(item.fieldId);
            if((isType(field, SqlTypesConst.DATETIME) || isType(field, SqlTypesConst.DATE))){
                calendarsToRef.value[index] = ref(null) as any;
            }
        });

        }

        //arreglo inputs calendar por bug primevue
        const fixCalendarFocusTrap = (calendarRef:any) => {
            
            const componentExists = !!calendarRef;
            if (!componentExists) return;

            const alreadyMonkeyPatched =
                !!calendarRef.originalUpdateFocusFunc;
            if (alreadyMonkeyPatched) return;

            calendarRef.originalUpdateFocusFunc =
            calendarRef.updateFocus;

            calendarRef.updateFocus = () => {
                const inputEl = calendarRef.input;
                const inputElHasFocus = inputEl && inputEl == document.activeElement;
                if (!inputElHasFocus) {
                    calendarRef.originalUpdateFocusFunc();
                }
            };
        };

       //arreglo inputs calendar por bug primevue
        watch(() => calendarsToRef.value, (newValue, oldValue) => {
            
            calendarsToRef.value.filter((el) => el !== null && el !== undefined).forEach((item: any, index: any)=>{
                if( item.value && item.value[0]){
                    fixCalendarFocusTrap(item.value[0]);
                }
                   
            });
        }, { immediate: true, deep: true });

        //arreglo inputs calendar por bug primevue
        watch(() => calendarsFromRef.value, (newValue, oldValue) => {
            
            calendarsFromRef.value.filter((el) => el !== null && el !== undefined).forEach((item: any, index: any)=>{
                if( item.value && item.value[0]){
                    fixCalendarFocusTrap(item.value[0]);
                }
            });
        }, { immediate: true, deep: true });


        onMounted(() => {

            emit('mounted');
            //obtener lista campos para busqueda
            getData();
            
            
            if(props.filterConditions.length>0 || !HelperCommon.isNullOrWhitespace(props.where)){
                executeSearch();
            }

            //arreglo inputs calendar por bug primevue
            calendarsFromRef.value.filter((el) => el !== null && el !== undefined).forEach((item: any, index: any)=>{
                    fixCalendarFocusTrap(item.value[0]);
            });

        })

        onBeforeMount(() => {
            fillDataSearchFilters();
          //arreglo inputs calendar por bug primevue
            assignCalendarsRef();

        });

        
        return {
            rows,
            onRowSelect,
            executeSearch,
            filters,
            dataSearchFilter,
            dataBaseId,
            tables,
            getCatalogData,
            CatalogTypeConst,
            isType,
            SqlTypesConst,
            isCatalogType,
            removeField,
            fieldsData,
            selectedSearch,
            selectField,
            clearFilterData,
            DataSourceConst,
            FiltroBusquedaConst,
            stopPropagateEventDropDown,
            getLabel,
            columnsFilteredList,
            calendarsFromRef,
            calendarsToRef,
            isFilterVisible,
            onChangeDropDown,
            columnsToFormat,
            getFieldData,
            getCatalogFilterByFieldType,
            dateFilter,
            setDateFilterValues,
            notShowControl,
            showFilter,

            onClickFilter,
            toolTipDD,
            setTooltip,
            getToolTipFilter,
            onFilterFocus
        };
    },
});
</script>

<style lang="scss" scoped>
::v-deep(.p-panel.p-panel-toggleable .p-panel-header) {
    padding: 0.25rem 0.50rem !important;
}

::v-deep(.p-panel .p-panel-header) {
    padding: 0.25rem 0.50rem !important;
}


::v-deep(.p-panel .p-panel-content) {
    padding: 0.25rem 0.25rem !important;
}

::v-deep(.p-datatable-table .p-datatable-tbody > tr > td) {
    padding: 0.25rem 0.25rem !important;
}

::v-deep(.p-datatable-table .p-datatable-thead > tr > th) {
    padding: 0.25rem 0.25rem !important;
}

::v-deep(.field) {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
}

::v-deep(.p-inputtext) {
    padding: 0.25rem 0.25rem !important;
}

::v-deep(.p-multiselect .p-multiselect-label) {
    padding: 0.25rem 0.25rem !important;
}

::v-deep(.p-inputgroup-addon) {
    padding: 0.25rem 0.25rem !important;
}
</style>
